import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

const fetchDashboardIdeas = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/workflow/user-dashboard`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }

};

export const submitRFI = async (id, answer) => {
  if(!id) 
    throw new Error('Missing required parameter: id');
  if(!answer)
    throw new Error('Missing required parameter: answer');

  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  const formData = new FormData();
  formData.append("answer", answer);

  try {
    const response = await axios.post(
      `${API_URL}/workflow/user-dashboard/rfi/${id}`, 
      formData, 
      { headers: authHeader }
    );
    return response.data;
  } catch (error) {
    console.error('Error submitting evaluation:', error.response ? error.response.data : error.message);
    throw error;
  }
};
  

export default {
  fetchDashboardIdeas,
};
