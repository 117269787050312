import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

const getUsers = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/users`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const exportUsers = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
  
    try {
      const response = await axios.post(`${API_URL}/administration/users?action=export`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data; // You can handle the blob (file) appropriately here
    } catch (error) {
      console.error('Error exporting users:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

export default {
  getUsers,
  exportUsers
};
