import Parse from 'parse';


//action = action_{TableName} example: update_User, create_User, delete_User
export const logAction = async (action, actionDescription, object = {}) => {

  const currentUser = Parse.User.current();
  if (!currentUser) {
    return null;
  }

  const Log = Parse.Object.extend("Log");
  const logEntry = new Log();

  logEntry.set("action", action);
  logEntry.set("actionBy", currentUser);
  logEntry.set("actionDescription", actionDescription);
  logEntry.set("customUpdatedAt", new Date());
  
  if(object)  
    logEntry.set("object", object.toJSON()); 

  try {
    const result = await logEntry.save();
    return result;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};
