import moment from 'moment';
import Parse from 'parse';
import { object } from "prop-types";
import React from "react";
import ReactDOM from 'react-dom';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormInput,
  Row,
  Tooltip
} from "shards-react";
import Swal from 'sweetalert2';
import { users as UserListNew } from '../../components/components-overview/UserListNew';
import { logAction } from '../../services/logService';
import ExecutionSelectNew from "./ExecutionSelectNew";
import SupervisorSelect from "./SupervisorSelect";

import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as RedIcon } from "../../images/red_icon.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";

// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
import selectIdeaImage from '../../images/selected.png';

// New Selected
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";

import Switch from "./Switch.js";


import { withTranslation } from 'react-i18next';

import DepartmentSelect from "./DepartmentSelect";
import SelectPrivileges from "./SelectPrivileges";
import { sendResetEmail } from '../../services/emailService.js';

class EditUserForm extends React.Component {
    constructor(props) {
        super(props);

        // Refs
        this.galleryRef = React.createRef();

        this.state = {
          data:[],
          ideaQuestions: [],
          answers:[],
          category:null,
          department:null,
          date: '',
          categoryQuestions: [],
          filterQuestions: [],
          selectedFilterQ: [],
          filterQAnswers: [],
          categoryQAnswers: [],
          ideaDescription: null,
          file:null, 
          buttonNext:true,
          descriptionValid:'',
          remainingCharacters: 250,
          visible: true,
          filterVisible: false,
          filterQuestionsVisible: false, 
          ideaQuestionsVisible: false,
          hideNextButton: false,
          userName: 'User Name',
          sectionTitle:'',
          formButtonTitle: 'Continuar',
          ideaNumber: '#00008',
          status: '',
          executionResObj: object,
          selectedBenefit:'',
          selectedResult: '',
          money: '',
          selectedImprovement: '',
          selectedImpact: '',
          selectedCoachBackup: '',
          isRecognized: '',
          page: 2,
          responseInfo: false,
          responseInfo2: false,
          selectedStatus: ''.length,
          expectedReturn: '',
          timeUnit: '',
          language: 'en',
          executionRes: 0,
          coachRes: '',
          recurringImpact: false,
          comment: '',
          categoryDuration: false,
          startDate: '',
          endDate: '',
          selectedLanguage: {
            value:'English',
            label:'English'
          }, 
          categoryTitle: '',
          categoryInformation: '',
          hasEnglish: false,
          hasSpanish: false,
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          privileges: [],
          userData: '',
          canEdit: '',
          accountDisabled: false,
          roles: [], 
          supervisor: '',
          secondaryEmail: '',
          activeTab: 'departments '
        }

        this.change = this.change.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setIdeaDescription = this.setIdeaDescription.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.showNext = this.showNext.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.saveIdea = this.saveIdea.bind(this);
        this.showNext = this.showNext.bind(this);
        this.changeMoney = this.changeMoney.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.setEvalStatus = this.setEvalStatus.bind(this);
        this.setFirstName = this.setFirstName.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setSecondaryEmail = this.setSecondaryEmail.bind(this);
        // this.setPassword = this.setPassword.bind(this);
        this.setLanguage = this.setLanguage.bind(this);

    }

    componentDidUpdate(prevProps) {
      if (prevProps.userData !== this.props.userData) {
        // alert('Can evaluate!')
        this.loadInitialSettings()
      }
    }

    componentDidMount() {

      // Category
      this.loadInitialSettings()

      let currUser = Parse.User.current();
     
      this.getDate();
    }

    loadInitialSettings() {
      const { userData } = this.props;
       
      if (userData) {
        const firstName = userData.firstName
        const lastName = userData.lastName
        const email = userData.email
        const accountDisabled = userData.accountDisabled
        const secondaryEmail = userData.secondaryEmail

        const isMurmurattoUser = userData.role === 'murmuratto';
        const isSuperUser = userData.role === 'super_user';
        const isCoach = userData.role === 'coach';
        const isSupervisor =  userData.role === 'supervisor';
        const isAnalyticsViewer = userData.role == 'analytics'
        const isEnterpriseViewer = userData.isEnterprise

        const isPmo = userData.pmo
        const isVerification = userData.verification
        const isReward = userData.reward
        const supervisor = userData.supervisor && userData.supervisor.title
        

        const roles = []
        const privileges = userData.privileges.map((item) => {
          return {value:item, label:item}
        })

        // Roles

        if (isMurmurattoUser) {
          roles.push({value:"murmuratto", label:'Murmuratto'})
        }

        if (isSuperUser) {
          roles.push({value:"super_user", label:'Super User'})
        }

        if (isCoach) {
          roles.push({value:"coach", label: 'Coach'})
        }

        if (isSupervisor) {
          roles.push({value:"supervisor", label: 'Supervisor'})
        }

        if (isAnalyticsViewer) {
          roles.push({value:"analytics", label: 'Analytics Viewer'})
        }

        if (isEnterpriseViewer) {
          roles.push({value:"enterprise", label: 'Enterprise Viewer'})
        }

        // Privileged

        
        const department = userData.department
        const selectedDepartment = {value: "", label: department.title.en}
        const selectedSupervisor = {value: supervisor, label: supervisor}

        this.setState({firstName: firstName, lastName: lastName, email: email, department: selectedDepartment, roles: roles, privileges: privileges, canEdit: true, userData: userData, accountDisabled: accountDisabled, supervisor: selectedSupervisor, secondaryEmail: secondaryEmail})
      }


      // this.setState({categoryOn: categoryStatus, categoryTitle: categoryName, categoryInformation: categoryInformation, hasEnglish: hasEnglish, hasSpanish: hasSpanish})
    }



    getDate() {
      this.setState({
        date: new Date()
      });
    }

    async getUserName(user) {
      var query = new Parse.Query(Parse.User);
      query.equalTo("objectId", user.id);
      const results = await query.find();
      const firstName = results[0].get('firstName');
      const lastName = results[0].get('lastName');
      const fullName = firstName + ' ' + lastName;
      this.setState({
        userName: fullName
      });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continuar") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        //  
        //  
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          //  
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        //  
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions.");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
      //  
      //  
      //  
      //  
      //  
    }

    resetIdeaForm() {
      window.location.reload();
    }


   

    

    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions, selectedFilterQ } = this.state;
      //  
      //  
      
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      //  
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    saveIdea() {
      // Simple syntax to create a new subclass of Parse.Object.
      var Idea = Parse.Object.extend("Idea");
      // Create a new instance of that class.
      var ideaInfo = new Idea();

      const currentUser = Parse.User.current();
      const userId = currentUser.get("username");
    
      // IDEA Properties
      ideaInfo.set("proponent", userId);
      ideaInfo.set("department",this.state.department);
      ideaInfo.set("category", this.state.category);
      ideaInfo.set("date", this.state.date);
      ideaInfo.set("description", this.state.ideaDescription);
      ideaInfo.set("file", this.state.file);
      ideaInfo.set("status", "saved");
      ideaInfo.set("progress", [0,100]);
      
      // ideaInfo.save()
      // .then((ideaInfo) => {
      //   // Execute any logic that should take place after the object is saved.
      //   this.resetForm();
      //   alert('Su IDEA fue guardada exitosamente.');
      // }, (error) => {
      //   // Execute any logic that should take place if the save fails.
      //   // error is a Parse.Error with an error code and message.
      //   alert('Failed to create new object, with error code: ' + error.message);
      // });
    }

    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
      // this.setState({category: event.target.value});
      this.setState({department: event.target.value});
      //  
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
      //  
    }

    setFilter(filterName) {
      //  
      // this.setState({sectionTitle: (filterName === "innovacion")?"INNOVACION":"RESOLUCION DE PROBLEMAS"});
      this.handleFilterChange(filterName);
    }

    clickedPrint() {
       
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
      //  
    }

    filterQuestionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.filterQAnswers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    questionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.answers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

      //  
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      //  
    }

    selectFile(file) {
      //  
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        //  
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
      //  
    }

    addFilterAnswer(filteredData) {
      //  
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          //  
        });
    }

    submitEvaluation() {
      const {status, ideaDescription, descriptionValid, userName, committeeResObj, executionRes} = this.state;
      const { setFinishedSaving } = this.props;

      var ideaItem = this.props.ideaItem;
      var comments = ideaItem.get("comments");
      var newStatus = '';
      var percentage = [25,75];
      var mayNeedEval = false 
      
      // Verify idea to check if it leaves the evaluation inbox or not
      if (status == 'Proyecto' || status == 'Otro') {
        mayNeedEval = true
      }
      
       
       

      switch(status) {
        case "Devuelta":
          // code block
          newStatus = 'Idea Devuelta - Mas Informacion';
          break;
        case "Espera":
          // code block
          newStatus = 'Idea en Espera';
          break;
        case "No Perseguido":
          // code block
          newStatus = 'No Perseguido';
          percentage = [0,100];
          break;
        case "Ejecutar":
          // code block
          newStatus = 'Ejecutar - Just Do It';
          break;

        case "Ejecutar Proyecto":
          // code block
          newStatus = 'Ejecutar - Just Do It - Proyecto';
          break;
        case "Proyecto":
          // code block
          newStatus = 'Idea Proyecto';
          percentage = [0,100];
          break;
        default:
          newStatus = 'SOMETIDA';
          percentage = [0,100];
          // code block
      }



      // Comments
      var newComment = {"comment": ideaDescription, "date": new Date(), "user":userName, "progress":[25,75]};

      if (ideaDescription && ideaDescription.length > 0) {
        comments.push(newComment)
      }
       
      const responsibleName = this.state.executionRes.label;
      const resUser = this.state.executionRes;

      ideaItem.set("status", newStatus);
      ideaItem.set("comments", comments);
      ideaItem.set("needsEvaluation", mayNeedEval);
      ideaItem.set("progress", percentage);

      if (status == 'Otro') {
        var otherCom = committeeResObj.get('value');
        ideaItem.set("department", otherCom);
      } else {
        ideaItem.set("responsibleName",responsibleName);
      }

      ideaItem.set("responsible", resUser.value);
      ideaItem.set("edited", false);
      ideaItem.set("customUpdatedAt", new Date());
       
      if ( newStatus == 'No Perseguido') {
        if (window.confirm('Did you have a conversation with your employee? If you did, please click OK.')) this.saveIdeaItem(ideaItem) 
      } else {
        this.saveIdeaItem(ideaItem) 
      }
  }

  saveIdeaItem(ideaItem) {
    const { setFinishedSaving } = this.props;
    Parse.Object.saveAll([ideaItem], {useMasterKey: true}).then(() => {
      if (this.state.status == 'Otro') {
        alert('Su evaluacion ha sido sometida. ¡Gracias!', setFinishedSaving());
      } else {
        setFinishedSaving()
      }
    });
  }

    changeStatus(selection) {
      this.setState({status: selection});
       
    }

    // changeResponsible(res, idx) {
    //    
    //   this.setState({
    //     executionResObj: res,
    //     executionRes: idx
    //   });
    //    
    // }

    changeEvaluationResponse() {
      const responseObj = {
        status: '',
        economicImpact: '',
        timeFrame: '',
        recurringImpact: false,
        comments: '',
        ideaOwner: '',
        ideaCoach: '',
        privileges: '',
        supervisor: ''
      }
    }

    async getHumanResourceUsers() {
      var query = new Parse.Query(Parse.User);
      query.equalTo("humanResources", true);
      const results = await query.find();
       
      return results
    }

    async setVerificationNotifications() {
      const allUsers= await this.getHumanResourceUsers();
      for (var u in allUsers) {
        allUsers[u].increment("notificationCount");
        var responsibleNotifications = allUsers[u].get("notificationMessages");
        responsibleNotifications.unshift("'¡Congrats! You have ideas to evaluate.'");
        allUsers[u].set("notificationMessages", responsibleNotifications);
        allUsers[u].set("customUpdatedAt", new Date());
        await allUsers[u].save().catch(error => {
          // This will error, since the Parse.User is not authenticated
        });
      }
    }

    async setNotifications() {
      const {allUsers, department, category} = this.state;

      const responsibleMsg = '¡Congrats! Your idea has been verified.';

      var ideaItem = this.props.ideaItem;

      var responsibleUser = ideaItem.get("responsible");

      var responsibleNotifications = responsibleUser.get("notificationMessages");
      responsibleNotifications.unshift(responsibleMsg);

      responsibleUser.increment("notificationCount");
      responsibleUser.set("notificationMessages", responsibleNotifications);
      responsibleUser.set("customUpdatedAt", new Date());

      var users = new Parse.Query("Users");

      if(this.state.isRecognized) {
        await Parse.Object.saveAll(users, {useMasterKey: true}).then(()=> this.setVerificationNotifications())
        .catch(error => {
           
       });
      } else {
        await Parse.Object.saveAll(users, {useMasterKey: true})
        .catch(error => {
           
       });
      }
    }

    changeBenefit(response) {
        this.setState({
          selectedBenefit: response
        });
      }

    changeResults(response) {
    this.setState({
        selectedResult: response
    });
    }

    changeImprovement(response) {
    this.setState({
        selectedImprovement: response
    });
    }

    changeMoney(response) {
    this.setState({
        money: response
    });
    }

    changeImpact(response) {
    this.setState({
        selectedImpact: response
    });
    }

    changeBackup(response) {
    this.setState({
        selectedCoachBackup: response
    });
    }

    changeMoney(response) {
      const inputMoney = parseFloat(response.target.value);
       
      this.setState({
        money: inputMoney
      })
    }

    changeRecognition(response) {
      this.setState({
          isRecognized: response
      });
      }

    downloadFile(file) {
        //  
        if (file != null) {
            const newWindow = window.open(file._url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            alert('No file found...');
        }
    }

    getDate(date) {
      return moment(date).format('DD / MM / YYYY');
    }

    getIcon(name, fillColor) {
      const newIcons = [
          {normal: <UrgentImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <ProductivityImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <ProductivityImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <CheckmarkImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <TrophyImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <TrophyImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <Shield2Image className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <Shield2ImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <DollarSignImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <DollarSignImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <NumberOneImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <NumberOneImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
      ]

      switch(name) {
          case 'HandImage':
            return <HandImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ShieldImage':
              return <ShieldImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ChartImage':
              return <ChartImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'TeamImage':
              return <TeamImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'DollarImage':
              return <DollarImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ClockImage':
              return <TimeImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          //New Icons
          case 'Urgent':
              return newIcons[0].selected;
          case 'Productivity':
              return newIcons[1].selected;
          case 'Checkmark':
              return newIcons[2].selected;
          case 'Trophy':
              return newIcons[3].selected;
          case 'Shield':
              return newIcons[4].selected;
          case 'Dollar':
              return newIcons[5].selected;
          case 'Number One':
              return newIcons[6].selected;
          case 'Approve':
              return newIcons[0].selected;
          case 'Do not Pursue':
              return newIcons[1].selected;
          case 'Save for Later':
              return newIcons[2].selected;
          case 'Request information':
              return newIcons[3].selected;
          case 'Project Idea':
              return newIcons[4].selected;
          case 'Transfer Committee':
              return newIcons[5].selected;
          default:
            return <img src={selectIdeaImage} width="200" height="200" />//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
        }
  }
    toggle() {
      // alert('hover')
      this.setState({
        responseInfo: !this.state.responseInfo
      });
    }

    setEvalStatus(status) {
       
      this.setState({
        selectedStatus: status
      })
    }

    setFirstName(event) {
      const name = event.target.value;
  
      //  
      this.setState({
        firstName: name,
      })
    }

    setEmail(event) {
      const email = event.target.value;
  
      //  
      this.setState({
        email: email,
      })
    }

    setSecondaryEmail(event) {
      const email = event.target.value;
  
      //  
      this.setState({
        secondaryEmail: email,
      })
    }

    changePassword(event) {
      const password = event.target.value;
  
      //  
      this.setState({
        password: password,
      })
    }

    setLanguage(unit) {
       
      this.setState({selectedLanguage: unit}, () => {
        this.loadInitialSettings()
    })
    }

    changeSupervisor(res, idx) {
      this.setState({
        supervisor: res,
      });
       
       
    }

    changeDepartment(res, idx) {
        this.setState({
          department: res,
        });
      }

      changePrivileges(res, idx) {
        this.setState({
          privileges: res ? res : [],
        });
         
      }

      changeRoles(res, idx) {
        this.setState({
          roles: res ? res:[],
        });
         
      }


    changeCoach(res, idx) {
      this.setState({
        coachRes: res,
      });
       
    }

    changeLastName(res) {
      const lastName = res.target.value
      this.setState({
        lastName: lastName
      })
    }

    async saveUser() {
      const { firstName, lastName, email,  department, supervisor, roles} = this.state;
      var sessionToken = Parse.User.current().getSessionToken();
      var user = new Parse.User();


      if (firstName === '' || lastName === '' || email === '' || (department === '' || !department)) {
        alert('Please enter all required information.');
      } else {
      
        if (roles.length > 0) {
          // Check if super-user
          const isSuperUser = roles.some(e => e.label === 'Super User')
          // Check if Coach
          const isCoach = roles.some(e => e.label === 'Coach')
          // Check if Supervisor
          const isSupervisor = roles.some(e => e.label === 'Supervisor')

          // User type
          const userType = isSuperUser ? 'super_user' : 'user'

          if(isSuperUser){
            const itemUserClass = Parse.Object.extend("_User");
            const queryUsers = new Parse.Query(itemUserClass);
            queryUsers.equalTo("role", "super_user").doesNotExist("deletedAt");
            const countSuperUser = await queryUsers.count({ useMasterKey: true })
            if(countSuperUser >= 3){
              alert('There are already 3 super users. Please contact support to add more.')
              return
            }
          }

          user.set("isSupervisor", isSupervisor)
          user.set("role", userType)
          user.set("coach", isCoach)
        } else {
          user.set("role", 'user')
        }

        // Supervisor email
        const supervisorData = supervisor ? supervisor.value : ""
        const supervisorEmail = supervisorData?supervisorData.get("username"):""
        
         
        const randomPassword = Math.random().toString(36).slice(-8);

        user.set("username", email)
        user.set("password", randomPassword)
        user.set("firstName", firstName)
        user.set("supervisorEmail", supervisorEmail)
        user.set("lastName", lastName)
        user.set("evaluationCriteria", [])
        user.set("email", email);
        user.set("department", department.label)
        user.set("notificationCount", 0)
        user.set("notificationMessages", [])

        try {
          const query = new Parse.Query(Parse.User);
          query.equalTo("username", email);
          query.exists("deletedAt");
          const existingUser = await query.first({ useMasterKey: true });
          if (existingUser) {
              const newUsername = `${email}.deleted${Math.floor(Math.random() * 1000)}`;
              existingUser.set("username", newUsername);
              await existingUser.save(null, { useMasterKey: true });
          }

          await user.signUp();
          Parse.User.become(sessionToken);
          this.props.refreshUsers()
          this.sendResetEmailToUser(email, false)

          alert('User was created.');
        } catch (error) {
          // Show the error message somewhere and let the user try again.
          alert('There was an error creating user1: ' + error.message);
        }
      }
    }

    sendResetEmailToUser = async (email, showAlert) => {
    
      try {
        const sendEmailResponse = await sendResetEmail(email);
        showAlert && alert(sendEmailResponse.message);
       } catch (error) {
        alert(error.message);
       }
    }
    

    async editUser() {
      const { roles, userData, firstName, lastName, email, secondaryEmail, password, privileges, department, supervisor} = this.state;
      var user = userData;

      if(userData.role !== 'super_user') {
        const isSuperUser = roles.some(e => e.label === 'Super User')
        if(isSuperUser){
          const itemUserClass = Parse.Object.extend("_User");
          const queryUsers = new Parse.Query(itemUserClass);
          queryUsers.equalTo("role", "super_user").doesNotExist("deletedAt");
          const countSuperUser = await queryUsers.count({ useMasterKey: true })
          if(countSuperUser >= 3){
            alert('There are already 3 super users. Please contact support to add more.')
            return
          }
        }
      }


      if (firstName === '' || lastName === '' || email === '' || department === '' ) {
        alert('Please enter all required information.');
      } else {

        if (roles.length > 0) {
          if(userData.role !== 'murmuratto') {
            const isSuperUser = roles.some(e => e.label === 'Super User')
            const isAnalytics =  roles.some(e => e.label === 'Analytics Viewer')
            const isEnterprise =  roles.some(e => e.label === 'Enterprise Viewer')
            const isCoach = roles.some(e => e.label === 'Coach')
            const isSupervisor = roles.some(e => e.label === 'Supervisor')
            var userType = isSuperUser? 'super_user':'user'

            if (isAnalytics && !isSuperUser) {
              userType = 'analytics' 
            }

            if (isEnterprise && !isSuperUser) {
              user.set("isEnterprise", true)
            } else {
              user.set("isEnterprise", false)
            }

            user.set("isSupervisor", isSupervisor)
            user.set("role", userType)
            user.set("coach", isCoach)


          }
        } else {
          user.set("isEnterprise", false)
          user.set("role", 'user')
          user.set("coach", false)
        }
        
        if (privileges.length > 0 ) {
          // Check if super-user
          const isPmo = privileges.some(e => e.label.toUpperCase() === 'PMO' || e.label.toUpperCase() === 'PROJECT EVALUATION')
          // Check if Coach
          const isVerification = privileges.some(e => e.label.toUpperCase() === 'VERIFICATION')
          // Check if Supervisor
          const isReward = privileges.some(e => e.label.toUpperCase() === 'REWARD & RECOGNITION')

          user.set("pmo", isPmo)
          user.set("verification", isVerification)
          user.set("reward", isReward)
        } else {
          user.set("pmo", false)
          user.set("verification", false)
          user.set("reward", false)
        }

          user.set("firstName", firstName)
          user.set("lastName", lastName)
          user.set("email", email);
          user.set("username", email)
          user.set("secondaryEmail", secondaryEmail)
          user.set("department", department.label)
          user.set("customUpdatedAt", new Date());
          
          user.set("supervisorEmail", supervisor.email)
          if (password) {
            alert('Password changed!')
            user.set("password", password)
          }


          //si pertenece a un commite lo pongo como evaluation
          if (user.get("role") == 'user') {
            const itemClassEvaluationCommittee = Parse.Object.extend("EvaluationCommittee");
            const queryEvaluationCommittee = new Parse.Query(itemClassEvaluationCommittee);
            const allEvaluationCommittee = await queryEvaluationCommittee.limit(9999999).find({ useMasterKey: true });
            if (allEvaluationCommittee.length > 0) {
              for (const committee of allEvaluationCommittee) {
                const isMember = committee.get('members').filter(member => member.value.id === user.id);
                const isLeader = committee.committeeLeader && committee.committeeLeader === user.username
                if (isMember.length > 0 || isLeader) {
                  user.set("role", 'evaluation')
                  break;
                }
              }
            }
          }
          
          Parse.Object.saveAll([user], {useMasterKey: true}).then(async () => {
            alert('You user was edited successfully.')
            
            this.props.refreshUsers()
            try {
              const UserClass = Parse.Object.extend("_User");
              const query = new Parse.Query(UserClass);
              query.equalTo("deletedAt", null);
              query.limit(1000);
              let users;
              let count = 0;
              do {
                users = await query.find({ useMasterKey: true });
                if (users.length > 0) {
                  users.forEach(user => user.unset('deletedAt'));
                  await Parse.Object.saveAll(users, { useMasterKey: true });
                  count += users.length;
                }
              } while (users.length > 0);

              
            } catch (error) {
              console.error('Error updating users:', error);
            }
            
          }).catch((error) => {
            alert(error.message)
          })
        }
    }

    changeUsername = async() => {
      UserListNew.map((user) => {
        this.changeUserData(user.username, user.email)
      })
    }

    async changeUserData(username, email) {
      var query = new Parse.Query(Parse.User);
      query.equalTo("email", email);
      const results = await query.find();

      if (results.length > 0) {
        const myUser = results[0]
         

        //  
        myUser.set("username", username)
        myUser.set("customUpdatedAt", new Date());
        
        Parse.Object.saveAll([myUser], {useMasterKey: true}).then(() => {
           
          this.props.refreshUsers()
        }).catch((error) => {
           
        })
      }
    }

     // Function to change the active tab
    setActiveTab = (tabName) => {
      this.setState({ activeTab: tabName });
    }

    renderTabContent = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 'departments':
        return <div>Department content goes here...</div>;
      case 'evaluationComms':
        return <div>Evaluation Committees content goes here...</div>;
      case 'opportunities':
        return <div>Opportunities content goes here...</div>;
      default:
        return <div>Department content goes here...</div>;
    }
  }


    async deleteUser() {
      const { userData } = this.props;
      const newOwner = [];
      const selects = [];
      let confirmation = {
        "isConfirmed": false,
        "isDenied": false,
        "isDismissed": true,
        "dismiss": "cancel"
      };
      const userEmail = userData.email
    
     // Correcting the typo in the original code for extending "EvaluationCommittee"
      const itemClassOpportunity = Parse.Object.extend("Idea");
      const itemClassEvaluationCommittee = Parse.Object.extend("EvaluationCommittee");
      const itemClassDepartment = Parse.Object.extend("IdeaDepartment");

      // Queries for Opportunities, Evaluation Committees, and Departments
      const queryResponsible = new Parse.Query(itemClassOpportunity).equalTo("responsible", userData);
      const queryProponent = new Parse.Query(itemClassOpportunity).equalTo("proponentObj", userData);
      const queryCoaches = new Parse.Query(itemClassOpportunity).equalTo("coaches", userData);
      const queryEvaluationCommittee = new Parse.Query(itemClassEvaluationCommittee).equalTo("committeeLeader", userEmail);
      const queryDepartment = new Parse.Query(itemClassDepartment).equalTo("departmentLeader", userEmail);

      const [opportunitiesSubmital, opportunities, evaluationComms, departments] = await Promise.all([
        Parse.Query.or(queryProponent, queryCoaches).descending("updatedAt").find({ useMasterKey: true }).then(results => results.filter(opportunity => opportunity.get('needsEvaluation'))),
        Parse.Query.or(queryResponsible, queryCoaches).descending("updatedAt").find({ useMasterKey: true }).then(results => results.filter(opportunity => !opportunity.get('completed') || opportunity.get('progress').pop() === 100)),
        queryEvaluationCommittee.find({ useMasterKey: true }),
        queryDepartment.find({ useMasterKey: true })
      ]);

      if(opportunities.length > 0 || opportunitiesSubmital.length > 0 || evaluationComms.length > 0 || departments.length > 0) {

        const users = await new Parse.Query(Parse.Object.extend("User")).doesNotExist("deletedAt").ascending("firstName").limit(9000000000).find({ useMasterKey: true });
        const getAllUser = users.filter(user => user.id !== userData.id);

        const selectOptions = getAllUser.map(user => ({
          value: user,
          label: `${user.get('firstName')} ${user.get('lastName')}`
        }));

        // Generating HTML for each section: Committees, Departments, Opportunities, Submital
        let committeesHtml = evaluationComms.map((comm, index) => {
          const idSelect = `select-committee-${index}`;
          selects.push({ id: idSelect, item: comm, type: 'Committee' });
          return `<tr><td>${comm.get('name')}</td><td><div id="${idSelect}"></div></td></tr>`;
        }).join('');

        let departmentsHtml = departments.map((dept, index) => {
          const idSelect = `select-department-${index}`;
          selects.push({ id: idSelect, item: dept, type: 'Department' });
          return `<tr><td>${dept.get('itemName')}</td><td><div id="${idSelect}"></div></td></tr>`;
        }).join('');

        let opportunitiesHtml = opportunities.map((opportunity, index) => {
          let roles = [];
          const coaches = opportunity.get('coaches') || [];
          if (coaches.find(coach => coach.id === userData.id)) roles.push('Coach');
          if (opportunity.get('responsible').id === userData.id) roles.push('Owner');

          return roles.map(role => {
            const idSelect = `select-opportunity-${role}-${index}`;
            selects.push({ id: idSelect, item: opportunity, opportunity, role, type: 'Opportunity' });
            return `<tr><td>${opportunity.get('num')}</td><td>${opportunity.get('title')}</td><td>${role}</td><td><div id="${idSelect}" className="select-wrapper"></div></td></tr>`;
          }).join('');
        }).join('');


        let submitalHtml = opportunitiesSubmital.map((opportunity, index) => {
            const idSelect = `select-submital-${index}`;
            selects.push({ id: idSelect, item: opportunity, type: 'Submital' });
            return `<tr><td>${opportunity.get('num')}</td><td>${opportunity.get('title')}</td><td><div id="${idSelect}" className="select-wrapper"></div></td></tr>`;
        }).join('');


        const htmlContainer = `
        <div id="container-delete-user" style="font-family: Arial, sans-serif;">
          <p style="margin-bottom: 20px;">When you delete a user, you will be asked to reassign the following items:</p>
          <div>
            ${evaluationComms.length > 0 ? `<button id="tab-evaluationComms" class="btn btn-pill delete-user-btn">Evaluation Committees</button>` : ''}
            ${departments.length > 0 ? `<button id="tab-departments" class="btn btn-pill delete-user-btn">Departments</button>` : ''}
            ${opportunities.length > 0 ? `<button id="tab-opportunities" class="btn btn-pill delete-user-btn">Opportunities</button>`:''}
            ${opportunitiesSubmital.length > 0 ? `<button id="tab-submital" class="btn btn-pill delete-user-btn">Submittal</button>`:''}
          </div>
          
          ${evaluationComms.length > 0 ?`<div id="evaluationComms-section" class="table-container-delete-user" style="display:none;">
            <table class="styled-table" style="margin-bottom:0px">
              <thead>
                <tr>
                  <th>Committee Name</th>
                  <th>New Leader</th>
                </tr>
              </thead>
              <tbody>
                ${committeesHtml}
              </tbody>
            </table>
          </div>` : ''}
          ${departments.length > 0 ?`
          <div id="departments-section" class="table-container-delete-user" style="display:none;">
            <table class="styled-table" style="margin-bottom:0px">
              <thead>
                <tr>
                  <th>Department Name</th>
                  <th>New Leader</th>
                </tr>
              </thead>
              <tbody>
              ${departmentsHtml}
              </tbody>
            </table>
          </div>` : ''}
          ${opportunities.length > 0 ?` <div id="opportunities-section" class="table-container-delete-user" style="display:none;">
            <table class="styled-table" style="margin-bottom:0px"> 
              <thead>
                <tr>
                  <th>Opp #</th>
                  <th>Title</th>
                  <th>Role</th>
                  <th>New Owner/Coach</th>
                </tr>
              </thead>
              <tbody>
              ${opportunitiesHtml}
              </tbody>
            </table>
          </div>` : ''}

          ${opportunitiesSubmital.length > 0 ?` <div id="submital-section" class="table-container-delete-user" style="display:none;">
            <table class="styled-table" style="margin-bottom:0px">
              <thead>
                <tr>
                  <th>Opp #</th>
                  <th>Title</th>
                  <th>New Contact</th>
                </tr>
              </thead>
              <tbody>
              ${submitalHtml}
              </tbody>
            </table>
          </div>` : ''}
        </div>    
        `;

        confirmation = await Swal.fire({
          title: `<strong>Delete User: ${userData.firstName} ${userData.lastName}</strong>`,
          html: htmlContainer,
          focusConfirm: false,
          showCloseButton: true,
          showCancelButton: true,
          width: '800px',
          showConfirmButton: true,
          confirmButtonText: 'Transfer, then delete',
          didOpen: () => {
            selects.forEach(select => {
              ReactDOM.render(<Select  
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                onChange={(res) => {
                  newOwner.push({
                    res,
                    item: select.item,
                    type: select.type,
                  });
                }} options={selectOptions} />, document.getElementById(select.id));
            });

            const showSection = (sectionId) => {
              const sections = ['evaluationComms-section', 'departments-section', 'opportunities-section', 'submital-section'];
              sections.forEach(id => {
                const section = document.getElementById(id);
                if (section) section.style.display = 'none';
              });
              const selectedSection = document.getElementById(sectionId);
              if (selectedSection) selectedSection.style.display = 'block';
            };

            const addClickListener = (selector, sectionId) => {
              const element = document.getElementById(selector);
              if (element) {
                element.addEventListener('click', () => {
                  showSection(sectionId);
                  const buttons = document.querySelectorAll('.delete-user-btn');
                  buttons.forEach(btn => btn.classList.remove('btn-active'));
                  element.classList.add('btn-active');
                });
              }
            };

            addClickListener('tab-evaluationComms', 'evaluationComms-section');
            addClickListener('tab-departments', 'departments-section');
            addClickListener('tab-opportunities', 'opportunities-section');
            addClickListener('tab-submital', 'submital-section');

            // Show the first section or the single section if only one exists
            const availableSections = ['evaluationComms-section', 'departments-section', 'opportunities-section', 'submital-section']
              .filter(sectionId => document.getElementById(sectionId) !== null);

            if (availableSections.length === 1) {
              showSection(availableSections[0]);
              document.querySelector(`#tab-${availableSections[0].split('-')[0]}`).classList.add('btn-active');
            } 
          },
          preConfirm: () => {
            if (newOwner.length !== selects.length) {
              Swal.showValidationMessage("Each opportunity must be assigned an owner.");
              return false;
            } else {
              ReactDOM.render(<div style={{display:'flex',justifyContent:'center'}}><ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80}/></div>, document.getElementById('container-delete-user'));
            }
          },
        });
      }else{
        confirmation = await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this user!',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        });
      }

      if (confirmation.isConfirmed) {
        if (newOwner.length > 0) {
          await updateAssignments(newOwner, userData);
        }

        await updateEvaluationCommittees(userData);
        await markUserAsDeleted(userData);
        await logAction('delete_User', '', userData);
        this.props.refreshUsers(); 
        await Swal.fire("Deleted!", "The user has been deleted.", "success");
      }


      // Update assignments based on their type
      async function updateAssignments(assignments, userData) {
        for (const assignment of assignments) {
          const { item, res, type } = assignment;
          try {
            await updateAssignmentByType(item, res, type, userData);
          } catch (error) {
            console.error(`Error updating ${type}: ${error}`);
          }
        }
      }

    // Specific update logic based on assignment type
    async function updateAssignmentByType(item, res, type, userData) {
      let parseObject, updateFields;
      // eslint-disable-next-line default-case
      switch (type) {
        case 'Opportunity':
          parseObject = Parse.Object.extend("Idea");
          updateFields = (objectToSave) => {
            objectToSave.set('responsible', res.value);
            objectToSave.set('responsibleName', `${res.value.get('firstName')} ${res.value.get('lastName')}`);
          };
          break;
        case 'Submital':
          parseObject = Parse.Object.extend("Idea");
          updateFields = (objectToSave) => {
            objectToSave.set('proponentObj', res.value);
            objectToSave.set('proponent', res.value.get('username'));
            objectToSave.set('proponentName', `${res.value.get('firstName')} ${res.value.get('lastName')}`);
          };
          break;
        case 'Committee':
          parseObject = Parse.Object.extend("EvaluationCommittee");
          updateFields = (objectToSave) => {
            const updatedMembers = objectToSave.get('members').map(member =>
              member.id === userData.id ? { ...member, id: res.value.id, email: res.value.get("email"), name: `${res.value.get('firstName')} ${res.value.get('lastName')}` } : member
            );
            objectToSave.set('members', updatedMembers);
          };
          break;
        case 'Department':
          parseObject = Parse.Object.extend("IdeaDepartment");
          updateFields = (objectToSave) => {
            objectToSave.set('departmentLeader', res.value.get("email"));
          };
          break;
      }

      const query = new Parse.Query(parseObject);
      const objectToSave = await query.get(item.id, { useMasterKey: true });
      updateFields(objectToSave);
      objectToSave.set("customUpdatedAt", new Date());
      await objectToSave.save(null, { useMasterKey: true });

      if(type === 'Submital'){
        const actionBy = Parse.User.current()
        const ideaProponentHistory = new Parse.Object('IdeaProponentHistory');

        ideaProponentHistory.set('idea', objectToSave);
        ideaProponentHistory.set('oldProponent', userData);
        ideaProponentHistory.set('oldProponentName', `${userData.firstName} ${userData.lastName}`);
        ideaProponentHistory.set('oldProponentEmail', userData.email);
        ideaProponentHistory.set('oldProponentWasSupervisor', userData.isSupervisor);
        ideaProponentHistory.set('oldProponentWasCoach', userData.coach);
        ideaProponentHistory.set('oldProponentWasSuperUser', userData.role === 'super_user');
        ideaProponentHistory.set('oldProponentWasCommitteeLeader', userData.comLeader);
        ideaProponentHistory.set('oldProponentDepartment', userData.department);
        ideaProponentHistory.set('oldProponentWasDepartmentLeader', userData.departmentLeader);
        ideaProponentHistory.set('newProponent', res.value);
        ideaProponentHistory.set('newProponentName', `${res.value.firstName} ${res.value.lastName}`);
        ideaProponentHistory.set('newProponentEmail', res.value.email);
        ideaProponentHistory.set('customUpdatedAt', new Date());
        ideaProponentHistory.set('actionBy', actionBy);
        ideaProponentHistory.set('actionByName', `${actionBy.firstName} ${actionBy.lastName}`);

        await ideaProponentHistory.save(null, { useMasterKey: true });
      }
    }

    // Update evaluation committees by removing the deleted user
    async function updateEvaluationCommittees(userData) {
      const EvaluationCommittee = Parse.Object.extend('EvaluationCommittee');
      const query = new Parse.Query(EvaluationCommittee);
      query.limit(10000);
      const committees = await query.find();
      for (const committee of committees) {
        if (committee) {
          const members = committee.get('members') || [];
          const updatedMembers = members.filter(member => member.value.id !== userData.id);
          committee.set('members', updatedMembers);
          committee.set("customUpdatedAt", new Date());
          await committee.save(null, { useMasterKey: true });
        }
      }
    }

    // Mark user as deleted
    async function markUserAsDeleted(userData) {
      userData.set('deletedAt', new Date());
      await userData.save(null, { useMasterKey: true });
    }
      
      
  }
    
    


    reactivateAccount() {
      const {userData} = this.props;
      const user = userData
      user.set("accountDisabled", false);
      user.set("customUpdatedAt", new Date());

      Parse.Object.saveAll([user], {useMasterKey: true}).then(() => {
        alert('The user was reactivated successfully.')
        this.props.refreshUsers()
      }).catch((error) => {
        alert(error.message)
      })
    }

    render() {
        const {secondaryEmail, canEdit, firstName, lastName, email, supervisor, privileges, roles, coachRes, department, executionRes } = this.state
        const { ideaStage, userData } = this.props;
        const nowDate = this.getDate(Date())

        var isSameUser = false 
        // if (this.state.userData) {
        //   const currUserId = Parse.User.current().id
        //   const selectedUserId = this.state.userData.id
        //   isSameUser = currUserId == selectedUserId
        // }

        return(
            <div className="edit-user-details" >
                      <Form className="py-4"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                        
                        {/* VISIBILITY */}
                        <div >
                        <Row form >
                          {/* Left Part */}
                          <Col lg="3" className="mx-auto">
                            <Row form>
                              {/* Proponent */}
                              <Col md="12" className="form-group">
                                
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="firstName" className="georgia">Name: *</label>
                                        <FormInput
                                            id="categoryName"
                                            placeholder={'First Name'}
                                            value={firstName}
                                            onChange={this.setFirstName}
                                            className="insideFont"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <FormInput
                                        value={lastName}
                                        // style={{ minHeight: "80px" }}
                                        id="ideaQuestion"
                                        className="insideFont"
                                        placeholder="Last Name"
                                        onChange={(event) => this.changeLastName(event)}
                                        required>
                                        </FormInput>
                                    </Col>
                                </Row>
                        
                                <Row className="mt-4">
                                  <Col>       
                                    <label htmlFor="firstName" className="georgia">Roles: </label>
                                    { (!userData || userData.role !== 'murmuratto') ? <SelectPrivileges className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeRoles(res, idx)} selectedVal={roles}/> : <h6 style={{fontWeight: 500,  color: '#303030'}}>Murmuratto Support</h6> }
                                  </Col>
                                </Row>  
                              </Col>
    
                            </Row>
                          </Col>


                          {ideaStage == 1 && 
                            <Col lg="3" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col>
                                            <label htmlFor="firstName" className="georgia">Company Information: *</label>
                                            <DepartmentSelect className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeDepartment(res, idx)} selectedVal={department} isMurmurattoUser={(userData && userData.role === 'murmuratto')}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <SupervisorSelect className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeSupervisor(res, idx)} selectedVal={supervisor} isMurmurattoUser={(userData && userData.role === 'murmuratto')}/>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      <Col>
                                          <label htmlFor="firstName" className="georgia">Additional Privileges: </label>
                                          <SelectPrivileges className="insideFont" evalType={'execution'} type={'privileges'} setResponsible={(res, idx) => this.changePrivileges(res, idx)} selectedVal={privileges}/>
                                      </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          }

           

                          {ideaStage == 1 && 
                            <Col lg="3" className="mx-auto">
                              <Row form>
                                <Col md="12" className="form-group">
                                    <Row className="mt-2">
                                        <Col>
                                            <label htmlFor="firstName" className="georgia">User Information: *</label>
                                            <FormInput
                                                id="categoryName"
                                                placeholder={'Email'}
                                                value={email}
                                                onChange={this.setEmail}
                                                className="insideFont"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <Button onClick={() => this.sendResetEmailToUser(email, true)}>Reset Password</Button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4" >
                                        <Col style={{marginTop: '3.5%'}}>
                                            <label htmlFor="firstName" className="georgia">Secondary Email: (Optional)</label>
                                            <FormInput
                                                id="secondaryEmail"
                                                placeholder={'Email'}
                                                value={secondaryEmail}
                                                onChange={this.setSecondaryEmail}
                                                className="insideFont"
                                            />
                                        </Col>
                                    </Row>
                                    
                                    { this.state.accountDisabled && 
                                    <Row className="mt-2">
                                        <Col>
                                            <Button onClick={() => this.reactivateAccount()}>Reactivate Account</Button>
                                        </Col>
                                    </Row>
                                    }
                                </Col>
                              </Row>
                            </Col>
                          }

                          {/* Right Part */}
                          

                          {ideaStage == 2 && 
                            <Col lg="6">
                              <Row className="mt-4">
                                  <Col md="6">
                                    <label htmlFor="firstName" className="georgia">Choose how to proceed: </label>
                                    <Row>
                                      <Col>
                                      {this.getIcon(this.state.selectedStatus, 'Black')}
                                          <div className="mr-auto" style={{width: '100%', backgrounColor: 'black'}}>
                                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.selectedStatus}</h6>
                                          </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6">
                                    <Row className="mt-2">
                                      <Col>
                                        <label htmlFor="firstName" className="georgia">Employee Response Date</label>
                                        <h6 style={{fontWeight: 500,  color: '#303030'}}>{nowDate}</h6>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="firstName" className="georgia">Idea Status</label>
                                        <Row>
                                          <Col md="7">
                                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Pending'}</h6>
                                          </Col>
                                          <Col className="mb-auto" md="1">
                                            {/* <div className="my-auto" style={{backgroundColor: '#1DE334', height: 16, width: 16, borderRadius: 8}}></div> */}
                                            {/* { timingWording == "On-Time"? 
                                            <GreenIcon style={{height: 16, width: 16}}></GreenIcon>
                                            : */}
                                            <RedIcon style={{height: 16, width: 16}}></RedIcon>
                                            {/* } */}
                                          </Col>
                                          <Col md="1" className="mb-auto">
                                            <a id={"TooltipResponseInfo2"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                                                const myCopy = this.state.responseInfo2
                                                myCopy = !myCopy
                                                this.setState({responseInfo2: myCopy})
                                            }}>
                                                
                                                <InfoIcon style={{height: 16, width: 16}}></InfoIcon>
                                                
                                            </a>
                                          </Col>
                                         
                                          <Tooltip
                                            open={this.state.responseInfo2}
                                            target={"#TooltipResponseInfo2"}
                                            id={"TooltipResponseInfo2"}
                                            toggle={() => {this.toggle()}}
                                            >
                                            Type Category Description. Lorem ipsum dolor sit amet, consectetuer adipi- scing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volut-!
                                          </Tooltip>
                                      </Row>

                                      </Col>
                                    </Row>
                                    
                                  </Col>
                              </Row>

                              {/* Subject Matter Comments */}
                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <label htmlFor="firstName" className="georgia">Subject-Matter Expert Comments:</label>
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.comment}</h6>
                                </Col>
                              </Row>

                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <label className="georgia">{'Estimate economic/output impact'}</label>
                                  <Row>
                                    <Col>
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{'$'+this.state.expectedReturn}</h6>
                                    </Col>
                                    <Col md="4">
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.timeUnit}</h6>
                                    </Col>
                                    <Col md="4">
                                      <Switch 
                                        isOn={this.state.recurringImpact}
                                        disabled
                                        // handleToggle={() => this.setState({hasTeam: !hasTeam})}
                                        onColor="#633FDA"
                                        title="Recurring Impact"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                  
                              </Row>

                              { this.state.selectedStatus !== "Do not Pursue" &&
                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Choose an Idea Owner *'}</h6>
                                    <ExecutionSelectNew className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeResponsible(res, idx)} selectedVal={executionRes}/>
                                  <br/>
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Choose an Idea Coach'}</h6>
                                    <ExecutionSelectNew className="insideFont" evalType={'coach'} setResponsible={(res, idx) => this.changeCoach(res, idx)} selectedVal={coachRes}/>
                                </Col>
                              </Row>
                              }
                            </Col>
                          }
                          
                        </Row>
                       
                        <Row className="mt-2">
                            <Col md="2" className="ml-auto">
                                <Row>
                                    <Col md="3" className="ml-auto">
                                        { <AcceptIcon className="functionalButton" style={{height: 34, width: 34}} onClick={() => canEdit ? this.editUser() : this.saveUser()}></AcceptIcon>}
                                    </Col>
                                    <Col md="3" className="mr-auto">
                                       { !isSameUser && <CancelIcon className="functionalButton" style={{height: 34, width: 34}} onClick={() => this.deleteUser()}></CancelIcon>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
    
        
                      
                        </div>
                        </Form>
  
                  </div>
          );
    }
}




export default withTranslation()(EditUserForm);