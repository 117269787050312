import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

export const getConfiguration = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/administration/configuration`, {
      headers: {
        ...authHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching configuration:', error.response ? error.response.data : error.message);
    throw error;
  }
};
