import Parse from 'parse';
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { Button, Card, CardBody, Col, Container, FormRadio, FormTextarea, Row } from "shards-react";
import OriginalIdeaInfo from '../components/administration/OriginalIdeaInfo';
import OriginalIdeaInfoCompleted from '../components/administration/OriginalIdeaInfoCompleted';
import ActivityHistory from '../components/common/ActivityHistory';
import IdeaAttachmentModal from '../components/common/IdeaAttachmentModal';
import IdeaVerificationViewNew from '../components/common/IdeaVerificationViewNew';
import IdeaViewCardNew from '../components/common/IdeaViewCardNew';
import RecognitionView from '../components/common/RecognitionView';
import TaskHistory from '../components/common/TaskHistory';
import CustomFileWithProgressUpload from "../components/components-overview/CustomFileWithProgressUpload";
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import { ReactComponent as SubmitIcon } from "../images/submit_new_new.svg";
import { ReactComponent as SubmitIconNosel } from "../images/submit_new_nosel.svg";
import colors from "../utils/colors";
import { authUser } from '../services/userService';
import opportunityService from '../services/opportunityService';

function IdeaInfoCompleted({closeModal, setShowInfo, currIdea, stage, customTitle, setPage, closeA3}) {
  // t is used to translate i18 variables to text strings
  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(0)
  const [categoryIsOpen, setCategoryIsOpen] = useState(true)
  const [showIdeaInformation, setShowIdeaInformation] = useState(false)
  const [showIdeaA3, setShowIdeaA3] = useState(false)
  const [openVerification, setOpenVerification] = useState(false)
  const [openRecognition, setOpenRecognition] = useState(false)
  const [canBeReplicated, setCanBeReplicated] = useState(false)
  const [goalAchieved, setGoalAchieved] = useState(false)
  const [goalTarget, setGoalTarget] = useState('')
  const [originalSituation, setOriginalSituation] = useState('')
  const [outcome, setOutcome] = useState('')
  const [additionalDetails, setAdditionalDetails] = useState('')
  const [hasResults, setHasResults] = useState(false)
  const [file, setFile] = useState(null)  
  const [result, setResult] = useState(undefined)  
  const [isLoading, setIsLoading] = useState(false)
  const [nextSteps, setNextSteps] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [ideaHasHistory, setIdeaHasHistory] = useState(false)
  const ref = useRef(null);
  const [isContentLoaded, setContentLoaded] = useState(false);
  const [opportunity, setOpportunity] = useState('');
  const [opportunityHistory, setOpportunityHistory] = useState([]);

  const handleShowInfo = (isOpen) => {
    setShowIdeaInformation(isOpen)
    setShowInfo(isOpen)
  }
  
  let currUser = authUser()
  const getUserName = async (user) => {
   
    setUsername(currUser.fullName)
    
    if (ideaStage === 0) {

      // We want to use t(VARIABLE) so that we can get the correct string
      // Replace 'VARIABLE' with t('VARIABLE_NAME')
      // VARIABLE_NAME being the corresponding variable in our new json file.

      // Uncomment the following line to create a message string
      // const welcomeMsg = 'VARIABLE' + username + '!'

      
      // Replace the contents of setTitle with our new welcomeMsg variable.
      if (customTitle) {
        setTitle(customTitle)
      } else {
        setTitle('Congratulations, you did it!')
      }
      // Here we are setting our title to what we want
    }
  }

  useEffect(() => {
    // Update the document title using the browser API
    getUserName(currUser)
    getResultCard()
    hasHistory()
    fetchNextSteps()
    setContentLoaded(true);

  }, []);

  useEffect(() => {
    if(showIdeaInformation)
      setPage('ideaInformation')
    else if(showIdeaA3)
      setPage('ideaA3')
    else if(openVerification)
      setPage('verification')
    else if(openRecognition)
      setPage('recognition')
    else
      setPage('idea')

  }, [showIdeaInformation, showIdeaA3, openVerification, openRecognition]);

  

  const fetchNextSteps = () =>  {
    // const className = "NextSteps";

    // var ItemClass = Parse.Object.extend(className);
    // var query = new Parse.Query(ItemClass);

    // query.equalTo("ideaNum", currIdea.num);
    // query.ascending("customUpdatedAt");

    // query.find()
    // .then((results) => {
    //   setNextSteps(results)
    // }, (error) => {
    //   alert('Hubo un error en la busca. Favor de tratar luego.');
    //   // The object was not retrieved successfully.
    //   // error is a Parse.Error with an error code and message.
    // });
  }

  const hasHistory = async () => {
    try {
      const history = await opportunityService.fetchOpportunityHistory(currIdea.id)
      if (history && history.length > 0 ) {
        setOpportunityHistory(history)
        setIdeaHasHistory(true)
      }
    } catch (error) {
      alert(error.message)
    }
    // const ideaReference = currIdea.get("num");

    // var ItemClass = Parse.Object.extend("ActivityHistory");
    // var query = new Parse.Query(ItemClass);
    // query.equalTo("type", "Status Update")
    // query.equalTo("ideaNum", ideaReference);
    // query.limit(1); 

    // query.find()
    // .then((results) => {
    //   if (results.length > 0) {
    //     setIdeaHasHistory(true)
    //   } else {
    //     setIdeaHasHistory(false)
    //   }
    // }, (error) => {
    //     console.error("Error fetching activity history:", error);
    //     setIdeaHasHistory(false)
    // });
  }

  const getResultCard = async () => {
    if (currIdea.implement !== null) {
      setOriginalSituation(currIdea.implement.originalSituation)
      setGoalTarget(currIdea.implement.goal)
      setGoalAchieved(currIdea.implement.goalAchieved)
      setOutcome(currIdea.implement.achievedResults)
      setAdditionalDetails(currIdea.implement.additionalDetails)
      setCanBeReplicated(currIdea.implement.canBeReplicated)
      setHasResults(true)
    }
    // var Result = Parse.Object.extend("Result"); 
    // const query = new Parse.Query(Result);
    // const myObj = currIdea.get("result")

    // if (myObj) {
    //   const objtId = myObj.id
  
    //   query.equalTo("objectId", objtId)
    //   query.find().then((results) => {
        
    //     if (results.length > 0) {
    //       const myResult = results[0]
    //       setOriginalSituation(myResult.get("originalSituation"))
          
    //       setGoalTarget(myResult.get("goalOrTarget"))
    //       setGoalAchieved(myResult.get("targetAchieved"))
    //       setOutcome(myResult.get("achievedResults"))
    //       setAdditionalDetails(myResult.get("additionalDetails"))
    //       setCanBeReplicated(myResult.get("canBeReplicated"))
    //       setHasResults(true)
    //       setResult(myResult)
    //       setFile(myResult.get("file"))
    //     } else {
    //       setHasResults(false)
    //     }
    //   }).catch((error) => {
    //     alert(error)
    //   });
  
    // }
  }

  const setFinished = () => {
    setIdeaStage(3)
  }

  const canSubmitForm = () => {
    if (originalSituation == '' || goalTarget == '') {
      return false
    } else {
      return true
    }
  }

  const saveResults = async()=> {
    setIsLoading(true)
    var Result = Parse.Object.extend("Result");
    var resultInfo = new Result();

    // IDEA Properties
    resultInfo.set("originalSituation", originalSituation);
    resultInfo.set("goalOrTarget", goalTarget);
    resultInfo.set("targetAchieved", goalAchieved);
    resultInfo.set("achievedResults", outcome);
    resultInfo.set("additionalDetails", additionalDetails);
    resultInfo.set("canBeReplicated", canBeReplicated);
    resultInfo.set("idea", currIdea)
    resultInfo.set("file", file);
   
    saveFinalIdea(resultInfo);
  }

  const saveFinalIdea = (ideaInfo) => {
    ideaInfo.set("customUpdatedAt", new Date());
    ideaInfo.save()
    .then((result) => {
      if(result && result.id) {
        fetch(`https://services.murmuratto.com/update-file/${process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo'}/Result/file/${result.id}`).then(() => {})
      }

      updateIdea(result)
    }).catch((error) => {
      alert(error)
    })
      
  }

  const updateIdea = (resultId) => {
    currIdea.set("result", resultId)
    currIdea.set("completed", true)
    currIdea.set("needsEvaluation", true)
    currIdea.set("customUpdatedAt", new Date());
    currIdea.save()
    .then((result) => {
      setIsLoading(false)
      alert('Your results have been saved!');
      // Go to this link http://localhost:3000/implement
      window.location.href = '/implement'
    }).catch((error) => {
      alert(error)
    })
  }


  
   const handleGoBack = () => {
    if (showIdeaInformation) {
      handleShowInfo(false)
    } else if (showIdeaA3) {
      setShowIdeaA3(false)
    } else if (openVerification) {
        setOpenVerification(false)
    } else if (openRecognition) {
      setOpenRecognition(false)
    } else {
      closeModal()
    }
   }

  return (
  <Container fluid style={{backgroundColor: 'white', padding: 0}}>
    
    
    
    <div ref={ref} style={{flex: 1}}>
    {showIdeaInformation && <Row className="mt-4">
      <Col md="12" lg="12">
        <Row>
          <Col md="12" className="m-auto">
            <IdeaViewCardNew canSubmit={false} setFinishedSaving={() => setFinished()} ideaItem={currIdea}  ideaStage={0} />
          </Col>
        </Row>
      </Col>
    </Row>}

    {openVerification && <Row className="mt-4">
    <Col md="12" lg="12">
        <Row>
          <Col md="12" className="m-auto">
            <IdeaVerificationViewNew dismissModal={() => console.log('Dismiss')} ideaItem={currIdea} onViewIdeaPress={() => console.log('View Idea')}/>
          </Col>
        </Row>
      </Col>
    </Row>}

    {openRecognition && <Row className="mt-4">
    <Col md="12" lg="12">
        <Row>
          <Col md="12" className="m-auto">
            <RecognitionView dismissModal={() => console.log('Dismiss')} ideaItem={currIdea} onViewIdeaPress={() => console.log('View Idea')}/>
          </Col>
        </Row>
      </Col>
    </Row>}

    {showIdeaA3 && <Row className="mt-4">
      <Col md="12" lg="12">
      <Row className="mt-4" >
      <Col md="12" className="m-auto">
        <Row>
      <Col md="6">
      <Card style={{ minHeight: "250px" }}>
                             <CardBody>
                             <label  htmlFor="question"><strong>{'Description'}: </strong></label>
                                  <FormTextarea
                               style={{ minHeight: "60px" }}
                               id="userBio"
                              //  placeholder={mejoras}
                               value={currIdea.submittal.description}
                              //  onChange={this.setMejoras}
                               inactive
                               disabled
                             />
                              <br/>
                                                         
                                    <label  htmlFor="question"><strong>{'Describe the Original Situation/Condition'}: *</strong></label>
                                  <FormTextarea
                                    style={{ minHeight: "60px" }}
                                    id="userBio"
                                     placeholder={"Type Response Here."}
                                     value={originalSituation}
                                     onChange={(event) => setOriginalSituation(event.target.value)}
                                    inactive
                                    disabled={hasResults}
                                  />
                                  <br/>
                                  <label  htmlFor="question"><strong>{'Describe the Goal or Target?'}: *</strong></label>
                                  <FormTextarea
                                    style={{ minHeight: "60px" }}
                                    id="userBio"
                                    placeholder={"Type Response Here."}
                                     value={goalTarget}
                                     onChange={(event) => setGoalTarget(event.target.value)}
                                    inactive
                                    disabled={hasResults}
                                  />
                                  <br/>
                                  <label  htmlFor="question"><strong>{t('Was the Goal/Target achieved?')} *</strong></label>
                                  <br/>
                                  <FormRadio
                                    inline
                                    name="goalAchieved"
                                    checked={goalAchieved == true}
                                    onChange={() => {
                                      setGoalAchieved(true)
                                    }}
                                    disabled={hasResults}
                                  >
                                    Yes
                                  </FormRadio>
                                  <FormRadio
                                    inline
                                    name="goalAchieved"
                                    checked={goalAchieved == false}
                                    onChange={() => {
                                      setGoalAchieved(false)
                                    }}
                                    disabled={hasResults}
                                  >
                                    No
                                  </FormRadio>
                                  <br/>
                                  <br/>
                                  <label  htmlFor="question"><strong>{t("COULD_BE_REPLICATED")} *</strong></label>
                                  <br/>
                                  <FormRadio
                                    inline
                                    name="canBeReplicated"
                                    checked={canBeReplicated == true}
                                    onChange={() => {
                                      
                                      setCanBeReplicated(true)
                                    }}
                                    disabled={hasResults}
                                  >
                                    Yes
                                  </FormRadio>
                                  <FormRadio
                                    inline
                                    name="canBeReplicated"
                                    checked={canBeReplicated == false}
                                    onChange={() => {
                                      setCanBeReplicated(false)
                                    }}
                                    disabled={hasResults}
                                  >
                                    No
                                  </FormRadio>
                                  <br/>
                                  
                             </CardBody>
                            </Card>
      </Col>
      <Col md="6">
      <Card className="h-100" style={{ minHeight: "250px" }}>
                             <CardBody>
                             <label  htmlFor="question"><strong>{'Describe the achieved results/outcome:'} </strong></label>
                                  <FormTextarea
                               style={{ minHeight: "60px" }}
                               id="userBio"
                               placeholder={"Type Response Here."}
                               value={outcome}
                               onChange={(event) => setOutcome(event.target.value)}
                               inactive
                               disabled={hasResults}
                             />
                              <br/>
                              <label  htmlFor="question"><strong>{'Add any additional details:'} </strong></label>
                                  <FormTextarea
                               style={{ minHeight: "60px" }}
                               id="userBio"
                               placeholder={"Type Response Here."}
                               value={additionalDetails}
                               onChange={(event) => setAdditionalDetails(event.target.value)}
                               inactive
                               disabled={hasResults}
                             />
                              <br/>

                              <div>
                                
                              </div>             
                              {/* Only Show if There are Tasks  */}
                             
                              <div>
                              {!hasResults &&<label  htmlFor="question"><strong>{'Add an attachment'}: </strong></label>}
                              {!hasResults && <span><CustomFileWithProgressUpload maximumSizeFile={10} onFileUpload={(file) => { setFile(file) }} /></span>  }
                              {hasResults && file && <Button onClick={() => setModalVisible(true)} style={{display: 'flex'}} >{'View Attachment'}</Button>  }
                              </div>
                             </CardBody>
                            </Card>
      </Col>
      

      </Row>
        { ideaHasHistory && 
          <Row className="mt-4">
            <Col md="12" lg="12" className="mt-4">
            
                <ActivityHistory currIdea={currIdea} history={opportunityHistory}></ActivityHistory>
                
              </Col>
          </Row>
        }
        {/* Don't show if there are no next steps */}
        { nextSteps.length > 0 && categoryIsOpen && 
          <Row className="mt-4">
            <Col md="12" lg="12" className="mt-4">
            
                <TaskHistory currIdea={currIdea}></TaskHistory>
                
              </Col>
          </Row>
        }
      </Col>
    </Row>
      </Col>
    </Row>}

    <Row className="mt-4">
      <Col md="12" lg="12">

      </Col>
      </Row>

    {!showIdeaInformation && !showIdeaA3 && !openVerification && !openRecognition &&
    <div>
    
    <div style={{flex: 1}}>
      <Row className="mt-4">
          <Col md="12" lg="12">
           
            {
              currIdea.progress == 100 ?
              <OriginalIdeaInfoCompleted currIdea={currIdea} openIdea={() => handleShowInfo(true)} key={Math.random()} openIdeaA3={() => setShowIdeaA3(true)} openVerification={() => setOpenVerification(true)}  openRecognition={() => setOpenRecognition(true)} id={Math.random()}  open={true} shouldReset={false} updateUsers={() => {console.log('')}} user={{}} stage={stage}></OriginalIdeaInfoCompleted>
              :
              <OriginalIdeaInfo currIdea={currIdea} openIdea={() => handleShowInfo(true)} key={Math.random()} openIdeaA3={() => setShowIdeaA3(true)} openVerification={() => setOpenVerification(true)}  openRecognition={() => setOpenRecognition(true)} id={Math.random()}  open={true} shouldReset={false} updateUsers={() => {console.log('')}} user={{}} stage={stage}></OriginalIdeaInfo>
              }
          </Col>
          
      </Row>
      { ideaHasHistory && currIdea.progress != 100 && 
          <Row className="mt-4">
            <Col md="12" lg="12" className="mt-4 m-auto">
            
                <ActivityHistory currIdea={currIdea} history={opportunityHistory} ></ActivityHistory>
                
              </Col>
          </Row>
        }


    
    </div>
    
    </div>
    }
    </div>
   
    <Row className="mt-4">
      <Col lg="2" className="mb-4 mr-auto">
        { <PreviousIcon className="functionalButton mr-auto d-block" style={{minWidth: 140, maxWidth:140}} onClick={() => handleGoBack()}></PreviousIcon>}
      
      </Col>
      <Col lg="8" className="mb-4 mx-auto my-auto">
        
      </Col>
      <Col lg={2} className="mb-4 ml-auto">
  {canSubmitForm() && hasResults === false && !isLoading && (
    <SubmitIcon
      className="functionalButton ml-auto d-block"
      style={{ minWidth: 140, maxWidth: 140 }}
      onClick={() => saveResults()}
    />
  )}
  {isLoading && (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '10em'
      }}
    >
      <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
    </div>
  )}
  {!stage && 
    (!canSubmitForm() && hasResults === false && !isLoading) && (
    <SubmitIconNosel
      className="functionalButton ml-auto d-block"
      style={{ minWidth: 140, maxWidth: 140 }}
    />
  )}
</Col>

    </Row>
    {result && <IdeaAttachmentModal ideaItem={result} onClick={setModalVisible} isVisible={modalVisible} file={file} type="file"/> }
  </Container>
  )}

  IdeaInfoCompleted.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

IdeaInfoCompleted.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(IdeaInfoCompleted);
