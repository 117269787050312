import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

export const uploadFile = async (file, additionalData = {}) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  const formData = new FormData();
  formData.append('file', file);

  // Append any additional data to the form-data
  Object.keys(additionalData).forEach((key) => {
    formData.append(key, additionalData[key]);
  });

  try {
    const response = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        ...authHeader,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
       
      },
    });

    if (response.data.fileId) {
      return {
        fileId: response.data.fileId,
        message: response.data.message || 'File uploaded successfully.',
      };
    } else {
      throw new Error('Unexpected response format.');
    }
  } catch (error) {
    console.error('Error uploading file:', error.response ? error.response.data : error.message);
    throw error;
  }
};
