import Parse from 'parse';
import React from "react";
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import ReactTable from "react-table";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import { fetchInbox } from '../../services/evaluateService';
import { getOpportunitiesWithFilters } from '../../services/implementService';

class ManagementIdeaTable extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      ideas:[],
      isLoading: false,
      evalCriteria: [],
      filteredIdeas:[],
      filteredData:[],
      title:'',
      evalComms: [],
      includeTcComm: false,
      transferCommResults: [],
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      search: ''
    }

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentDidMount() {
    this.fetchInboxFromService();
  }

  fetchInboxFromService = async () => {
    const {isLoading} = this.state;
    if(isLoading) return;

    this.setState({ isLoading: true });

    try {
      const inbox = await fetchInbox();
      this.setState({
        ideas: inbox,
        filteredData: inbox,
      })
    } catch (error) {
      this.setState({ isLoading: false });
      console.error('Error fetching inbox:', error);
      alert('There was an error fetching inbox. Please try again later.');
    } finally{
      this.setState({ isLoading: false });
    }
  }
  componentDidUpdate(prevProps) {
    const { ideas, transferCommResults } = this.state;
  
    if (
      prevProps.category !== this.props.category ||
      prevProps.status !== this.props.status ||
      prevProps.ideaType !== this.props.ideaType ||
      prevProps.department !== this.props.department
    ) {
      if (this.props.status === "Transfer Committee") {
        // Pass the parameters in the correct order
        this.filterIdeas(
          this.props.category,        // categories
          transferCommResults,        // ideas
          this.props.department,      // departments
          this.props.ideaType,        // ideaTypes
          true                        // transferCommittee
        );
      } else {
        this.filterIdeas(
          this.props.category,        // categories
          ideas,                      // ideas
          this.props.department,      // departments
          this.props.ideaType,        // ideaTypes
          false                       // transferCommittee
        );
      }
    }
  }
  

  arrayIncludesInObj = (arr, key, valueToCheck) => {
    return arr.some(value => value[key] === valueToCheck);
  }

  
  async filterIdeas(categories, ideas, departments, ideaTypes, transferCommittee) {
    const { evalComm } = this.props;
    const { isLoading } = this.state;
    if(isLoading) return
  
    const categoryIds = (categories || []).map(category => category.value);
    const ideaTypeIds = (ideaTypes || []).map(ideaType => ideaType.value);
    const departmentIds = (departments || []).map(department => department.value);
  
 
    const filters = {
      categoriesIds: categoryIds,
      typesIds: ideaTypeIds,
      departmentsIds: departmentIds,
      committeesIds: transferCommittee ? evalComm : undefined,
      isTransferred: transferCommittee,
    };

    this.setState({ isLoading: true });
  
    try {
      const filteredData = await getOpportunitiesWithFilters(filters);
      this.setState({ filteredData, ideas: filteredData });
    } catch (error) {
      this.setState({ isLoading: false });
      console.error('Error filtering ideas:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  
  handleDuplicateClick = async (item) => {
    try {
      // Create a new Parse Query
      const Idea = Parse.Object.extend("Idea");
      const query = new Parse.Query(Idea);
  
      // Set the query to find the idea by id
      query.equalTo("objectId", item.similarIdeaId);
  
      // Execute the query
      const similarIdea = await query.first();
  
      if (similarIdea) {
        // Found the similar idea
        item.set("similarIdea", similarIdea);
        this.props.onViewIdeaPress(item, true);
      } else {
        this.props.onViewIdeaPress(item, false);
      }
    } catch (error) {
      console.error("Error finding similar idea: ", error);
    }
  };

  fetchDuplicates = (idea) => {
    const cachedShowDuplicateTab = localStorage.getItem('showDuplicateTab') === 'true';
    if (!cachedShowDuplicateTab) {
      return { isDuplicate: false };
    }
  
    // Si es una idea mergeada no muestra duplicate
    if(idea.get('duplicateOriginalOpportunity') && idea.get('duplicateSimilarOpportunity'))
      return { isDuplicate: false };

    // Si fue marcada como no duplicada no muestra más duplicate
    if((idea.get('notDuplicates') && idea.get('notDuplicates').length > 0)){
      return { isDuplicate: false };
    }

    const potentialDuplicates = idea.get('potentialDuplicates');
    if (potentialDuplicates && potentialDuplicates.length > 0) {
      return {
        isDuplicate: true,
        randomIdeaId: potentialDuplicates[0].id
      };
    }
    
    return { isDuplicate: false };
  };

  handlePageSizeChange(e) {
    this.setState({ pageSize: parseInt(e.target.value) });
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      const filteredData = this.state.ideas.filter(idea => {
        if(idea.num === Number(searchTerm)) return true;
        return idea.title.toLowerCase().includes(searchTerm) ||
        idea.category.toLowerCase().includes(searchTerm) ||
        idea.status.toLowerCase().includes(searchTerm)
      });
      this.setState({ filteredData });
    });
  }

  render() {
    const { pageSize, isLoading, pageSizeOptions, search } = this.state;
    const { t } = this.props;
    const columns = [
      {
        Header: t("IDEA_MANAGE_NUM"),
        accessor: "num",
        className: "text-center",
        maxWidth: 120,
        Cell: row => (
          <div className="lo-stats__cell-container lo-stats__cell-clickable" onClick={() => this.props.onViewIdeaPress(row.original.actions, false)}>
            {row.value}
          </div>
        )
      },
      {
        Header: t("IDEA_MANAGE_TITLE"),
        accessor: "title",
        className: "text-center",
        Cell: row => {
          const isDuplicate = row.original.actions.isDuplicate;
          const isMerged = row.original.actions.duplicateOriginalOpportunity && row.original.actions.duplicateSimilarOpportunity;

          return (
          <div className="lo-stats__cell-container lo-stats__cell-clickable" onClick={() => this.props.onViewIdeaPress(row.original.actions, false)}>
            {row.value}
            {isMerged &&
              <Badge pill style={{marginLeft: '10px', backgroundColor: '#643fda', color: 'white'}}>
                Merged
              </Badge>
            }
            {isDuplicate && 
              <span style={{ backgroundColor: '#FFD700', color: 'black', fontSize: '12px', padding: '2px 6px', borderRadius: '4px', marginLeft: '8px' }}>
                Duplicate
              </span>
            }
          </div>
        )
        }
      },
      {
        Header: t("IDEA_MANAGE_CATEGORY"),
        maxWidth: 200,
        accessor: "category",
        className: "text-center",
        style: {color: '#17c671'},
      },
      {
        Header: t("IDEA_MANAGE_STATUS"),
        maxWidth: 150,
        accessor: "status",
        className: "text-center",
        Cell: row => (
          <div className="d-table mx-auto">
            <Badge pill theme={getBadgeType(row.value)}>
              {row.value === 'SOMETIDA' ? 'Submitted' : row.value}
            </Badge>
          </div>
        )
      },
      {
        Header: 'Action',
        accessor: "actions",
        className: "text-right",
        sortable: false,
        maxWidth: 80,
        Cell: row => (
            <Badge pill outline size="sm" theme="danger" style={{cursor:'pointer'}} onClick={() => row.original.isDuplicate ? this.handleDuplicateClick(row.original.actions) : this.props.onViewIdeaPress(row.original.actions, false)}>
              Evaluate
            </Badge>
        )
      }
    ];

    const data = this.state.filteredData.map(idea => ({
      num: idea.num,
      title: idea.title,
      category: idea.category,
      status: idea.status,
      department: idea.department,
      duplicateOriginalOpportunity: '',
      duplicateSimilarOpportunity: '',
      isDuplicate: false,
      similarIdeaId: '',
      actions: idea,
    }));

    

    return(
      <Card>
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex" md="6">
                <span>{t('SHOW')}</span>
                <FormSelect
                  size="sm"
                  value={pageSize}
                  onChange={this.handlePageSizeChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size + ' ' + t('ROWS')}
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col className="file-manager__filters__search d-flex" md="6">
                <InputGroup seamless size="sm" className="ml-auto">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput value={search} onChange={this.handleFilterSearch} />
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <Container fluid className="px-0">
            {isLoading ? (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
              </div>
            ) : (
              <ReactTable
                columns={columns}
                data={data}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                showPageSizeOptions={false}
                resizable={false}
              />
            )}
          </Container>
        </CardBody>
      </Card>
    );
  }
}

/**
 * Returns the badge type for a specific
 */
function getBadgeType(itemStatus) {
  const statusMap = {
    Complete: "success",
    Saved: "warning",
    Canceled: "danger"
  };

  return statusMap[itemStatus];
}

export default withTranslation()(ManagementIdeaTable);
