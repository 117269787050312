import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  FormSelect,
  Container,
  Tooltip
} from "shards-react";
import Parse from 'parse';

import "./LocalStyles/CategorySelectStyle.css"

// Defult
import clockImage from "../../images/stopwatch.svg"
import handImage from "../../images/hand.svg"
import chartImage from "../../images/line-chart.svg"
import shieldImage from "../../images/shield.svg"

// Selected
import clockImageSelected from "../../images/stopwatch_blue.svg"
import handImageSelected from "../../images/hand_blue.svg"
import chartImageSelected from "../../images/line-chart_blue.svg"
import shieldImageSelected from "../../images/shield_blue.svg"

// Defult
import { ReactComponent as UserImage } from "../../images/user.svg"
import { ReactComponent as GearsImage } from "../../images/gears.svg"

import { ReactComponent as DownloadData } from "../../images/Download_Data.svg"
import { ReactComponent as UploadData } from "../../images/Upload_Data.svg"
import { ReactComponent as Template } from "../../images/Template.svg"

// Selected
import puzzleImageSelected from "../../images/puzzle_blue.svg"
import gearsImageSelected from "../../images/gears_blue.svg"
import { withTranslation } from 'react-i18next';

import Papa from 'papaparse';

import Select from 'react-select';

import XLSX from 'xlsx';
import csv from 'csvtojson';

const selectedColor = '#157ffb';//'#ff9012';

class SettingsActionButtons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            selectedPerson:this.props.selectedVal,
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    fetchCategoryData() {
        const className = "IdeaDepartment";
  
        var ItemClass = Parse.Object.extend(className);
        var query = new Parse.Query(ItemClass);
        query.limit(1000)
        query.ascending("firstName").find()
        .then((results) => {
            const options = []
            var coaches = []


            // if (this.props.type == 'Committee') {
            //     options.push({"value":'All Departments', "label":'All Departments'})
            // }
            
            results.map((result, idx) => options.push({"value":result, "label":result.get("itemNameTrans").en}))
               
            
            
            this.setState({
                data: results, 
                options: options
            });
            // this.props.setResponsible(results[0], -1);
        }, (error) => {
            this.setState({
                data: []
            });
          // The object was not retrieved successfully.
          // error is a Parse.Error with an error code and message.
        });
      }

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad', 
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            })
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2){
            this.setState({
                selectionValue: 'Calidad', 
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            })
             this.props.setFilter('solucion');
        } 
    }

    handleSelectedPersonChange(event, action) {
        const {data} = this.state;
        // const idx = event.target.value;
         
        
        var filterValue = event
         
        if (action.action == 'select-option') {
            
            filterValue = event.filter((v, i) =>  !v.label.includes('All '))
        }

        if (action.action == 'remove-value') {
            
            filterValue = this.props.selectedVal.filter((v, i) => v.label !== action.removedValue.label);
        }
        // this.setState({filterValue: filterValue})
        this.props.setResponsible(filterValue, 0);
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    downloadCsvData() {
        const jsonData = this.props.dataCsv
        const csv = Papa.unparse(jsonData);
        
        // Transform CSV to Blob and Download it
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "data.csv";
        link.click();

        
    }

    // prepareTemplateData(file) {

    //     csv()
    //     .fromFile(file)
    //     .then((jsonObj) => {
    //         const data = jsonObj.map((obj) => {
    //         const keys = Object.keys(obj);
    //         const values = Object.values(obj);
    //         const firstCol = {
    //             v: values[0],
    //             s: {
    //             fill: {
    //                 bgColor: { rgb: "FFFF0000" } // red background
    //             }
    //             }
    //         };
    //         const restCols = values.slice(1).map((val) => ({ v: val }));
    //         return { t: "a", r: keys.length - 1, c: 0, s: firstCol.s, v: firstCol.v, ...restCols };
    //         });
    //         const worksheet = XLSX.utils.json_to_sheet(data);
    //         const workbook = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //         const xlsxFile = XLSX.write(workbook, { type: "binary" });
    //         const blob = new Blob([this.s2ab(xlsxFile)], { type: "application/octet-stream" });
    //         const url = URL.createObjectURL(blob);
    //         return url
           
    //     }); 
    // }
    
    // prepareTemplateData(file) {
    //     return new Promise((resolve, reject) => {
    //       Papa.parse(file, {
    //         download: true,
    //         complete: (results) => {
    //           const data = results.data.map((values, rowIndex) => {
    //             // Add an empty value to the last column if it's missing
    //             if (values.length > 0 && values[values.length - 1] === "") {
    //               values[values.length - 1] = " ";
    //             }
    //             const firstCol = {
    //               v: values[0],
    //               s: {
    //                 font: {
    //                   color: { rgb: "FF0000" } // red text color
    //                 }
    //               }
    //             };
    //             const restCols = values.slice(1).map((val) => ({ v: val }));
    //             return { t: "s", r: rowIndex, c: 0, s: firstCol.s, v: firstCol.v, ...restCols };
    //           });
    //           const worksheet = XLSX.utils.json_to_sheet(data, { header: 1 });
    //           worksheet["!cols"] = [{ width: 25 }, ...Array.from({ length: data[0].length - 1 }, () => ({ width: 15 }))]; // set column widths
    //           const workbook = XLSX.utils.book_new();
    //           XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //           const xlsxFile = XLSX.write(workbook, { type: "binary" });
    //           const blob = new Blob([this.s2ab(xlsxFile)], { type: "application/octet-stream" });
    //           const url = URL.createObjectURL(blob);
    //           resolve(url);
    //         },
    //         error: (error) => {
    //           reject(error);
    //         }
    //       });
    //     });
    //   }

    //   s2ab(s) {
    //     const buf = new ArrayBuffer(s.length);
    //     const view = new Uint8Array(buf);
    //     for (let i = 0; i < s.length; i++) {
    //       view[i] = s.charCodeAt(i) & 0xFF;
    //     }
    //     return buf;
    //   }
      
      

    //   downloadTemplate() {
    //     const file = this.props.templateFile;
    //     this.prepareTemplateData(file).then((downloadFile) => {
    //       const element = document.createElement("a");
    //       element.setAttribute("href", downloadFile);
    //       element.setAttribute("download", "murmuratto_data_template.xlsx");
    //       element.style.display = "none";
    //       document.body.appendChild(element);
    //       element.click();
    //       document.body.removeChild(element);
    //     });
    //   }

    downloadTemplate() {
        const fileUrl = this.props.templateFile;
      
        fetch(fileUrl)
          .then(response => response.text())
          .then(csvText => {
            const csvData = Papa.parse(csvText, { header: true }).data;

            const data = csvData.map(row => {
                row['Last Name'] = { v: row['Last Name'] };
                row['First Name'] = { v: row['First Name'], s: { fill: {
                    type: 'pattern',
                    pattern:'solid',
                    patternType: "solid", // none / solid
                    fgColor: {rgb: "FF000000"},
                    bgColor: {rgb: "FFFFFFFF"}
                    }, } };
                
              return row;
            });
            
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const xlsxFile = XLSX.write(workbook, { type: 'binary' });
            const blob = new Blob([this.s2ab(xlsxFile)], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'murmuratto_data_template.xlsx';
            a.click();
          })
          .catch(error => {
            console.error(error);
          });
      }
      
      s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      }
      

    toggleUploadTooltip() {
        this.setState({showUploadTooltip: !this.state.showUploadTooltip})
    }

    toggleDownloadTooltip() {
        this.setState({showDownloadTooltip: !this.state.showDownloadTooltip})
    }

    toggleDownloadTemplateTooltip() {
        this.setState({showDownloadTemplateTooltip: !this.state.showDownloadTemplateTooltip})
    }

    render() {
        const { selectedPerson, data, options, coaches } = this.state;
        const { selectedVal, t } = this.props;
        
        return(
            
               
            <Container className="mx-auto">
                <Row className="mx-auto">
                    <Col md="3" >
                        <UploadData id="UploadButton" onClick={() => this.props.uploadData()} className="functionalButton ml-auto d-block " style={{minWidth: 30, maxWidth:30, minHeight: 30, maxHeight: 30}}/>
                        <Tooltip
                        open={this.state.showUploadTooltip}
                        target={"#UploadButton"}
                        id={"UploadButton"}
                        toggle={() => {this.toggleUploadTooltip()}}
                        >
                            Upload new data.
                        </Tooltip>
                    </Col >
                    <Col md="3" className="mx-1">
                        <DownloadData  id="DownloadButton" onClick={() => this.props.exportData()} className="functionalButton ml-auto d-block" style={{minWidth: 30, maxWidth:30, minHeight: 30, maxHeight: 30}}/>
                        <Tooltip
                        open={this.state.showDownloadTooltip}
                        target={"#DownloadButton"}
                        id={"DownloadButton"}
                        toggle={() => {this.toggleDownloadTooltip()}}
                        >
                            Download data. 
                        </Tooltip>
                    </Col>
                    <Col md="3">
                        <Template id="DownloadTemplateButton" onClick={() => this.downloadTemplate()} className="functionalButton ml-auto d-block" style={{minWidth: 30, maxWidth:30, minHeight: 30, maxHeight: 30}}/>
                        <Tooltip
                        open={this.state.showDownloadTemplateTooltip}
                        target={"#DownloadTemplateButton"}
                        id={"DownloadTemplateButton"}
                        toggle={() => {this.toggleDownloadTemplateTooltip()}}
                        >
                            Download template. 
                        </Tooltip>
                        
                    </Col>
                </Row>
            </Container>
                    
        )
    }
}

export default withTranslation()(SettingsActionButtons);

const myStyles = {
    container: {
        marginRight: 10,
        marginLeft: 10
    }
  }