import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from "shards-react";
import NextSteps from '../components/administration/NextSteps';
import IdeaPercentageBar from '../components/common/IdeaPercentageBar';
import IdeaViewCardNew from '../components/common/IdeaViewCardNew';
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import { authUser } from '../services/userService';
import colors from "../utils/colors";
import IdeaInfoCompleted from './IdeaInfoCompleted';


function ImplementIdea({closeModal, currIdea, updateCurrIdea, updateActivityHistory, showA3}) {
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(0)
  const [showNext, setShowNext] = useState(true)
  const [currentPage, setCurrentPage] = useState('idea')
  const [surveyModalShow, setSurveyModalShow] = useState(false)
  const [showIdeaInformation, setShowIdeaInformation] = useState(false)
  const [showA3Btn, setShowA3Btn] = useState(currIdea.progress == 100)
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [incompleteTasks, setIncompleteTasks] = useState(undefined)
  const [markTasks, setMarkTasks] = useState(undefined)
  let currUser = authUser()
  // this.getUserName()

  const getUserName = async (user) => {
   

    setUsername(authUser.fullName)

    // Update welcome title
    if (ideaStage == 0) {

      // We want to use t(VARIABLE) so that we can get the correct string
      // Replace 'VARIABLE' with t('VARIABLE_NAME')
      // VARIABLE_NAME being the corresponding variable in our new json file.

      // Uncomment the following line to create a message string
      // const welcomeMsg = 'VARIABLE' + username + '!'

      
      // Replace the contents of setTitle with our new welcomeMsg variable.
      setTitle('Keep your team updated!') // Here we are setting our title to what we want
    }
  }

  useEffect(() => {
    getUserName(currUser)
  });

  const setFinished = () => {
    setIdeaStage(3)
  }

  return (
    <Container fluid className="main-content-container px-4" style={{backgroundColor: 'white'}}>
    <Row>
      {/* Page Header :: Title */}
      <Col md="12" lg="12" className="mt-4 mx-auto">
        <Row>
          <Col md="12" lg="12">
            <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivisorBarIcon></DivisorBarIcon>
          </Col>
        </Row>
      </Col>
      {/* Page Header :: Actions */}
      
    </Row>
    
    {/* Divider */}

    {showIdeaInformation && <Row className="mt-4">
      <Col md="12" lg="12">
        <Row>
          <Col md="12" className="m-auto">
            <IdeaViewCardNew canSubmit={false} setFinishedSaving={() => setFinished()} ideaItem={currIdea}  ideaStage={0} />
          </Col>
        </Row>
      </Col>
    </Row>}

    {!showIdeaInformation &&
      <div>
        {currentPage === 'idea' &&
        <Row className="mt-4">
          <Col md="12" lg="12">
            <IdeaPercentageBar canClick={!showA3Btn} reloadData={() => setShouldReloadData(true)} currIdea={currIdea} showA3={(canShow) => showA3(canShow)} incompleteTasks={incompleteTasks} markTasksRed={setMarkTasks}></IdeaPercentageBar>
          </Col>
        </Row>
        }

        {(!currIdea.implement) && showNext && 
          <Row className="mt-4">
            <Col md="12" lg="12">
              <NextSteps changeShouldReload={(status) => setShouldReloadData(status)} currIdea={currIdea}  open={true} shouldReset={false} updateIdea={() => updateCurrIdea()} user={{}} setIncompleteTasks={(val) => setIncompleteTasks(val)} markTasksRed={markTasks}></NextSteps>
            </Col>
          </Row>
        }

        <Row className="mt-4">
          <Col md="12" lg="12">
            <IdeaInfoCompleted setPage={setCurrentPage} setShowInfo={(boolean)=>{setShowNext(!boolean)}} currIdea={currIdea} updateCurrIdea={() => this.updateCurrIdea()} closeModal={() =>   showIdeaInformation?setShowIdeaInformation(false):closeModal()} stage="completed" closeA3={() => this.setState({showA3: false})}></IdeaInfoCompleted>
          </Col>
        </Row>
      </div>
    }
   
  </Container>
  )}

  ImplementIdea.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

ImplementIdea.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(ImplementIdea);