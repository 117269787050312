import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

const fetchReportsLocalIdeas = async () => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const response = await axios.get(`${API_URL}/reports/local`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
      params: {
        limit: 11
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};


const downloadReportsLocalIdeas = async (dataExport) => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const response = await axios.get(`${API_URL}/reports/local`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
      params: {
        export: dataExport
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default {
  fetchReportsLocalIdeas,
  downloadReportsLocalIdeas,
};
