import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
  Row
} from "shards-react";
import { getSubdomain, validateResetToken } from '../services/userService';

const Register = (props) => {
    const subdomain = getSubdomain() || 'demo';
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordAlert, setPasswordAlert] = useState('');
  const [showAlert, setShowAlert] = useState(true);
  const [resetDisabled, setResetDisabled] = useState(true);
  const [token, setToken] = useState('');
  const [company, setCompany] = useState(subdomain); // Default company value

  useEffect(() => {
    const query = qs.parse(window.location.search);
    setCompany(subdomain); 
    if (query.token) {
      setToken(query.token);
    }
  }, []);

  const changePassword = (event) => {
    const password = event.target.value;
    if (!password || !passwordConfirmation) {
      setResetDisabled(true);
    } else {
      setResetDisabled(false);
    }
    setPassword(password);
  };

  const changePasswordConfirmation = (event) => {
    const passwordConfirmation = event.target.value;
    if (!password || !passwordConfirmation) {
      setResetDisabled(true);
    } else {
      setResetDisabled(false);
    }
    setPasswordConfirmation(passwordConfirmation);
  };

  const isPasswordValid = () => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,64}$/;
    return regex.test(password);
  };

  const resetPassword = async () => {
    setPasswordAlert('');
    setShowAlert(false);
    
    if (!isPasswordValid()) {
      setPasswordAlert('Please make sure your password follows the safety criteria and is the same in both fields.');
      setShowAlert(true);
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordAlert('Passwords do not match. Please make sure your password is the same in both fields.');
      setShowAlert(true);
      return;
    }

    try {

      await validateResetToken(token, company, password);

      alert('Your password has been reset!');
      props.history.push('/login');
    } catch (error) {
      alert('There was an error processing your request. Please try again later.');
      console.error('Error:', error);
    }
  };

  return(
    <Container fluid className="main-content-container h-200 px-4">
      <Row noGutters className="h-200 flex-center">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto flex-center">
          <Card className="mt-4">
            <CardBody>
              <h5 className="auth-form__title text-center mb-4">
                Reset Password
              </h5>
              <Form>
                <FormGroup>
                  <label htmlFor="newPassword">New Password</label>
                  <FormInput
                    type="password"
                    id="newPassword"
                    placeholder="Enter password"
                    autoComplete="password"
                    value={password}
                    onChange={(event) => changePassword(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="confirmPassword">Confirm New Password</label>
                  <FormInput
                    type="password"
                    id="confirmPassword"
                    placeholder="Enter password"
                    autoComplete="password"
                    value={passwordConfirmation}
                    onChange={(event) => changePasswordConfirmation(event)}
                  />
                </FormGroup>
                {showAlert && (
                  <FormGroup>
                    <small className="form-text text-center" style={{ color: 'red' }}>
                      {passwordAlert}
                    </small>
                  </FormGroup>
                )}
                <Button
                  pill
                  theme="accent"
                  disabled={resetDisabled}
                  className="d-table mx-auto"
                  onClick={() => resetPassword()}
                >
                  Reset Password
                </Button>
                <FormGroup className="mt-4">
                  <small className="form-text text-center">
                    {'Your password must be between 8 and 64 characters in length and contain characters from the following types: Upper Case Letter (A-Z), Lower Case Letter(a-z), Numeric(0-9), Special Characters (* & % $ # @ .)'}
                  </small>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <div className="auth-form__meta d-flex mt-4">
            <Link to="/login" className="mx-auto">
              Take me back to login.
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Register);
