import React from "react";
import {
    Col,
    FormSelect,
    Row,
    Tooltip
} from "shards-react";

import "./LocalStyles/CategorySelectStyle.css";

// Default
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as ExtraImage } from '../../images/innovation.svg';
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";


// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as CheckmarkImage } from "../../images/Icons_Idle_03_Checkmark.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as ProblemSolvingImage } from "../../images/Icons_Type_01_Problem Solving_NotSelected.svg";
import { ReactComponent as InnovationImage } from "../../images/Icons_Type_02_Innovation_NotSelected.svg";
import { ReactComponent as ContinuousImprovementImage } from "../../images/Icons_Type_03_ContinuousImprovement_NotSelected.svg";

// New Selected
import { withTranslation } from 'react-i18next';
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/Icons_Selected_03_Checkmark.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as ProblemSolvingImageSelected } from "../../images/Icons_Type_01_Problem Solving_Selected.svg";
import { ReactComponent as InnovationImageSelected } from "../../images/Icons_Type_02_Innovation_Selected.svg";
import { ReactComponent as ContinuousImprovementImageSelected } from "../../images/Icons_Type_03_ContinuousImprovement_Selected.svg";

import { fetchDepartments, fetchTypes } from "../../services/tableService";

// Import icons (default and selected)

const selectedColor = '#FD902c';

class IdeaFilterSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            selectionId: null,
            allCats: [],
            departments: [], // State to store department data
            right: [],
            selectedCategoryName: '',
            department: ''
        };

        this.fetchCategoryData = this.fetchCategoryData.bind(this);
        this.fetchNewData = this.fetchNewData.bind(this);
        this.change = this.change.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
        this.fetchNewData();
    }

   // Method to fetch category data
async fetchCategoryData() {
    try {
      const categories = await fetchTypes();
      this.setState({ allCats: categories, right: new Array(categories.length).fill(false) });
    } catch (error) {
      console.error('Error setting category data:', error);
    }
  }
  
  // Method to fetch department data
  async fetchNewData() {
    try {
      const departments = await fetchDepartments();
      this.setState({ departments });
    } catch (error) {
     
      this.setState({ departments: [] });
    }
  }
    

    changeSelectedValue(selectionValue, category) {
        const storageLanguage = localStorage.getItem('language') != null ? localStorage.getItem('language') : 'en';
        this.setState({
            selectionValue: selectionValue,
            selectionId: category.id,
            selectedCategoryName: category.title[storageLanguage]
        });
        this.props.setFilter(category);
    }

    getIcon(name, fillColor) {
        const newIcons = [
            {normal: <UrgentImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <UrgentImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <ProductivityImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <ProductivityImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <CheckmarkImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <CheckmarkImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <TrophyImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <TrophyImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <Shield2Image className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <Shield2ImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <DollarSignImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:8200}}/>,
             selected: <DollarSignImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <NumberOneImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <NumberOneImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <ProblemSolvingImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <ProblemSolvingImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <InnovationImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:8200}}/>,
             selected: <InnovationImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
            {normal: <ContinuousImprovementImage className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>,
             selected: <ContinuousImprovementImageSelected className="functionalButton mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
            },
        ]

        switch(name) {
            case 'HandImage':
              return <HandImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ShieldImage':
                return <ShieldImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ChartImage':
                return <ChartImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'TeamImage':
                return <TeamImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'DollarImage':
                return <DollarImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ClockImage':
                return <TimeImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            //New Icons
            case 'Urgent':
                return newIcons[0];
            case 'Productivity':
                return newIcons[1];
            case 'Checkmark':
                return newIcons[2];
            case 'Trophy':
                return newIcons[3];
            case 'Shield':
                return newIcons[4];
            case 'Dollar':
                return newIcons[5];
            case 'Number One':
                return newIcons[6];
            case 'problem-solving':
                return newIcons[7];
            case 'innovation':
                return newIcons[8];
            case 'continuous-improvement':
                return newIcons[9];
            default:
              return <ExtraImage className="functionalButton mx-auto d-block" style={{minWidth: 80, maxWidth:80,fill: fillColor}}/>;
          }
    }

    getBgColor(index) {
        return this.state.selectionValue === index ? selectedColor : '';
    }

    toggle(index) {
        const myCopy = [...this.state.right];
        myCopy[index] = !myCopy[index];
        this.setState({ right: myCopy });
    }

    change(event) {
       
        this.setState({ department: event.target.value});
        this.props.setDepartment(event.target.value);
    }

    render() {
        const { allCats, departments } = this.state;
        const { t } = this.props;
        const storageLanguage = localStorage.getItem('language') != null ? localStorage.getItem('language') : 'en';

        return (
            <div>
                <Row className='pt-2'>
                    {allCats.map((myCategory, index) => {
                        const isSelected = myCategory.id === this.state.selectionId;
                        const type = isSelected ? 'selected' : 'normal';
                        const icon = this.getIcon(myCategory.id, '#000000');
                        const myIcon = icon[type];

                        return (
                            <Col sm="12" lg="4" md="4" style={{ justifyContent: 'start', flexWrap: 'wrap' }} key={index}>
                                <Row style={{ justifyContent: 'start', alignItems: 'center' }}>
                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='pt-3' onClick={() => this.changeSelectedValue(index, myCategory)} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            {myIcon}
                                            <div style={{ textAlign: 'center' }} className='pt-4'>
                                                <h6 style={{ fontWeight: 500, color: '#303030', marginRight: 10 }}>
                                                    {myCategory.title[storageLanguage]}
                                                    <a id={"TooltipExampleFilter" + index} className="text-right" style={{ color: 'inherit', paddingLeft: '10px' }} onClick={() => this.toggle(index)}>
                                                        <InfoIcon style={{ height: 10, width: 10 }} />
                                                    </a>
                                                </h6>
                                                <Tooltip
                                                    open={this.state.right[index]}
                                                    target={"#TooltipExampleFilter" + index}
                                                    id={"TooltipExampleFilter1" + index}
                                                    toggle={() => { this.toggle(index) }}
                                                >
                                                    {myCategory.description[storageLanguage]}
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
                <Row className='pt-4'>
                    <h6 className="mt-4" style={{ fontWeight: 500, color: '#303030' }}>{t("Which department is this idea for?")}</h6>
                    <FormSelect
                        size="sm"
                        onChange={this.change}
                        value={this.state.department}
                        required
                    >
                        <option key={-1} value={-1}>{t("SUBMIT_IDEA_DepartmentSelect")}</option>
                        {departments.map((department, idx) => (
                            <option key={idx} value={department.id}>
                                {department.transcription[storageLanguage] || department.title}
                            </option>
                        ))}
                    </FormSelect>
                </Row>
            </div>
        )
    }
}

export default withTranslation()(IdeaFilterSelect);
