import React from 'react';
import { Button, Col, FormTextarea, Modal, ModalBody, ModalHeader, Row } from "shards-react";
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { submitRFI } from '../../services/userDashboardService.js';

class MoreInformationModalViewSubmittal extends React.Component {
  constructor(props) {
    super(props)  

    const { idea } = this.props
    this.state = {
      rfiMessage: this.props.text,
      idea,
      responseInfo: false,
      open: false,
    }
  }

  componentDidUpdate(prevProps) {
    const currentStatus =
      prevProps.isOpen 
    const nextStatus =
      this.props.isOpen

    if (currentStatus !== nextStatus) {
      this.setState({open: this.props.isOpen})
    }
  }

  toggle = () => {
    this.props.closeModal()
  }

  toggleInfo = () => {
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  onTextChange = (event) => {
      const text = event.target.value
      this.setState({rfiMessage: text})
  }

  updateRfi = () => {
    if (this.state.rfiMessage.length > 0) {
      this.props.changeText(this.state.rfiMessage)
    } else {
      alert('Your request cannot be empty.')
    }
  }

  updateIdea = async (rfi) => {
    const { rfiMessage } = this.state;
    if (rfiMessage.length > 0) {
      try {
        await submitRFI(rfi.id, rfiMessage);
        if(!this.props.hiddenSubmittal){
          this.viewIdea()
        }else{
          const idea = {...this.props.idea, status: "SOMETIDO"}
          console.log('idea', idea)
          this.props.updateIdea(idea)
        }
        this.toggle();

      } catch (error) {
        if(error.response.data){
          if(error.response.data.error)
            alert(error.response.data.error.message);
          else if(error.response.data.message)
            alert(error.response.data.message);
        }else
          alert(error);

        console.error('Error submitting evaluation:', error);
      }
    } else {
      alert('Your request cannot be empty.');
    }
  }

  closeModal = () => {
    this.setState({rfiMessage: ''})
    this.props.closeModal()
  }
    
  viewIdea = () => {
    this.props.viewIdea(this.props.idea)
  }

  render() {
      const { open, rfiMessage } = this.state;
      const { idea, type } = this.props;
      const { requestInformationEvaluation } = idea

      const index = requestInformationEvaluation ? requestInformationEvaluation.length - 1 : 0
      const rfi = requestInformationEvaluation ? requestInformationEvaluation[index] : {question: null, id: null}

      const hasIdea = idea != ''
      const shouldEditIdea = type == 'submitInfo'
      return (
      <Modal open={open} centered backdrop toggle={() => this.toggle()}>
        <ModalHeader>
          <div style={{display: 'flex', flexDirection: 'row'}}>
              <h5 className="m-auto" style={{fontWeight: 500, color: '#303030'}}>Information Requested</h5>
              <span 
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                fontSize: '24px',
              }}
              onClick={this.toggle}
            >
              &times;
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          {hasIdea && 
            <Row>
              <Col md={12}>
                <p><strong>{rfi.question}</strong></p>
              </Col>
            </Row>}
          <Row>
            <Col md={12}>
              <FormTextarea
                  style={{ minHeight: "120px" }}
                  id="userBio"
                  placeholder={'Type response here'}
                  value={rfiMessage}
                  onChange={(event) => this.onTextChange(event)}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                  {!this.props.hiddenSubmittal &&
                    <div className='mr-2'>
                      <Button pill outline className="btn-outline-primary-bold" onClick={() => this.viewIdea()}>
                        View Submittal
                      </Button>
                    </div>
                  }
                  <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row'}}>
                    <div className='mr-2'>
                      <AcceptIcon  className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => shouldEditIdea ? this.updateIdea(rfi) : this.updateRfi()}></AcceptIcon>
                    </div>
                    <div>
                      <CancelIcon className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.closeModal()}></CancelIcon>
                    </div>
                  </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      );
    }
  }

export default MoreInformationModalViewSubmittal