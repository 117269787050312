import Parse from 'parse';
import React from 'react';
import ReactLoading from 'react-loading';
import { Col, FormInput, FormTextarea, Modal, ModalBody, ModalHeader, Row } from "shards-react";
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as NextIcon } from "../../images/clean_next.svg";
import { ReactComponent as NextIconValid } from "../../images/clean_next_selected.svg";

import CategoryBasicSelect from '../common/CategoryBasicSelect';
import DepartmentSelect from '../common/DepartmentSelect';

class MergeIdeasModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.text,
      idea: this.props.idea,
      similarOpportunity: this.props.similarOpportunity,
      responseInfo: false,
      open: false,
      pmoAligned: '',
      canSubmit: false,
      canContinue: false,
      modalStage: 1,
      department: '',
      category: '',
      type: '',
      ideaQuestions: [],
      filterQuestions: [],
      filteredQuestions: [],
      answers: [],
      ideaTitle: '',
      loading: false,
      hasTeam: false,
      teamName: '',
      selectedEmployees: [],
      expectedReturn: 0,
    };
  }

  componentDidMount() {
    this.fetchQuestions();
    this.fetchFilterQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    const currentStatus = prevProps.isOpen;
    const nextStatus = this.props.isOpen;

    if (currentStatus !== nextStatus) {
      this.setState({ open: this.props.isOpen });
    }
    if (prevState.modalStage !== this.state.modalStage) {
      this.validateForm();
    }
    
  }

  fetchQuestions() {
    const className = "IdeaQuestion";
    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);
    query.equalTo("questionType", 'Category');
    query.find().then((results) => {
      this.setState({ ideaQuestions: results });
    }, (error) => {
      this.setState({ ideaQuestions: [] });
    });
  }

  fetchFilterQuestions() {
    const className = "IdeaQuestion";
    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);
    query.equalTo("questionType", 'Type');
    query.find().then((results) => {
      this.setState({ filterQuestions: results });
    }, (error) => {
      this.setState({ filterQuestions: [] });
    });
  }

  toggle = () => {
    this.props.closeModal();
  }

  toggleInfo = () => {
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  onTitleChange = (event) => {
    const title = event.target.value;
    this.setState({ ideaTitle: title }, this.validateForm);
  }

  onTextChange = (event) => {
    const text = event.target.value;
    this.setState({ message: text }, this.validateForm);
  }

  handleInputChange = (e, question, index) => {
    const { value } = e.target;

    const answerValue = value || ""; 
    const questionText = question.get("questionTrans") && question.get("questionTrans").en ? question.get("questionTrans").en : "";
    const isRequired = question.get("required") != null ? question.get("required") : false;

    let answers = [...this.state.answers];
    answers[index] = {
      question: questionText,
      answer: answerValue,
      required: isRequired
    };

    this.setState({ answers }, this.validateForm);
  }


  updateInfo = () => {
    if (this.state.message.length > 0) {
      this.props.changeText(this.state.message);
      this.props.changeSelection(this.state.pmoAligned);
    } else {
      alert('Your request cannot be empty.');
    }
  }

  updateIdea = () => {
    const { rfiMessage } = this.state;
    const { idea } = this.props;

    if (rfiMessage.length > 0) {
      idea.set("rfiAnswer", rfiMessage);
      idea.set("status", 'SOMETIDA');
      idea.set("needsEvaluation", true);

      Parse.Object.saveAll([idea], { useMasterKey: true }).then(() => {
        this.closeModal();
      });
    } else {
      alert('Your request cannot be empty.');
    }
  }

  closeModal = () => {
    this.setState({ rfiMessage: '' });
    this.props.closeModal();
  }

  changeModalStage = () => {
    const { modalStage } = this.state;
    if (modalStage === 1) {
      this.setState({ modalStage: 2 }, this.validateForm);
    } else if (modalStage === 2) {
      this.setState({ modalStage: 3, canSubmit: true, canContinue: false }, this.validateForm);
    } else if (modalStage === 3) {
      this.setState({ modalStage: 1, canSubmit: true }, this.validateForm);
    }
  }

  setDepartment = (res, i) => {
    this.setState({ department: res }, this.validateForm);
  }

  setCategory = (res) => {
    const { ideaQuestions } = this.state;
    var filteredData = ideaQuestions.filter(item => item.get("category") === res.label);
    this.setState(prevState => ({
      category: res,
      filteredQuestions: filteredData,
      selectedAttributes: {
        ...prevState.selectedAttributes,
        category: res.label
      }
    }), this.validateForm);
  }

  setType = (res) => {
    const { ideaQuestions } = this.state;
    var filteredData = ideaQuestions.filter(item => item.get("category") === res.label);
    this.setState(prevState => ({
      ideaType: res.label,
      filteredQuestions: filteredData,
      selectedAttributes: {
        ...prevState.selectedAttributes,
        type: res.label
      }
    }), this.validateForm);
  }

  sendSubmissionEmail = async () => {
    const { ideaTitle, department } = this.state;
    const deptName = department.label;
    const url = 'https://' + window.location.hostname;
    const currentUser = Parse.User.current();
    const userEmail = currentUser.get("email");
    const secondaryEmail = '';

    return fetch('https://murmuratto-email-service.herokuapp.com/api/account/send-submission-confirmation', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: url,
        toEmail: userEmail,
        secondaryEmail: secondaryEmail,
        ideaTitle: ideaTitle,
        ideaCategory: deptName
      })
    });
  }

validateForm = () => {
  const { modalStage, ideaTitle, message, answers, ideaQuestions, filterQuestions, department, category } = this.state;
  let isTitleValid, isDescriptionValid, areAnswersValid;

  switch (modalStage) {
    case 1:
      isTitleValid = ideaTitle.trim() !== '';
      isDescriptionValid = message.trim() !== '';
      this.setState({ canContinue: isTitleValid && isDescriptionValid });
      break;
    case 2:
      this.setState({ canContinue: department && category });
      break;
    case 3:
      
      areAnswersValid = [...ideaQuestions, ...filterQuestions].every((question, index) => {
        const answer = answers[index];
        if (question.get("required")) {
          return answer && answer.answer.trim() !== '';
        }
        return true;
      });

      this.setState({ canContinue: areAnswersValid, canSubmit: areAnswersValid});
      break;
    default:
      this.setState({ canContinue: false });
      break;
  }
}

  async getIdeaCount() {
    const Idea = Parse.Object.extend("Idea");
    const query = new Parse.Query(Idea);

    query.descending("num");

    query.limit(1);

    const highestIdea = await query.first();

    if (highestIdea) {
      return highestIdea.get("num") + 1;
    } else {
      return 1;
    }
  }


  async saveIdea() {
    const { category, message, file, answers, ideaTitle, department, expectedReturn, idea, similarOpportunity } = this.state;
    this.setState({ loading: true, canExit: false });
  
    var Idea = Parse.Object.extend("Idea");
    var ideaInfo = new Idea();
    const currentUser = Parse.User.current();
    const userId = currentUser.get("username");
    const proponentName = `${currentUser.get("firstName")} ${currentUser.get("lastName")}`
    const count = await this.getIdeaCount();
    const originDept = currentUser.get("department");

    const formatTeamMember = (obj) => {
      return {
          value: {
            __type: "Pointer",
            className: "_User",
            objectId: obj.id
          },
          label: `${obj.get('firstName')} ${obj.get('lastName')}`
        }
    }

    const transformToTeamMember = () => {
      const proponentObj1 = idea.get("proponentObj");
      const proponentObj2 = similarOpportunity.get("proponentObj");
      const proponetArray = []

      if(proponentObj1 && (proponentObj1 && proponentObj1.id !== proponentObj2.id)){
        proponetArray.push(formatTeamMember(proponentObj1))
      }
      if(proponentObj2 && (proponentObj1 && proponentObj2.id !== proponentObj1.id)){
        proponetArray.push(formatTeamMember(proponentObj2))
      }
      return proponetArray
    };

    const teamMembers = transformToTeamMember();

    ideaInfo.set("originDepartment", originDept);
    ideaInfo.set("proponentObj", currentUser);
    ideaInfo.set("proponent", userId);
    ideaInfo.set("proponentName", proponentName);
    ideaInfo.set("edited", false);
    ideaInfo.set("completed", false);
    ideaInfo.set("department", department.label);
    ideaInfo.set("category", category.label);
    ideaInfo.set("date", (new Date()));
    ideaInfo.set("num", count);
    ideaInfo.set("title", ideaTitle);
    ideaInfo.set("description", message);
    ideaInfo.set("file", file);
    ideaInfo.set("status", "SOMETIDA");
    ideaInfo.set("progress", [0, 100]);
    ideaInfo.set("filterAnswer", answers);
    ideaInfo.set("questionAnswer", []);
    ideaInfo.set("comments", []);
    ideaInfo.set("needsEvaluation", true);
    ideaInfo.set("hasTeam", true);
    ideaInfo.set("mergeDate", new Date());
    ideaInfo.set("ideaType", this.state.ideaType);

    ideaInfo.set("duplicateInfo", { "originalOpportunityId": this.state.idea.id, "similarOpportunityId": this.state.similarOpportunity.id });
    ideaInfo.set("duplicateOriginalOpportunity", this.state.idea);
    ideaInfo.set("duplicateSimilarOpportunity", this.state.similarOpportunity);

    ideaInfo.set("expectedReturn", parseFloat(expectedReturn));
    ideaInfo.set("teamName", "Duplicate");
    ideaInfo.set("teamMembers", teamMembers);
    ideaInfo.set("customUpdatedAt", new Date());
    ideaInfo.save().then((ideaInfo) => {
      this.state.idea.set("status", "Duplicate");
      this.state.idea.set("progress", [100, 0]);
      this.state.idea.set("needsEvaluation", false);
      this.state.idea.set("mergedOpportunityId", ideaInfo);
      this.state.idea.save();

      this.state.similarOpportunity.set("status", "Duplicate");
      this.state.similarOpportunity.set("progress", [100, 0]);
      this.state.similarOpportunity.set("needsEvaluation", false);
      this.state.similarOpportunity.set("mergedOpportunityId", ideaInfo);
      this.state.similarOpportunity.save();

      const IdeaDuplicates = Parse.Object.extend("Idea");
      const queryIdeas = new Parse.Query(IdeaDuplicates);      
      queryIdeas.containedIn("potentialDuplicates", [this.state.idea.id, this.state.similarOpportunity.id]);
      queryIdeas.find().then((ideasPotential) => { 
        ideasPotential.forEach((i) => {
          const potentialDuplicates = i.get("potentialDuplicates");
          const newPotentialDuplicates = potentialDuplicates.filter((id) => id !== this.state.idea.id && id !== this.state.similarOpportunity.id);
          i.set("potentialDuplicates", newPotentialDuplicates);
          i.save();
        });
      });

      fetch(`https://services.murmuratto.com/update-file/${process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo'}/Idea/file/${ideaInfo.id}`).then(() => { });
      this.sendSubmissionEmail().then(() => {
        this.setState({ loading: false });

        this.closeModal();
        window.location.reload();
      }, (error) => {
        console.error('Failed to create new object, with error code: ' + error.message);
      });
    });

  }
  

  render() {
    const { open, canSubmit, canContinue, modalStage, department, category, loading} = this.state;
    const { idea, similarOpportunity } = this.props;

    return (
      <Modal open={open} className="modal1000" centered toggle={() => this.toggle()} >
        
        <ModalHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Merge</div> 
        <span 
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '24px',
          }}
          onClick={this.closeModal}
        >
          &times;
        </span>
      </div>
      </ModalHeader>
       
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {loading ? (
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30em' }}>
              <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
            </div>
          ) : (
            <>
              {modalStage === 1 && <Row className="mb-4">
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Current Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Title</label>
                      <FormInput value={idea.get("title")} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Description</label>
                      <FormTextarea
                        style={{ minHeight: "80px" }}
                        id="userBio"
                        placeholder={'Description...'}
                        value={idea.get("description")}
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row>
                    <Col className="mb-4">
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Similar Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Title</label>
                      <FormInput value={similarOpportunity.get("title")} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Description</label>
                      <FormTextarea
                        style={{ minHeight: "80px" }}
                        id="userBio"
                        placeholder={'Description...'}
                        value={similarOpportunity.get("description")}
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Merged Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Title</label>
                      <FormInput onChange={(event) => this.onTitleChange(event)}></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Description</label>
                      <FormTextarea
                        style={{ minHeight: "80px" }}
                        id="userBio"
                        placeholder={'Description...'}
                        value={this.state.message}
                        onChange={(event) => this.onTextChange(event)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>}

              {modalStage === 2 && <Row className="mb-4">
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Current Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Department</label>
                      <FormInput value={idea.get("department")} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Category</label>
                      <FormInput value={idea.get("category")} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Type</label>
                      <FormInput value={idea.get("ideaType")} disabled></FormInput>
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Similar Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Department</label>
                      <FormInput value={similarOpportunity.get("department")} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Category</label>
                      <FormInput value={similarOpportunity.get("category")} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Type</label>
                      <FormInput value={similarOpportunity.get("ideaType")} disabled></FormInput>
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row className="mb-3">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Merged Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Department</label>
                      <DepartmentSelect className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.setDepartment(res, idx)} selectedVal={department} />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Category</label>
                      <CategoryBasicSelect className="insideFont" evalType={"execution"} setResponsible={(res, idx) => this.setCategory(res, idx)} selectedVal={category} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Type</label>
                      <CategoryBasicSelect setResponsible={(res, idx) => this.setType(res, idx)} />
                    </Col>
                  </Row>
                </Col>
              </Row>}

              {modalStage === 3 && <Row className="mb-4">
                <Col>
                  <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Current Opportunity</h6>
                  {idea.get("filterAnswer").map((question, index) => {
                    if(!question) return null;
                    const prefix = 'Q' + (index + 1) + ': ';
                    const myQuestion = prefix + question["question"];
                    return (
                      <Row className="mt-4" key={index}>
                        <Col md="12">
                          <label className="georgia">{myQuestion}</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{question["answer"]}</h6>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Similar Opportunity</h6>
                  {similarOpportunity.get("filterAnswer").map((question, index) => {
                    const prefix = 'Q' + (index + 1) + ': ';
                    const myQuestion = prefix + question["question"];
                    const required = question["required"];
                    return (
                      <Row className="mt-4" key={index}>
                        <Col md="12">
                          <label className="georgia">{myQuestion}</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{question["answer"]}</h6>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Merged Opportunity</h6>
                  {[...this.state.ideaQuestions, ...this.state.filterQuestions].map((question, index) => {
                    const myQuestion = question.get("questionTrans").en;
                    const required = question.get("required");
                    return (
                      <Row className="mt-4" key={index}>
                        <Col md="12">
                          <label className="georgia">{myQuestion} {required ? '*' : ''}</label>
                          <FormInput placeholder={question["answer"]} onChange={(e) => this.handleInputChange(e, question, index)}></FormInput>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>}

              <Row className="mt-4">
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='mr-2'>
                      {canSubmit && canContinue && <AcceptIcon className="functionalButton ml-auto" style={{ height: 34, width: 34 }} onClick={() => this.saveIdea()} />}
                      {!canSubmit && !canContinue && <NextIcon className="functionalButton ml-auto" style={{ minWidth: 34, maxWidth: 34 }} />}
                      {!canSubmit && canContinue && <NextIconValid className="functionalButton ml-auto" style={{ minWidth: 34, maxWidth: 34 }} onClick={() => this.changeModalStage()} />}
                    </div>
                  </div>
                </div>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default MergeIdeasModal;
