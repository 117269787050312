import Parse from 'parse';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "shards-react";
import CategoryTemplate from '../assets/Murmuratto_Template_Category.csv';
import CategoryItem from '../components/administration/CategoryItem';
import ImportDataModal from '../components/administration/ImportDataModal';
import SettingsActionButtons from '../components/common/SettingsActionButtons';
import { ReactComponent as AddCategoryIcon } from "../images/add_category.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import { getCategories as getCategoriesService } from "../services/categoryService";
import colors from "../utils/colors";


function AdministrationCategories(smallStats) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(1)
  const [category, setCategory] = useState('')
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [finishedSaving, setFinishedSaving] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [newCategory, setNewCategory] = useState('')
  const [activeCategories, setActiveCategories] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [dataCsv, setDataCsv] = useState([])
  
  let currUser = Parse.User.current();
  // this.getUserName()

  const setInitialTitle = async (user) => {
    setTitle('Administration > Categories')
  }

  useEffect(() => {
    // Update the document title using the browser API
    setInitialTitle()
    getCategories()
  }, []);

  const getCategories = async() => {
    const categories = await getCategoriesService();
    const count = categories.filter((cat) => cat.get("show") === true).length;

    setCategories(categories)
    transformDataToTemplate(categories)

    setActiveCategories(count)
  }

  const transformDataToTemplate = (results) => {
    const modifiedData = results.map((idea) => {
      const newData = {
        "CATEGORY ENG": idea.get("itemNameTrans").en,
        "CATEGORY 2ND LANGUAGE": idea.get("itemNameTrans").es,
        "ENG DESCRIPTION": idea.get("categoryDescription").en,
        "2ND LANG DESCRIPTION": idea.get("categoryDescription").es,
        "START DATE": ' ',
        "END DATE": ' ',
        "KPI 1": idea.get("kpi") ? (idea.get("kpi").length > 0 ? idea.get("kpi")[0].en : ' ') : ' ',
        "KPI 2": idea.get("kpi") ? (idea.get("kpi").length > 1 ? idea.get("kpi")[1].en : ' ') :  ' ',
        "KPI 3": idea.get("kpi") ? (idea.get("kpi").length > 2 ? idea.get("kpi")[2].en : ' ') : ' ',
      }

      return(newData)
    })
    
    setDataCsv(modifiedData)
  }

  const updateCategories = () => {
    setNewCategory('')
    getCategories()
  }

  const createCategory = () => {
    const IdeaCategory = Parse.Object.extend("IdeaCategory");
    const ideaCategory = new IdeaCategory();

    ideaCategory.set("itemNameTrans", {en: "", es: ""});
    ideaCategory.set("extra", true);
    ideaCategory.set("icon", "");
    ideaCategory.set("show", false);
    ideaCategory.set("itemName", 'newCategory'+Math.random())
    ideaCategory.set("categoryDescription", {en: "",es: ""})
    setNewCategory(ideaCategory)
  }

  const uploadData = () => {
    setOpenModal(true)
  }

  const onFinish = () => {
    alert("Your data was imported succesfully!")
    setOpenModal(false)
    getCategories()
  }

  return (
  <Container fluid className="main-content-container px-4 pb-4" style={{backgroundColor: 'white'}}>
    <Row>
      <Col md="10" lg="10" className="mt-4 mx-auto">
        <Row>
          <Col md="8" lg="8">
            <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
          </Col>
          <Col xs="12" md="2" lg="2" className="col d-flex align-items-center ml-auto">
            <SettingsActionButtons uploadData={() => uploadData()} templateFile={CategoryTemplate} dataCsv={dataCsv}></SettingsActionButtons>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivisorBarIcon></DivisorBarIcon>
          </Col>
        </Row>
        
      </Col>
      
    </Row>
    <Row className="mt-4">
      <Col lg="10" className="m-auto">
        <AddCategoryIcon className="functionalButton mr-4 d-block mb-4" style={{minWidth: 180, maxWidth:180}} onClick={() => createCategory()}></AddCategoryIcon>
      </Col>
    </Row>

    {newCategory && 
      <Row className="mt-2">
       <Col md="12" lg="12">
          <CategoryItem key={Math.random()} id={Math.random()} category={newCategory} isNew={true} updateCategories={updateCategories} activeCategories={activeCategories}></CategoryItem>
       </Col>
      </Row>
    }

    { categories && categories.map((category, i) => {
      return (
        <Row className="mt-2 ">
          <Col md="12" lg="12">
            <CategoryItem key={i+Math.random()} id={i+Math.random()} category={category} updateCategories={updateCategories} activeCategories={activeCategories}></CategoryItem>
          </Col>
        </Row>
      )
    })}
    <ImportDataModal open={openModal} onFinish={() => onFinish()} dataType="category"></ImportDataModal>
  </Container>
  )}

AdministrationCategories.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

AdministrationCategories.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationCategories;
