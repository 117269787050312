import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "shards-react";

import moment from 'moment';
import ReactLoading from 'react-loading';
import * as XLSX from 'xlsx';
import PageTitle from "../components/common/PageTitle";

import Parse from 'parse';
import Select, { components } from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormSelect
} from "shards-react";


import { toInteger } from "lodash";
import { withTranslation } from 'react-i18next';
import BarChart from "../components/charts/BarChart";
import PieChart from "../components/charts/PieChart";
import colors from "../utils/colors";

const blueColor = '#3A7BBB'
const orangeColor = '#FD902c'
const greenColor = '#7FA86F'
const goldColor = '#DDB153'

const parseInstance2 = Parse.initialize(
  'myappId',
  'Murmuratto123!!'
);
Parse.serverURL = 'https://mentor-mercksfo-server.murmuratto.com/parse';


class EnterpriseR1 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ideas: [],
      numSubmitted: '',
      numCompleted: '',
      numInProgress: '',
      numPendingEval: '',
      numCompletedOther: '',
      numInProgressOther: '',
      numPendingEvalOther: '',
      numSubmittedOther: '',
      departments: '',
      categories: [],
      otherCategories: [],
      ideaByDepartmentData: [],
      ideasPerSite: [],
      ideasBySiteData: [],
      progressByCategoryData: [],
      returnsByCategoryData: [],
      actualReturnsByCategoryData: [],
      completedIdeas: [],
      completeIdeas: [],
      ideasInProgress: [],
      ideasPerCats: [],
      ideaResults: [],
      showChart: false,
      showIdeasByDeptChart: false,
      showProgressByCatChart: false,
      showEarningsByCatChart: false,
      exportData: {},
      ideaDataCsv: [],
      verifications: [],
      otherVerifications: [],
      showChartActual: false,
      otherIdeaResults: [],
      selectedOption: 'All Sites',
      showIdeasBySiteData: false,
      numVerifiedOther: '',
      numVerified: '',
      numEmployees: 0,
      numEmployeesOther: 0,
      selectedDate: 'All Time',
      selectedDateValue: 'All Time',
      numSubmittedOther1: '',
      numSubmittedOther2: '',
      numSubmittedOther3: '',
      numSubmittedOther4: '',
      otherIdeaResults1: [],
      otherIdeaResults2: [],
      otherIdeaResults3: [],
      otherIdeaResults4: [],
      numVerifiedOther1: '',
      numVerifiedOther2: '',
      numVerifiedOther3: '',
      numVerifiedOther4: '',
      numCompletedOther1: '',
      numCompletedOther2: '',
      numCompletedOther3: '',
      numCompletedOther4: '',
      categories1: [],
      categories2: [],
      categories3: [],
      categories4: [],
      users1: [],
      users2: [],
      users3: [],
      users4: [],
      ideasByTypeForPie: [],
      ideasByCategoryForPie: [],
      dataIsLoading: true,
      selectedSites: [],
      dataSites: [],
      sites: [
        { value: 'site1', label: 'SFO' },
        { value: 'site2', label: 'Amgen' },
        { value: 'site3', label: 'BOS' },
        { value: 'site4', label: 'Janssen' },
      ],
    }

    this.getIdeas = this.getIdeas.bind(this);
    this.getResults = this.getResults.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }
  
  handleSiteChange(selectedSites) {
    this.setState({ selectedSites });
  }

  
  handleDownload() {
    function formatDate(date) {
      if (!date) return 'N/A';
      const momentDate = moment(date);
      return momentDate.isValid() ? momentDate.format('MM/DD/YYYY') : 'N/A';
    }
  
    const { selectedSites, dataSites } = this.state;
    const workbook = XLSX.utils.book_new();
    const ideaTypeMapping = {
      'innovacion': 'Innovation',
      'mejoras': 'Continuous Improvement',
      'problema': 'Problem Solving'
    };

    const ideaStageMapping = {
      'SOMETIDA': 'Submitted'
    };
    

    selectedSites.forEach((site) => {
      const getData = dataSites.find((dataSite) => dataSite.site === site.value) || null;
      const dataOpportunities = getData && getData.results ;
      const dataVerification = getData && getData.verification ;
      const data = [
        ['Opportunity Number', 'Title', 'Description', 'Category', 'Type', 'Origin Department', 'Department Benefitted', 'Submitted', 'Stage', 'Evaluated At', 'Implemented At', 'Forecast Economic Impact', 'Verified Economic Impact', 'Originator'],
      ];
      
      if(dataOpportunities.length > 0){
        dataOpportunities.forEach( async (opportunity) => {
          const verify = dataVerification.filter(verification => verification.get("idea").objectId === opportunity.id);
          const opportunityData = [
            opportunity.get('num'),
            opportunity.get('title'),
            opportunity.get('description'),
            opportunity.get('category'),
            `${ideaTypeMapping[opportunity.get("ideaType")] || opportunity.get("ideaType")}`,
            opportunity.get("originDepartment"),
            '',
            `${formatDate(opportunity.get("date").iso)}`,
            `${ideaStageMapping[opportunity.get("status")] || opportunity.get("status")}`,
            `${formatDate(opportunity.get("evaluatedAt") ? opportunity.get("evaluatedAt").iso : null)}`,
            `${opportunity.get("implementAt") ? formatDate(opportunity.get("implementAt")) : 'N/A'}`,
            `${opportunity.get("expectedReturn") || 'N/A'}`,
            `${verify.length > 0 ? (verify[0].get('money') ? verify[0].get('money') : 'N/A') : 'N/A'}`,
            `${opportunity.get("proponentName")}`,
          ];
          data.push(opportunityData);
        });
      }

      const worksheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet, site.label);
    });

    XLSX.writeFile(workbook, 'report.xlsx');
  }

  componentWillMount() {
    this.getIdeas();
    this.getResults();
    this.getUsers();
    this.getDepartments();
    this.fetchAndSetStatesNew();
    this.getOtherVerification()
    this.getOtherCategories()
    this.getUsersOther()
  }

  // Function to handle fetching data for different URLs and setting states accordingly
  fetchAndSetStatesNew = async() => {
    const urlBase1 = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/';
    const urlBase2 = 'https://mentor-amgen-server.murmuratto.com/parse/classes/';
    const urlBase3 = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/';
    const urlBase4 = 'https://mentor-janssen-server.murmuratto.com/parse/classes/';

    const url1Ver = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/Verification?limit=500';
    const url2Ver = 'https://mentor-amgen-server.murmuratto.com/parse/classes/Verification?limit=500';
    const url3Ver = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/Verification?limit=500';
    const url4Ver = 'https://mentor-janssen-server.murmuratto.com/parse/classes/Verification?limit=500';

    const url1Cat = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/IdeaCategory?limit=500';
    const url2Cat = 'https://mentor-amgen-server.murmuratto.com/parse/classes/IdeaCategory?limit=500';
    const url3Cat = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/IdeaCategory?limit=500';
    const url4Cat = 'https://mentor-janssen-server.murmuratto.com/parse/classes/IdeaCategory?limit=500';

    const url1User = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/_User?limit=500';
    const url2User = 'https://mentor-amgen-server.murmuratto.com/parse/classes/_User?limit=500';
    const url3User = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/_User?limit=500';
    const url4User = 'https://mentor-janssen-server.murmuratto.com/parse/classes/_User?limit=500';
    

    const key1 = 'JKBkO5sIGCzm'
    const key2 = 'Murmuratto123!!'
    const key3 = 'SQ/5wMkh2HJG'
    const key4 = 'ovFIiAXb84F+'

    this.setState({dataIsLoading: true})

    try {
      const results1 = await this.tryFetchNew(urlBase1, key1);
      const results2 = await this.tryFetchNew(urlBase2, key2);
      const results3 = await this.tryFetchNew(urlBase3, key3);
      const results4 = await this.tryFetchNew(urlBase4, key4);
     
      const verification1 = await this.getOtherVerificationNew(url1Ver, key1);
      const verification2 = await this.getOtherVerificationNew(url2Ver, key2);
      const verification3 = await this.getOtherVerificationNew(url3Ver, key3);
      const verification4 = await this.getOtherVerificationNew(url4Ver, key4);

      const categories1 = await this.getOtherCategoriesNew(url1Cat, key1);
      const categories2 = await this.getOtherCategoriesNew(url2Cat, key2);
      const categories3 = await this.getOtherCategoriesNew(url3Cat, key3);
      const categories4 = await this.getOtherCategoriesNew(url4Cat, key4);

      const users1 = await this.getUsersOtherNew(url1User, key1);
      const users2 = await this.getUsersOtherNew(url2User, key2);
      const users3 = await this.getUsersOtherNew(url3User, key3);
      const users4 = await this.getUsersOtherNew(url4User, key4);

      this.setState({
        dataSites: [
          { site: 'site1', results: results1, verification: verification1 },
          { site: 'site2', results: results2, verification: verification2   },
          { site: 'site3', results: results3, verification: verification3  },
          { site: 'site4', results: results4, verification: verification4  }
        ],
        otherIdeaResults1: results1,
        otherIdeaResults2: results2,
        otherIdeaResults3: results3,
        otherIdeaResults4: results4,
        verification1: verification1,
        verification2: verification2,
        verification3: verification3,
        verification4: verification4,
        categories1: categories1,
        categories2: categories2,
        categories3: categories3,
        categories4: categories4,
        users1: users1,
        users2: users2,
        users3: users3,
        users4: users4,
        numCompletedOther1: String(results1.filter(idea => idea.get("progress")[0] == 100).length),
        numCompletedOther2: String(results2.filter(idea => idea.get("progress")[0] == 100).length),
        numCompletedOther3: String(results3.filter(idea => idea.get("progress")[0] == 100).length),
        numCompletedOther4: String(results4.filter(idea => idea.get("progress")[0] == 100).length),
        numVerifiedOther1: results1.filter(idea => idea.has("verification")).length,
        numVerifiedOther2: results2.filter(idea => idea.has("verification")).length,
        numVerifiedOther3: results3.filter(idea => idea.has("verification")).length,
        numVerifiedOther4: results4.filter(idea => idea.has("verification")).length,
        numRROther1: results1.filter(idea => idea.get("needsRecognition")).length,
        numRROther2: results2.filter(idea => idea.get("needsRecognition")).length,
        numRROther13: results3.filter(idea => idea.get("needsRecognition")).length,
        numRROther4: results4.filter(idea => idea.get("needsRecognition")).length,
        numSubmittedOther1: String(results1.length),
        numSubmittedOther2: String(results2.length),
        numSubmittedOther3: String(results3.length),
        numSubmittedOther4: String(results4.length),
        numEvaluatedOther1: String(results1.filter(idea => idea.get("evaluatedBy") !== undefined).length),
        numEvaluatedOthe2: String(results2.filter(idea => idea.get("evaluatedBy") !== undefined).length),
        numEvaluatedOthe3: String(results3.filter(idea => idea.get("evaluatedBy") !== undefined).length),
        numEvaluatedOthe4: String(results4.filter(idea => idea.get("evaluatedBy") !== undefined).length),
        numInProgressOther1: String(results1.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100).length),
        numInProgressOther2: String(results2.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100).length),
        numInProgressOther3: String(results3.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100).length),
        numInProgressOther4: String(results4.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100).length),
        numPendingEvalOther1: String(results1.filter(idea => idea.get("status") == 'SOMETIDA').length),
        numPendingEvalOther2: String(results2.filter(idea => idea.get("status") == 'SOMETIDA').length),
        numPendingEvalOther3: String(results3.filter(idea => idea.get("status") == 'SOMETIDA').length),
        numPendingEvalOther4: String(results4.filter(idea => idea.get("status") == 'SOMETIDA').length),
        // Set other states as needed
      }, () => {
        this.setupIdeasBySiteData()
       this.getCorrectIdeasByTypeNew()
       this.setupNumberOfIdeasByCategoryDataNewPie()
       this.setState({ dataIsLoading: false });
      });
    } catch (error) {
      console.error('Error while fetching:', error);
    }
  }

  handleOptionChange = (newSite) => {
    this.setState({
      selectedOption: newSite.target.value
    }, () => {
      this.setupNumberOfIdeasByCategoryDataNewPie()
      this.getCorrectIdeasByTypeNew()
    });
  }

  handleDateChange = (newDate) => {
    if (newDate.target.value === 'All Time') {
      this.setState({
        selectedDate: 'All Time',
        selectedDateValue: newDate.target.value
      }, () => {      
        this.fetchAndSetStatesNew();
      });
    } else if(newDate.target.value === 'last_month'){
      const correctedDate = moment().subtract(1, 'months').startOf('month')
      this.setState({
        selectedDate: correctedDate,
        selectedDateValue: newDate.target.value
      }, () => {      
        this.fetchAndSetStatesNew();
      });
    } else {
      const numOfDays = parseInt(newDate.target.value)
      const correctedDate = moment().subtract(numOfDays, 'days').startOf('day')
      
      this.setState({
        selectedDate: correctedDate,
        selectedDateValue: newDate.target.value
      }, () => {      
        this.fetchAndSetStatesNew();
      });
    }
  }

  async getUsers() {
    const  { selectedDate } = this.state;
    const query = new Parse.Query(Parse.User);

    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      query.greaterThan("createdAt", queryDate.toISOString())
    }

    const results = await query.find();
    this.setState({
      numEmployees: results.length
    })
  }

  async tryFetch() {
    const { selectedDate } = this.state;
    let correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/Idea?limit=500'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/Idea?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }


    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
       'X-Parse-Master-Key': 'JKBkO5sIGCzm'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('Idea');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

        const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
        const ideasInProgress = results.filter(idea => idea.get("status") == 'Approved' && !idea.get("result"));
        const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);
        const verifiedIdeas = results.filter(idea => idea.has("verification"));
        
        this.getNumOfEmployeesWhoHaveSubmittedIdeas(results)
        this.setState({ otherIdeaResults: results, numVerifiedOther: verifiedIdeas.length, numCompletedOther: String(completedIdeas.length), numInProgressOther: String(ideasInProgress.length), numPendingEvalOther: String(ideasPendingEval.length), numSubmittedOther: String(results.length) }, () => this.setupIdeasBySiteData());
      })
      .catch(error => console.error(error));
  }

  // Modified tryFetch function to accept URL as a parameter and return the results
  async tryFetchNew(url, masterKey) {
    const { selectedDate, selectedDateValue } = this.state;

    let correctUrl = `${url}Idea?limit=1000`;

    if (selectedDateValue !== 'All Time') {
      const queryDate = selectedDate;

      if(selectedDateValue === 'last_month'){
        const startOfMonth = moment().subtract(1, 'months').startOf('month').format('ddd MMM DD YYYY HH:mm:ss ZZ');
        const endOfMonth = moment().subtract(1, 'months').endOf('month').format('ddd MMM DD YYYY HH:mm:ss ZZ');
        correctUrl = `${correctUrl}&where={"createdAt":{"$gte":{"__type":"Date","iso":"${startOfMonth}"},"$lte":{"__type":"Date","iso":"${endOfMonth}"}}}`;
      }else{
        correctUrl = `${correctUrl}&where={"createdAt":{"$gte":{"__type":"Date","iso":"${queryDate}"}}}`;
      }
    }

    let userCache = {}
    return fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': masterKey
      }
    })
      .then(response => response.json())
      .then(data => {
        return data.results.map(result => {
          const Idea = Parse.Object.extend('Idea');
          var newIdea = new Idea();

          for (const property in result) {

            //search implement
            if (property === 'result') {
              if (result[property]) {
                fetch(`${url}Result/${result[property].objectId}`, {
                  headers: {
                    'X-Parse-Application-Id': 'myappID',
                    'X-Parse-Master-Key': masterKey
                  }
                })
                  .then(response => response.json())
                  .then(resultImplement => {
                    newIdea.set('implement', resultImplement);
                    newIdea.set('implementAt', new Date(resultImplement.createdAt));
                  })
                  .catch(error => {
                    console.error('Error fetching result:', error);
                    newIdea.set('implement', null);
                    newIdea.set('implementAt', null);
                  });
              } else {
                newIdea.set('implement', null);
                newIdea.set('implementAt', null);
              }
            }else if (result.hasOwnProperty(property)) {
              
              newIdea.set(property, result[property]);
            }
          }

          if(!newIdea.get('originDepartment')){
            if(userCache[newIdea.get('proponentObj').objectId]){
                newIdea.set('originDepartment', userCache[newIdea.get('proponentObj').objectId].department);
            }else{
              fetch(`${url}_User/${newIdea.get('proponentObj').objectId}`, {
                headers: {
                  'X-Parse-Application-Id': 'myappID',
                  'X-Parse-Master-Key': masterKey
                }
              })
              .then(response => response.json())
              .then(resultUser => {
                userCache[resultUser.objectId] = resultUser;
                newIdea.set('originDepartment', resultUser.department);
              })
              .catch(error => {
                newIdea.set('originDepartment', null);
              });
            }
          }
          return newIdea;
        });
      })
      .catch(error => {
        console.error(error);
        return [];
      });
  }

  // Modified getOtherVerification function to accept URL as a parameter and return the results
async getOtherVerificationNew(url, masterKey) {
  const { selectedDate } = this.state;

  let correctUrl = url;

  if (selectedDate !== 'All Time') {
    const queryDate = selectedDate;
    correctUrl = `${url}&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + ${queryDate.toISOString()} + '"}}}`;
  }

  try {
    const response = await fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': masterKey
      }
    });

    const data = await response.json();

    const results = data.results.map(result => {
      const Idea = Parse.Object.extend('Verification');
      var newIdea = new Idea();

      for (const property in result) {
        if (result.hasOwnProperty(property)) {
          newIdea.set(property, result[property]);
        }
      }

      return newIdea;
    });

    return results;
  } catch (error) {
    console.error(error);
    return [];
  }
}

// Modified getOtherCategories function to accept URL as a parameter and return the results
async getOtherCategoriesNew(url, masterKey) {
  const { selectedDate } = this.state;

  let correctUrl = url;

  if (selectedDate !== 'All Time') {
    const queryDate = selectedDate;
    correctUrl = `${url}&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + ${queryDate.toISOString()} + '"}}}`;
  }

  try {
    const response = await fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': masterKey
      }
    });

    const data = await response.json();

    const results = data.results.map(result => {
      const Idea = Parse.Object.extend('IdeaCategory');
      var newIdea = new Idea();

      for (const property in result) {
        if (result.hasOwnProperty(property)) {
          newIdea.set(property, result[property]);
        }
      }

      return newIdea;
    });

    return results;
  } catch (error) {
    console.error(error);
    return [];
  }
}

  // Modified getUsersOther function to accept URL as a parameter and return the results
  async getUsersOtherNew(url, masterKey) {
    const { selectedDate } = this.state;
    let correctUrl = url;

    if (selectedDate !== 'All Time') {
      correctUrl = `${url}&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}`;
    }

    try {
      const response = await fetch(correctUrl, {
        headers: {
          'X-Parse-Application-Id': 'myappID',
          'X-Parse-Master-Key': masterKey
        }
      });

      const data = await response.json();

      const results = data.results;

      // Filter out users from 'Setup' department or with 'super_user' role
      const filteredResults = results.filter(user => user.department !== 'Setup' && user.department !== 'Corporate');
      
      return filteredResults.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  }



  async getOtherVerification() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/Verification?limit=500'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/Verification?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }

    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
       'X-Parse-Master-Key': 'JKBkO5sIGCzm'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('Verification');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

       

        


        this.setState({ otherVerifications: results });
      })
      .catch(error => console.error(error));
  }

  async getOtherCategories() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/IdeaCategory'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/IdeaCategory&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }
    
    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
       'X-Parse-Master-Key': 'JKBkO5sIGCzm'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('IdeaCategory');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

        this.setState({ otherCategories: results });
      })
      .catch(error => console.error(error));
  }

  async getUsersOther() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/_User?limit=500'

    if (selectedDate !==  'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://mentor-mercksfo-server.murmuratto.com/parse/classes/_User?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }

    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
       'X-Parse-Master-Key': 'JKBkO5sIGCzm'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results   
        this.setState({ numEmployeesOther: results.length });
      })
      .catch(error => console.error(error));
  }

  // Third Site
  async tryFetchThird() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/Idea?limit=500'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://baxter-merckbos-server.murmuratto.com/parse/classes/Idea?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }


    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': 'SQ/5wMkh2HJG'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('Idea');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

        const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
        const ideasInProgress = results.filter(idea => idea.get("status") == 'Approved' && !idea.get("result"));
        const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);
        const verifiedIdeas = results.filter(idea => idea.has("verification"));
        
        this.getNumOfEmployeesWhoHaveSubmittedIdeas(results)

        this.setState({ otherIdeaResultsThird: results, numVerifiedOtherThird: verifiedIdeas.length,numCompletedOtherThird: String(completedIdeas.length), numInProgressOtherThird: String(ideasInProgress.length), numPendingEvalOtherThird: String(ideasPendingEval.length), numSubmittedOtherThird: String(results.length) }, () => this.setupIdeasBySiteData());
      })
      .catch(error => console.error(error));
  }

  async getOtherVerificationThird() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/Verification?limit=500'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/Verification?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }

    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': 'SQ/5wMkh2HJG'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('Verification');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });
        this.setState({ otherVerificationsThird: results });
      })
      .catch(error => console.error(error));
  }

  async getOtherCategoriesThird() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/IdeaCategory'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/IdeaCategory&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }
    
    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
       'X-Parse-Master-Key': 'JKBkO5sIGCzm'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('IdeaCategory');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

        this.setState({ otherCategoriesThird: results });
      })
      .catch(error => console.error(error));
  }

  async getUsersOtherThird() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/_User?limit=500'

    if (selectedDate !==  'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://mentor-merckbos-server.murmuratto.com/parse/classes/_User?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }

    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
       'X-Parse-Master-Key': 'JKBkO5sIGCzm'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results   
        this.setState({ numEmployeesOtherThird: results.length });
      })
      .catch(error => console.error(error));
  }

  getNumOfEmployeesWhoHaveSubmittedIdeas(ideas) {
    const ideasByProponent = ideas.reduce((countByProponent, idea) => {
      const proponent = idea.get('proponent');
      countByProponent[proponent] = (countByProponent[proponent] || 0) + 1;
      
      return countByProponent;
    }, {});

    const numIdeasByProponent = Object.keys(ideasByProponent).length;
    
   return numIdeasByProponent
  }

  async getVerification() {
    const { selectedDate } = this.state;
    const VerifyObject = Parse.Object.extend("Verification");
    const displayLimit = 1000;
    const query = new Parse.Query(VerifyObject);
    query.limit(displayLimit)
    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      query.greaterThan("createdAt", new Date(queryDate))
    }

    const results = await query.find();

    var editedResults = [];

    if (this.state.ideas.length > 0) {
      editedResults = results.map((verification) => {
        const ideaId = verification.get("idea") ? verification.get("idea").id : null;
    
        if (ideaId) {
          const idea = this.state.ideas.find(idea => idea.id === ideaId);
          
          if (idea) {
            verification.set("category", idea.get("category"));
            verification.category = idea.get("category");
          }
        }
        
        return verification;
      });
    } else {
      editedResults = [];
    }
    
   
    this.setupActualEarningsByCategoryData(editedResults)
    this.setState({verifications: editedResults})
  }

  setupNumberOfIdeasByCategoryData() {
    const { ideas, categories} = this.state;
  
    const zeroData = categories.map((category) => {
      const categoryName = category.get('itemName');
      const filteredDataCount = ideas.filter((idea) => idea.get('category') === categoryName);
  
      const numOfIdeas = filteredDataCount.length;
      return { x: categoryName, y: numOfIdeas };
    });
  
    const series = [
      {
        name: 'Ideas',
        data: zeroData,
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c']
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Category',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: 'Number of Ideas',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };


  
    return demoData;
  }

  setupNumberOfIdeasByCategoryDataNew() {
    const { selectedOption, otherIdeaResults1, otherIdeaResults2, otherIdeaResults3, otherIdeaResults4 } = this.state;
    const allIdeas = [...otherIdeaResults1, ...otherIdeaResults2, ...otherIdeaResults3, ...otherIdeaResults4]
    const {t} = this.props;
    let zeroData = []
    let ideasBySite;

    if (selectedOption === 'All Sites') {
      ideasBySite = allIdeas
    } else if (selectedOption === 'Site 1') {
      ideasBySite = otherIdeaResults1;
    } else if (selectedOption === 'Site 2') {
      ideasBySite = otherIdeaResults2;
    } else if (selectedOption === 'Site 3') {
      ideasBySite = otherIdeaResults3;
    } else if (selectedOption === 'Site 4') {
      ideasBySite = otherIdeaResults4;
    }

    const categoriesArray = [...(new Set(ideasBySite.map(idea => idea.get('category'))))];
    zeroData = categoriesArray.map((categoryName) => {
      const filteredDataCount = ideasBySite.filter((idea) => idea.get('category') === categoryName);
      const numOfIdeas = filteredDataCount.length;
      return { x: categoryName, y: numOfIdeas };
    });
    
    
    const series = [
      {
        name: 'Ideas',
        data: zeroData,
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c']
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: t('CHART_CATEGORIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };


  
    return demoData;
  }

  setupNumberOfIdeasByTypeDataNew() {
    const { otherIdeaResults1, otherIdeaResults2, otherIdeaResults3, otherIdeaResults4, selectedOption } = this.state;

    const { t } = this.props;

    const innovationIdeas = otherIdeaResults1.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeas = otherIdeaResults1.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeas = otherIdeaResults1.filter(idea => idea.get("ideaType") === "mejoras").length

    const innovationIdeasOther = otherIdeaResults2.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeasOther = otherIdeaResults2.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeasOther = otherIdeaResults2.filter(idea => idea.get("ideaType") === "mejoras").length

    const innovationIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "mejoras").length

    const innovationIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("ideaType") === "mejoras").length
  
    let data = [];

    if (selectedOption === 'All Sites') {
      data = [
        { x: 'Innovation', y: innovationIdeas + innovationIdeasOther + innovationIdeasOther3 + innovationIdeasOther4 },
        { x: 'Problem Solving', y: problemSolvingIdeas + problemSolvingIdeasOther + problemSolvingIdeasOther3 + problemSolvingIdeasOther4 },
        { x: 'Continuous Improvement', y: continuousImprovementIdeas + continuousImprovementIdeasOther + continuousImprovementIdeasOther3 + continuousImprovementIdeasOther4 }
      ];
    } else if (selectedOption === 'Site 1') {
      data = [
        { x: 'Innovation', y: innovationIdeas },
        { x: 'Problem Solving', y: problemSolvingIdeas },
        { x: 'Continuous Improvement', y: continuousImprovementIdeas }
      ];
    } else if (selectedOption === 'Site 2') {
      data = [
        { x: 'Innovation', y: innovationIdeasOther },
        { x: 'Problem Solving', y: problemSolvingIdeasOther },
        { x: 'Continuous Improvement', y: continuousImprovementIdeasOther }
      ];
    } else if (selectedOption === 'Site 3') {
      data = [
        { x: 'Innovation', y: innovationIdeasOther3 },
        { x: 'Problem Solving', y: problemSolvingIdeasOther3 },
        { x: 'Continuous Improvement', y: continuousImprovementIdeasOther3 }
      ];
    } else if (selectedOption === 'Site 4') {
      data = [
        { x: 'Innovation', y: innovationIdeasOther4 },
        { x: 'Problem Solving', y: problemSolvingIdeasOther4 },
        { x: 'Continuous Improvement', y: continuousImprovementIdeasOther4 }
      ];
    }
      
    const series = [
      {
        name: 'Ideas',
        data: data,
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c']
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: t('OPPORTUNITY_TYPE'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };


  
    return demoData;
  }

  setupNumberOfIdeasByCategoryDataNewPie() {
    const {
      otherIdeaResults1,
      otherIdeaResults2,
      otherIdeaResults3,
      otherIdeaResults4,
      selectedOption
    } = this.state;
  
    const allIdeas = [...otherIdeaResults1, ...otherIdeaResults2, ...otherIdeaResults3, ...otherIdeaResults4];
  
    let zeroData = []
    let ideasBySite;

    if (selectedOption === 'All Sites') {
      ideasBySite = allIdeas
    } else if (selectedOption === 'Site 1') {
      ideasBySite = otherIdeaResults1;
    } else if (selectedOption === 'Site 2') {
      ideasBySite = otherIdeaResults2;
    } else if (selectedOption === 'Site 3') {
      ideasBySite = otherIdeaResults3;
    } else if (selectedOption === 'Site 4') {
      ideasBySite = otherIdeaResults4;
    }

    const categoriesArray = [...(new Set(ideasBySite.map(idea => idea.get('category'))))];
    zeroData = categoriesArray.map((categoryName, index) => {
      const filteredDataCount = ideasBySite.filter((idea) => idea.get('category') === categoryName);
      const numOfIdeas = filteredDataCount.length;
      return { label: categoryName, value: numOfIdeas, color: this.getCategoryColor(index) };
    });

    this.setState({ideasByCategoryForPie: zeroData})
  }

  getCategoryColor(i) {
    const colors = ['#86a80a', '#435226', '#39859a', '#1b859c', '#639c2b', '#1219a4', '#08aba1', '#516a45', '#0e226a', '#792f97', '#761154', '#b16503', '#c68b4b', '#15a275', '#072157', '#42b954', '#84704c', '#206156', '#3557a5', '#95a436', '#70657a', '#28739a', '#1c0f68', '#5abac2', '#1f6a95', '#c776b8', '#49a632', '#990e5d', '#b19f66', '#9dc385', '#6e513b', '#70b708', '#7ba308', '#584da9', '#75ac87', '#7a9336', '#9e7b3e', '#433d1e', '#b92f31', '#148616', '#3746aa', '#69c264', '#6f2a18', '#4a972b', '#958239', '#8db332', '#973553', '#a46438', '#5a9e6f', '#c86241'];
    const colorLength = colors.length;

    const index = i % colorLength;
    return colors[index];
  }


  async getIdeas() {
    const { selectedDate } = this.state;
    const IdeaObject = Parse.Object.extend("Idea");
    const displayLimit = 1000;
    const query = new Parse.Query(IdeaObject);
    query.limit(displayLimit)

    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      query.greaterThan("createdAt", new Date(queryDate))
    }

    const results = await query.find();
    const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
    const ideasInProgress = results.filter(idea => idea.get("status") == 'Approved' && !idea.get("result"));
    const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);
    const verifiedIdeas = results.filter(idea => idea.has("verification"));
    

    this.setState({
      ideas: results,
      completedIdeas: completedIdeas,
      verifiedIdeas: verifiedIdeas.length,
      numVerified: verifiedIdeas.length,
      ideasInProgress: ideasInProgress,
      numCompleted: String(completedIdeas.length),
      numInProgress: String(ideasInProgress.length),
      numSubmitted: String(results.length),
      numPendingEval: String(ideasPendingEval.length),
    }, () => {
       
      this.getDepartments()
    })
  }

  async getIdeasOtherSource() {
    const IdeaObject = parseInstance2.Object.extend("Idea");
    const displayLimit = 1000;
    const query = new parseInstance2.Query(IdeaObject);
    query.limit(displayLimit)
    const results = await query.find();
    const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
    const ideasInProgress = results.filter(idea => idea.get("status") == 'Approved' && !idea.get("result"));
    const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);

    this.setState({
      ideas: results,
      completedIdeas: completedIdeas,
      ideasInProgress: ideasInProgress,
      numCompleted: String(completedIdeas.length),
      numInProgress: String(ideasInProgress.length),
      numSubmitted: String(results.length),
      numPendingEval: String(ideasPendingEval.length),
    })
  }



  async getResults() {
    const  { selectedDate } = this.state;
    const IdeaObject = Parse.Object.extend("Result");
    const query = new Parse.Query(IdeaObject);

    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      query.greaterThan("createdAt", queryDate.toISOString())
    }

    const results = await query.find();
    this.setState({ ideaResults: results });
  }

  async getCategories() {
    const Category = Parse.Object.extend("IdeaCategory");
    const query = new Parse.Query(Category);
    const results = await query.find();
    this.setState({ categories: results }, () => this.setupProgressByCategoryData());
  }

  async getDepartments() {
    this.getCategories();
    // Modify Idea Data
    this.downloadIdeaData()
    const Department = Parse.Object.extend("IdeaDepartment");
    const query = new Parse.Query(Department);
    const results = await query.find();
    this.setState({ departments: results }, () => {
      this.getVerification();
      // this.setupIdeaByDepartmentData()
    });
  }

  setupIdeaByDepartmentData() {
    const { ideas, categories, completedIdeas, ideasInProgress, departments } = this.state;
    var inProgressData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideasInProgress.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      inProgressData.push([deptName, newNum])
    }

    var completedData = [];
    var completedIdeas1 = [];
    var colors = [greenColor, blueColor, orangeColor, goldColor, 'red'];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = completedIdeas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      completedData.push([deptName, newNum]);
      completedIdeas1.push({ title: deptName, value: newNum, color: colors[i] });
    }

    var ideasPerCategory = []
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      const newNum = filteredDataCount.length;
      ideasPerCategory.push({ title: categoryName, value: newNum, color: colors[i] });
    }

    var submittedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      submittedData.push([deptName, newNum])
    }

    const exportData = {
      'Submitted': submittedData,
      'In Progress': ideasPerCategory,
      "Completed": completedData
    }

     


    const results = [{
      label: 'Completed',
      data: completedData
    },
    {
      label: 'In Progress',
      data: inProgressData
    },
    {
      label: 'Submitted',
      data: submittedData
    }, []];

     
    this.setState({ ideaByDepartmentData: results, exportData: ideas, completeIdeas: completedIdeas1, ideasPerCats: ideasPerCategory, showIdeasByDeptChart: true });
  }

  setupIdeasBySiteData() {
    const { otherIdeaResults1, otherIdeaResults2, otherIdeaResults3 } = this.state;
    const allIdeasNum = [...otherIdeaResults1, ...otherIdeaResults2, ...otherIdeaResults3].length;
    const site1IdeasNum = otherIdeaResults1.length;
    const site2IdeasNum = otherIdeaResults2.length;
    const site3IdeasNum = otherIdeaResults3.length;
 
    const results = [{
      label: 'All Sites',
      data: [allIdeasNum]
    },
    {
      label: 'SFO',
      data: [site1IdeasNum]
    },
    {
      label: 'Amgen',
      data: [site2IdeasNum]
    }, 
    {
      label: 'Boston',
      data: [site3IdeasNum]
    }, 
    []];

     

    this.setState({ ideasBySiteData: results, showIdeasBySiteData: true });

  }

  setupProgressByCategoryData() {

    this.setupEarningsByCategoryData()

    const { ideas, categories } = this.state;
     
    var zeroData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 0)
      const newNum = filteredDataCount.length;
      zeroData.push([categoryName, newNum])
    }

    var twentyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 25)
      const newNum = filteredDataCount.length;
      twentyFiveData.push([categoryName, newNum])
    }

    var fiftyData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 50)
      const newNum = filteredDataCount.length;
      fiftyData.push([categoryName, newNum])
    }

    var seventyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 75)
      const newNum = filteredDataCount.length;
      seventyFiveData.push([categoryName, newNum])
    }

    var hundredData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 100)
      const newNum = filteredDataCount.length;
      hundredData.push([categoryName, newNum])
    }

    const results = [{
      label: '0%',
      data: zeroData
    },
    {
      label: '25%',
      data: twentyFiveData
    },
    {
      label: '50%',
      data: fiftyData
    },
    {
      label: '75%',
      data: seventyFiveData
    },
    {
      label: '100%',
      data: hundredData
    }, []];
     

    this.setState({ progressByCategoryData: results, showProgressByCatChart: true });
  }

  downloadIdeaData() {
    const modifiedData = this.state.ideas.map((idea) => {
      const newData = {
        "Idea Originator": idea.get("proponentName"),
        "Idea Number": idea.get("num"),
        "Idea Title": idea.get("title"),
        "Description": idea.get("description"),
        "Idea Category": idea.get("category"),
        "Idea Type": idea.get("ideaType"),
        "Department": idea.get("department"),
        "Submitted": idea.get("date"),
        "Idea Stage": idea.get("status"),
        "Implementation Owner": idea.get("responsibleName"),
        "Implementation Status (Progress %)": idea.get("progress")[0],
      }

      return (newData)
    })
     
    this.setState({ ideaDataCsv: modifiedData })
  }

  setupEarningsByCategoryData() {
    const { ideas, categories } = this.state;
     
    var zeroData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      var earnings = 0
      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
        earnings = earnings + filteredDataCount[index].get("expectedReturn")
      }

      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];

     
    this.setState({ returnsByCategoryData: results, showChart: true });
  }

  setupEarningsBySiteData() {
    const {otherIdeaResults1, otherIdeaResults2, otherIdeaResults3, otherIdeaResults4 } = this.state;

    const correctField = 'expectedReturn'
    
    var expectedEarningsSite1 = 0
    if (otherIdeaResults1) {
      for (var i in otherIdeaResults1) {
        expectedEarningsSite1 = expectedEarningsSite1 + otherIdeaResults1[i].get(correctField)
      }
    }

    var expectedEarningsSite2 = 0
    if (otherIdeaResults2) {
      for (var i in otherIdeaResults2) {
        expectedEarningsSite2 = expectedEarningsSite2 + otherIdeaResults2[i].get(correctField)
      }
    }

    var expectedEarningsSite3 = 0
    if (otherIdeaResults3) {
      for (var i in otherIdeaResults3) {
        expectedEarningsSite3 = expectedEarningsSite3 + otherIdeaResults3[i].get(correctField)
      }
    }

    var expectedEarningsSite4 = 0
    if (otherIdeaResults4) {
      for (var i in otherIdeaResults4) {
        expectedEarningsSite4 = expectedEarningsSite4 + otherIdeaResults4[i].get(correctField)
      }
    }

    return [expectedEarningsSite1, expectedEarningsSite2, expectedEarningsSite3, expectedEarningsSite4]

  }

  // Setups actual return by site
  setupActualEarningsBySiteData() {
    const { verifications, otherVerifications, verification1, verification2, verification3, verification4 } = this.state;
    
    var actualEarningsSite1 = 0
    if (verification1) {
      for (var i in verification1) {
        actualEarningsSite1 = actualEarningsSite1 + verification1[i].get("money")
      }
    }

    var actualEarningsSite2 = 0
    if (verification2) {
      for (var i in verification2) {
        actualEarningsSite2 = actualEarningsSite2 + verification2[i].get("money")
      }
    }

    var actualEarningsSite3 = 0
    if (verification3) {
      for (var i in verification3) {
        actualEarningsSite3 = actualEarningsSite2 + verification3[i].get("money")
      }
    }

    var actualEarningsSite4 = 0
    if (verification4) {
      for (var i in verification4) {
        actualEarningsSite4 = actualEarningsSite4 + verification4[i].get("money")
      }
    }

    return [actualEarningsSite1, actualEarningsSite2, actualEarningsSite3, actualEarningsSite4]
  }

  setupActualEarningsByCategoryData(verifications) {
    const { ideas, categories, } = this.state;

    var zeroData = [];
    // var completedData = [];
     

    for (var i in categories) {
      const categoryName = categories[i].get("itemName");

      const filteredDataCount = verifications.filter(verification => verification.get("category") == categoryName)
       
       

      var earnings = 0

      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
        //  
        earnings = earnings + filteredDataCount[index].get("money")
      }

       
      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];

     
    this.setState({ actualReturnsByCategoryData: results, showChartActual: true });
  }

  getOfficialNums() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;

    var officialNums = {}

    // Check if ideas and other ideas loaded


    if (selectedOption == 'All Sites') {

      officialNums.numCompleted = String(parseInt(numCompleted) + parseInt(numCompletedOther))
      officialNums.numInProgress = String(parseInt(numInProgress) + parseInt(numInProgressOther))
      officialNums.numPendingEval = String(parseInt(numPendingEval) + parseInt(numPendingEvalOther))
      officialNums.numSubmitted = String(parseInt(numSubmitted) + parseInt(numSubmittedOther))

    } else if (selectedOption == 'Site 1') {
      officialNums.numCompleted = parseInt(numCompleted)
      officialNums.numInProgress = parseInt(numInProgress)
      officialNums.numPendingEval = parseInt(numPendingEval)
      officialNums.numSubmitted = parseInt(numSubmitted)
    } else if (selectedOption == 'Site 2') {
      officialNums.numCompleted = parseInt(numCompletedOther)
      officialNums.numInProgress = parseInt(numInProgressOther)
      officialNums.numPendingEval = parseInt(numPendingEvalOther)
      officialNums.numSubmitted = parseInt(numSubmittedOther)
    }

    return officialNums

  }

  getOfficialNumsNew() {
    const { 
      selectedOption, 
      numCompletedOther1,
      numCompletedOther2,
      numCompletedOther3,
      numCompletedOther4,
      numInProgressOther1,
      numInProgressOther2,
      numInProgressOther3,
      numInProgressOther4,
      numPendingEvalOther1,
      numPendingEvalOther2,
      numPendingEvalOther3,
      numPendingEvalOther4,
      numSubmittedOther1,
      numSubmittedOther2,
      numSubmittedOther3,
      numSubmittedOther4,
    } = this.state;

    var officialNums = {}

    // Check if ideas and other ideas loaded


    if (selectedOption == 'All Sites') {
      officialNums.numCompleted = String(parseInt(numCompletedOther1) + parseInt(numCompletedOther2) + parseInt(numCompletedOther3) + parseInt(numCompletedOther4))
      officialNums.numInProgress = String(parseInt(numInProgressOther1) + parseInt(numInProgressOther2) + parseInt(numInProgressOther3) + parseInt(numInProgressOther4))
      officialNums.numPendingEval = String(parseInt(numPendingEvalOther1) + parseInt(numPendingEvalOther2) + parseInt(numPendingEvalOther3) + parseInt(numPendingEvalOther4))
      officialNums.numSubmitted = String(parseInt(numSubmittedOther1) + parseInt(numSubmittedOther2) + parseInt(numSubmittedOther3) + parseInt(numSubmittedOther4))
    } else if (selectedOption == 'Site 1') {
      officialNums.numCompleted = parseInt(numCompletedOther1)
      officialNums.numInProgress = parseInt(numInProgressOther1)
      officialNums.numPendingEval = parseInt(numPendingEvalOther1)
      officialNums.numSubmitted = parseInt(numSubmittedOther1)
    } else if (selectedOption == 'Site 2') {
      officialNums.numCompleted = parseInt(numCompletedOther2)
      officialNums.numInProgress = parseInt(numInProgressOther2)
      officialNums.numPendingEval = parseInt(numPendingEvalOther2)
      officialNums.numSubmitted = parseInt(numSubmittedOther2)
    } else if (selectedOption == 'Site 3') {
      officialNums.numCompleted = parseInt(numCompletedOther3)
      officialNums.numInProgress = parseInt(numInProgressOther3)
      officialNums.numPendingEval = parseInt(numPendingEvalOther3)
      officialNums.numSubmitted = parseInt(numSubmittedOther3)
    } else if (selectedOption == 'Site 4') {
      officialNums.numCompleted = parseInt(numCompletedOther4)
      officialNums.numInProgress = parseInt(numInProgressOther4)
      officialNums.numPendingEval = parseInt(numPendingEvalOther4)
      officialNums.numSubmitted = parseInt(numSubmittedOther4)
    }

    return officialNums

  }

  getDemoData() {
    const { 
      selectedOption, 
      ideas, 
      otherIdeaResults,
    } = this.state;

    const demoData = {
      series: [
        {
          name: 'Ideas per Site',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        xaxis: {
          categories: ['Site 1', 'Site 2'],
        },
        yaxis: {
          title: {
            text: 'Number of Ideas',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: ideas.length,
        },
        {
          x: 'Site 2',
          y: otherIdeaResults.length,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: ideas.length,
        },
      ]

    } else if (selectedOption == 'Site 2') {
      
      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: otherIdeaResults.length,
        },
      ]

    }

    return demoData
  }

  getDemoDataNew() {
    const { 
      selectedOption, 
      otherIdeaResults1,
      otherIdeaResults2,
      otherIdeaResults3,
      otherIdeaResults4
    } = this.state;
   
    const demoData = {
      series: [
        {
          name: 'Ideas per Site',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        xaxis: {
          categories: ['SFO', 'Amgen', 'BOS'],
        },
        yaxis: {
          title: {
            text: 'Number of Ideas',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: otherIdeaResults1.length,
        },
        {
          x: 'Site 2',
          y: otherIdeaResults2.length,
        },
        {
          x: 'Site 3',
          y: otherIdeaResults3.length,
        },
        {
          x: 'Site 4',
          y: otherIdeaResults4.length,
        },

      ]

    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: otherIdeaResults1.length,
        },
      ]

    } else if (selectedOption == 'Site 2') {
      
      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: otherIdeaResults2.length,
        },
      ]

    } else if (selectedOption == 'Site 3') {
      
      demoData.series[0].data = [
        {
          x: 'Site 3',
          y: otherIdeaResults3.length,
        },
      ]

    } else if (selectedOption == 'Site 4') {
      
      demoData.series[0].data = [
        {
          x: 'Site 4',
          y: otherIdeaResults4.length,
        },
      ]

    }

    return demoData
  }

  getCorrectIdeasByType() {
    const { 
      selectedOption, 
      ideas, 
      otherIdeaResults } = this.state;


    const innovationIdeas = ideas.filter(idea => idea.get("ideaType") == "innovacion").length
    const problemSolvingIdeas = ideas.filter(idea => idea.get("ideaType") == "problema").length
    const continuousImprovementIdeas = ideas.filter(idea => idea.get("ideaType") == "mejoras").length

    const innovationIdeasOther = otherIdeaResults.filter(idea => idea.get("ideaType") == "innovacion").length
    const problemSolvingIdeasOther = otherIdeaResults.filter(idea => idea.get("ideaType") == "problema").length
    const continuousImprovementIdeasOther = otherIdeaResults.filter(idea => idea.get("ideaType") == "mejoras").length


    var data = ''

    if (selectedOption == 'All Sites') {
      data = [
        { label: 'Innovation', value: innovationIdeas + innovationIdeasOther, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeas + problemSolvingIdeasOther, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeas + continuousImprovementIdeasOther, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 1') {
      data = [
        { label: 'Innovation', value: innovationIdeas, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeas, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeas, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 2') {
      data = [
        { label: 'Innovation', value: innovationIdeasOther, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeasOther, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeasOther, color: '#ed7333' },
      ];
    }
    
    return data;
    

  }

  getCorrectIdeasByTypeNew() {
    const { 
      selectedOption, 
      otherIdeaResults1,
      otherIdeaResults2,
      otherIdeaResults3,
      otherIdeaResults4,
    } = this.state;


    const innovationIdeas = otherIdeaResults1.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeas = otherIdeaResults1.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeas = otherIdeaResults1.filter(idea => idea.get("ideaType") === "mejoras").length

    const innovationIdeasOther = otherIdeaResults2.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeasOther = otherIdeaResults2.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeasOther = otherIdeaResults2.filter(idea => idea.get("ideaType") === "mejoras").length

    const innovationIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "mejoras").length
    
    const innovationIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("ideaType") === "innovacion").length
    const problemSolvingIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("ideaType") === "problema").length
    const continuousImprovementIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("ideaType") === "mejoras").length

    var data = ''

    if (selectedOption == 'All Sites') {
      data = [
        { label: 'Innovation', value: innovationIdeas + innovationIdeasOther + innovationIdeasOther3 + innovationIdeasOther4, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeas + problemSolvingIdeasOther + problemSolvingIdeasOther3 + problemSolvingIdeasOther4, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeas + continuousImprovementIdeasOther + continuousImprovementIdeasOther3 + continuousImprovementIdeasOther4, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 1') {
      data = [
        { label: 'Innovation', value: innovationIdeas, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeas, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeas, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 2') {
      data = [
        { label: 'Innovation', value: innovationIdeasOther, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeasOther, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeasOther, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 3') {
      data = [
        { label: 'Innovation', value: innovationIdeasOther3, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeasOther3, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeasOther3, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 4') {
      data = [
        { label: 'Innovation', value: innovationIdeasOther4, color: '#3f8cf4' },
        { label: 'Problem Solving', value: problemSolvingIdeasOther4, color: '#2961a7' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeasOther4, color: '#ed7333' },
      ];
    }
    this.setState({ideasByTypeForPie: data})
  }

  getCorrectIdeasByTypeNewBar() {
    const { 
      otherIdeaResults1,
      otherIdeaResults2,
      otherIdeaResults3,
      selectedOption,
    } = this.state;
  
    const innovationIdeas1 = otherIdeaResults1.filter(idea => idea.get("ideaType") === "innovacion").length;
    const problemSolvingIdeas1 = otherIdeaResults1.filter(idea => idea.get("ideaType") === "problema").length;
    const continuousImprovementIdeas1 = otherIdeaResults1.filter(idea => idea.get("ideaType") === "mejoras").length;
  
    const innovationIdeas2 = otherIdeaResults2.filter(idea => idea.get("ideaType") === "innovacion").length;
    const problemSolvingIdeas2 = otherIdeaResults2.filter(idea => idea.get("ideaType") === "problema").length;
    const continuousImprovementIdeas2 = otherIdeaResults2.filter(idea => idea.get("ideaType") === "mejoras").length;
  
    const innovationIdeas3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "innovacion").length;
    const problemSolvingIdeas3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "problema").length;
    const continuousImprovementIdeas3 = otherIdeaResults3.filter(idea => idea.get("ideaType") === "mejoras").length;
  
    const data = [];
  
    if (selectedOption === 'All Sites') {
      data.push(
        { x: 'Innovation', y: innovationIdeas1 + innovationIdeas2 + innovationIdeas3 },
        { x: 'Problem Solving', y: problemSolvingIdeas1 + problemSolvingIdeas2 + problemSolvingIdeas3 },
        { x: 'Continuous Improvement', y: continuousImprovementIdeas1 + continuousImprovementIdeas2 + continuousImprovementIdeas3 }
      );
    } else if (selectedOption === 'Site 1') {
      data.push(
        { x: 'Innovation', y: innovationIdeas1 },
        { x: 'Problem Solving', y: problemSolvingIdeas1 },
        { x: 'Continuous Improvement', y: continuousImprovementIdeas1 }
      );
    } else if (selectedOption === 'Site 2') {
      data.push(
        { x: 'Innovation', y: innovationIdeas2 },
        { x: 'Problem Solving', y: problemSolvingIdeas2 },
        { x: 'Continuous Improvement', y: continuousImprovementIdeas2 }
      );
    } else if (selectedOption === 'Site 3') {
      data.push(
        { x: 'Innovation', y: innovationIdeas3 },
        { x: 'Problem Solving', y: problemSolvingIdeas3 },
        { x: 'Continuous Improvement', y: continuousImprovementIdeas3 }
      );
    }
    
    return data;
  }
  
  

  getCorrectIdeasByStatus() {
    const { selectedOption, ideas, otherIdeaResults } = this.state;


   

    const completeIdeas = ideas.filter(idea => idea.get("progress")[0] === 100).length
    const inProgressIdeas = ideas.filter(idea =>  idea.get("progress")[0] >= 25).length
    const pendingIdeas = ideas.filter(idea =>  idea.get("progress")[0] === 0).length

    const completeIdeasOther = otherIdeaResults.filter(idea => idea.get("progress")[0] === 100).length
    const inProgressIdeasOther = otherIdeaResults.filter(idea => idea.get("progress")[0] === 25).length
    const pendingIdeasOther = otherIdeaResults.filter(idea => idea.get("progress")[0] === 0).length


    var data = ''

    if (selectedOption == 'All Sites') {
      data = [
        { label: 'Completed', value: completeIdeas + completeIdeasOther, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeas + inProgressIdeasOther, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeas + pendingIdeasOther, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 1') {
      data = [
        { label: 'Completed', value: completeIdeas, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeas, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeas, color: '#ed7333' },
      ];
    } else if (selectedOption == 'Site 2') {
      data = [
        { label: 'Completed', value: completeIdeasOther, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeasOther, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeasOther, color: '#ed7333' },
      ];
    }
    
    return data;
    

  }

  getCorrectIdeasByStatusNew() {
    const { 
      selectedOption, 
      otherIdeaResults1,
      otherIdeaResults2,
      otherIdeaResults3,
      otherIdeaResults4,
    } = this.state;


    const completeIdeas = otherIdeaResults1.filter(idea => idea.get("progress")[0] == 100).length
    const inProgressIdeas = otherIdeaResults1.filter(idea =>  idea.get("progress")[0] >= 25).length
    const pendingIdeas = otherIdeaResults1.filter(idea =>  idea.get("progress")[0] == 0).length

    const completeIdeasOther = otherIdeaResults2.filter(idea => idea.get("progress")[0] == 100).length
    const inProgressIdeasOther = otherIdeaResults2.filter(idea => idea.get("progress")[0] == 25).length
    const pendingIdeasOther = otherIdeaResults2.filter(idea => idea.get("progress")[0] == 0).length

    const completeIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("progress")[0] == 100).length
    const inProgressIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("progress")[0] == 25).length
    const pendingIdeasOther3 = otherIdeaResults3.filter(idea => idea.get("progress")[0] == 0).length

    const completeIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("progress")[0] == 100).length
    const inProgressIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("progress")[0] == 25).length
    const pendingIdeasOther4 = otherIdeaResults4.filter(idea => idea.get("progress")[0] == 0).length

    var data = ''

    if (selectedOption === 'All Sites') {
      data = [
        { label: 'Completed', value: completeIdeas + completeIdeasOther, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeas + inProgressIdeasOther, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeas + pendingIdeasOther, color: '#ed7333' },
      ];
    } else if (selectedOption === 'Site 1') {
      data = [
        { label: 'Completed', value: completeIdeas, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeas, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeas, color: '#ed7333' },
      ];
    } else if (selectedOption === 'Site 2') {
      data = [
        { label: 'Completed', value: completeIdeasOther, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeasOther, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeasOther, color: '#ed7333' },
      ];
    } else if (selectedOption === 'Site 3') {
      data = [
        { label: 'Completed', value: completeIdeasOther3, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeasOther3, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeasOther3, color: '#ed7333' },
      ];
    } else if (selectedOption === 'Site 4') {
      data = [
        { label: 'Completed', value: completeIdeasOther4, color: '#3f8cf4' },
        { label: 'In Progress', value: inProgressIdeasOther4, color: '#2961a7' },
        { label: 'Pending', value: pendingIdeasOther4, color: '#ed7333' },
      ];
    }
    
    return data;
    

  }

  

  getEarningsBySiteData(type) {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;
    const expectedEarningsPerSite = this.setupEarningsBySiteData(type)
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]
    const expectedEarningsAllSites = expectedEarningSite1 + expectedEarningsSite2

    const demoData = {
      series: [
        {
          name: 'Earnings per Site',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Site',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: 'Expected Earnings',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };
    

    if (selectedOption == 'All Sites') {


      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
      ]

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    }

    return demoData

  }

  getEarningsBySiteDataNew(type) {
    const { selectedOption } = this.state;
    const expectedEarningsPerSite = this.setupEarningsBySiteData(type)
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]
    const expectedEarningsSite3 = expectedEarningsPerSite[2]
    const expectedEarningsSite4 = expectedEarningsPerSite[3]

    const demoData = {
      series: [
        {
          name: 'Earnings per Site',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Site',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: 'Expected Earnings',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };
    

    if (selectedOption == 'All Sites') {


      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        },
        {
          x: 'Site 3',
          y: expectedEarningsSite3,
        },
        {
          x: 'Site 4',
          y: expectedEarningsSite4,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
      ]

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    } else if (selectedOption == 'Site 3') {

      demoData.series[0].data = [
        {
          x: 'Site 3',
          y: expectedEarningsSite3,
        }
      ]

    } else if (selectedOption == 'Site 4') {

      demoData.series[0].data = [
        {
          x: 'Site 4',
          y: expectedEarningsSite4,
        }
      ]

    }

    return demoData

  }

  getActualEarningsBySiteData() {
    const { selectedOption } = this.state;
    const expectedEarningsPerSite = this.setupActualEarningsBySiteData()
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]

    var demoData = {
      series: [
        {
          name: 'Earnings per Site',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Site',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: 'Expected Earnings',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData = [
        demoData.series[0].data = [
          {
            x: 'Site 1',
            y: expectedEarningSite1,
          },
        ]
      ]

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    }

    return demoData

  }

  getActualEarningsBySiteDataNew() {
    const { selectedOption } = this.state;
    const expectedEarningsPerSite = this.setupActualEarningsBySiteData()
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]
    const expectedEarningsSite3 = expectedEarningsPerSite[2]
    const expectedEarningsSite4 = expectedEarningsPerSite[3]

    var demoData = {
      series: [
        {
          name: 'Earnings per Site',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Site',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: 'Expected Earnings',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        },
        {
          x: 'Site 3',
          y: expectedEarningsSite3,
        },
        {
          x: 'Site 4',
          y: expectedEarningsSite4,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData = [
        demoData.series[0].data = [
          {
            x: 'Site 1',
            y: expectedEarningSite1,
          },
        ]
      ]

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    } else if (selectedOption == 'Site 3') {

      demoData.series[0].data = [
        {
          x: 'Site 3',
          y: expectedEarningsSite3,
        }
      ]

    } else if (selectedOption == 'Site 4') {

      demoData.series[0].data = [
        {
          x: 'Site 4',
          y: expectedEarningsSite4,
        }
      ]

    }

    return demoData

  }

  getPercentageIdeasEmployeesBySite() {
    const { numEmployees, numEmployeesOther, selectedOption, ideas, otherIdeaResults } = this.state;
    const numEmployeesWithIdeasSite1 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(ideas)
    const numEmployeesWithIdeasSite2 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(otherIdeaResults)

    const demoData = {
      series: [
        {
          name: 'Employee Participation',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        xaxis: {
          categories: ['Site 1', 'Site 2'],
        },
        yaxis: {
          title: {
            text: 'Employee Participation',
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          max: 100,
          labels: {
            formatter: function (value) {
              return value + '%';
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    const percentageSite1 = (numEmployeesWithIdeasSite1/numEmployees) * 100
    const percentageSite2 = (numEmployeesWithIdeasSite2/numEmployeesOther) * 100

    // alert(numEmployeesOther)

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: percentageSite1,
        },
        {
          x: 'Site 2',
          y: percentageSite2,
        }
      ]


    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: percentageSite1,
        },
      ]

    } else if (selectedOption == 'Site 2') {
      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: percentageSite2,
        },
      ]

    }

    return demoData

  }

  getPercentageIdeasEmployeesBySiteNew() {
    const {users1, users2, users3, users4, otherIdeaResults1, otherIdeaResults2, otherIdeaResults3, otherIdeaResults4, selectedOption } = this.state;

    const {t} = this.props;

    const numEmployeesWithIdeasSite1 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(otherIdeaResults1)
    const numEmployeesWithIdeasSite2 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(otherIdeaResults2)
    const numEmployeesWithIdeasSite3 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(otherIdeaResults3)
    const numEmployeesWithIdeasSite4 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(otherIdeaResults4)
    // alert(numEmployees)

    const demoData = {
      series: [
        {
          name: 'Employee Participation',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        // xaxis: {
        //   categories: ['Site 1', 'Site 2'],
        // },
        yaxis: {
          title: {
            text: t('CHART_EMPLOYEE_PARTICIPATION'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          max: 100,
          labels: {
            formatter: function (value) {
              return value + '%';
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return Math.trunc(val) + '%'
            }
          }
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    const percentageSite1 = (numEmployeesWithIdeasSite1/users1) * 100
    const percentageSite2 = (numEmployeesWithIdeasSite2/users2) * 100
    const percentageSite3 = (numEmployeesWithIdeasSite3/users3) * 100
    const percentageSite4 = (numEmployeesWithIdeasSite4/users4) * 100

    // alert(numEmployeesOther)

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'SFO',
          y: percentageSite1,
        },
        {
          x: 'Amgen',
          y: percentageSite2,
        },
        {
          x: 'BOS',
          y: percentageSite3,
        },
        {
          x: 'Janssen',
          y: percentageSite4,
        }
      ]


    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'SFO',
          y: percentageSite1,
        },
      ]

    } else if (selectedOption == 'Site 2') {
      demoData.series[0].data = [
        {
          x: 'Amgen',
          y: percentageSite2,
        },
      ]

    } else if (selectedOption == 'Site 3') {
      demoData.series[0].data = [
        {
          x: 'BOS',
          y: percentageSite3,
        },
      ]

    } else if (selectedOption == 'Site 4') {
      demoData.series[0].data = [
        {
          x: 'Janssen',
          y: percentageSite4,
        },
      ]

    }

    return demoData

  }

  getIdeasEmployeesBySiteNew() {
    const {users1, users2, users3, users4, otherIdeaResults1, otherIdeaResults2, otherIdeaResults3, otherIdeaResults4, selectedOption } = this.state;
    const { t } = this.props;
    
    const demoData = {
      series: [
        {
          name: 'Employee Participation',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        // xaxis: {
        //   categories: ['SFO', 'Amgen', 'BOS'],
        // },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        yaxis: {
          title: {
            text: t('AVG_CONTRIBUTION_EMPLOYEE'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          min: 0,
          tickAmount: 2,
          max: 2,
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val.toFixed(2);
            }
          }
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };
    if (selectedOption == 'All Sites') {
      
      demoData.series[0].data = [
        {
          x: 'SFO',
          y: otherIdeaResults1.length / users1,
        },
        {
          x: 'Amgen',
          y: otherIdeaResults2.length / users2,
        },
        {
          x: 'BOS',
          y: otherIdeaResults3.length / users3,
        },
        {
          x: 'Janssen',
          y: otherIdeaResults4.length / users4,
        }
      ]


    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'SFO',
          y: otherIdeaResults1.length / users1,
        },
      ]

    } else if (selectedOption == 'Site 2') {
      demoData.series[0].data = [
        {
          x: 'Amgen',
          y:otherIdeaResults2.length / users2,
        },
      ]

    } else if (selectedOption == 'Site 3') {
      demoData.series[0].data = [
        {
          x: 'BOS',
          y: otherIdeaResults3.length / users3,
        },
      ]

    } else if (selectedOption == 'Site 4') {
      demoData.series[0].data = [
        {
          x: 'Janssen',
          y: otherIdeaResults4.length / users4,
        },
      ]

    }

    return demoData

  }

  getIdeasByStatusDataNew() {
    const {
      selectedOption,
      numEvaluatedOther1,
      numEvaluatedOthe2,
      numEvaluatedOthe3,
      numEvaluatedOthe4,
      numInProgressOther1,
      numInProgressOther2,
      numInProgressOther3,
      numInProgressOther4,
      numCompletedOther1,
      numCompletedOther2,
      numCompletedOther3,
      numCompletedOther4,
      numVerifiedOther1,
      numVerifiedOther2,
      numVerifiedOther3,
      numVerifiedOther4,
      numRROther1,
      numRROther2,
      numRROther3,
      numRROther4,
      numSubmittedOther1,
      numSubmittedOther2,
      numSubmittedOther3,
      numSubmittedOther4
    } = this.state;
  
    const { t } = this.props;
    var demoData = {
      series: [
        {
          name: 'Ideas by Status',
          data: [],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
         
        xaxis: {
          categories: ['Submitted', 'Evaluated', 'In Progress', 'Completed', 'Verified', 'R&R'],
          
        },
        yaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        fill: {
          opacity: 1,
          colors: [
            '#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c' ,'#9dd64c' 
          ]
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c', '#9dd64c'],
      },
    };
    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: toInteger(numSubmittedOther1) + toInteger(numSubmittedOther2) + toInteger(numSubmittedOther3) + toInteger(numSubmittedOther4),
        
        },
        {
          x: 'Evaluated',
          y: toInteger(numEvaluatedOther1) + toInteger(numEvaluatedOthe2) + toInteger(numEvaluatedOthe3) + toInteger(numEvaluatedOthe4),
          
        },
        {
          x: 'In Progress',
          y: toInteger(numInProgressOther1) + toInteger(numInProgressOther2) + toInteger(numInProgressOther3) + toInteger(numInProgressOther4),
          
        },
        {
          x: 'Completed',
          y: toInteger(numCompletedOther1) + toInteger(numCompletedOther2) + toInteger(numCompletedOther3) + toInteger(numCompletedOther4),
        },
        {
          x: 'Verified',
          y: toInteger(numVerifiedOther1) + toInteger(numVerifiedOther2) + toInteger(numVerifiedOther3) + toInteger(numVerifiedOther4),
        },
        {
          x: 'R&R',
          y: toInteger(numRROther1) + toInteger(numRROther2) + toInteger(numRROther3) + toInteger(numRROther4),
          
        },
      ]

    } else if (selectedOption == 'Site 1') {
     
   
        demoData.series[0].data = [
          {
            x: 'Submitted',
            y: toInteger(numSubmittedOther1),
          
          },
          {
            x: 'Evaluated',
            y: toInteger(numEvaluatedOther1) ,
            
          },
          {
            x: 'In Progress',
            y: toInteger(numInProgressOther1),
            
          },
          {
            x: 'Completed',
            y: toInteger(numCompletedOther1),
           
          },
          {
            x: 'Verified',
            y: toInteger(numVerifiedOther1),
           
          },
          {
            x: 'R&R',
            y: toInteger(numRROther1),
            
          },
        ]
      

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: toInteger(numSubmittedOther2),
        
        },
        {
          x: 'Evaluated',
          y: toInteger(numEvaluatedOthe2),
          
        },
        {
          x: 'In Progress',
          y: toInteger(numInProgressOther2),
          
        },
        {
          x: 'Completed',
          y: toInteger(numCompletedOther2),
         
        },
        {
          x: 'Verified',
          y: toInteger(numVerifiedOther2),
         
        },
        {
          x: 'R&R',
          y: toInteger(numRROther2),
          
        },
      ]

    } else if ( selectedOption == 'Site 3') {
      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: toInteger(numSubmittedOther3),
        
        },
        {
          x: 'Evaluated',
          y: toInteger(numEvaluatedOthe3),
          
        },
        {
          x: 'In Progress',
          y: toInteger(numInProgressOther3),
          
        },
        {
          x: 'Completed',
          y: toInteger(numCompletedOther3),
         
        },
        {
          x: 'Verified',
          y: toInteger(numVerifiedOther3),
         
        },
        {
          x: 'R&R',
          y: toInteger(numRROther3),
          
        },
      ]
    } else if ( selectedOption == 'Site 4') {
      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: toInteger(numSubmittedOther4),
        
        },
        {
          x: 'Evaluated',
          y: toInteger(numEvaluatedOthe4),
          
        },
        {
          x: 'In Progress',
          y: toInteger(numInProgressOther4),
          
        },
        
        {
          x: 'Completed',
          y: toInteger(numCompletedOther4),
         
        },
        {
          x: 'Verified',
          y: toInteger(numVerifiedOther4),
         
        },
        {
          x: 'R&R',
          y: toInteger(numRROther4),
          
        },
      ]
    }
  
    return demoData;
  }
  

  getIdeasByStatusPieData() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults, numVerified, numVerifiedOther } = this.state;
  
    const seriesData = [
      toInteger(numPendingEval) + toInteger(numPendingEvalOther),
      toInteger(numCompleted) + toInteger(numCompletedOther),
      toInteger(numInProgress) + toInteger(numInProgressOther),
      toInteger(numVerified) + toInteger(numVerifiedOther),
      0
    ];
  
    const chartData = [
      { x: 'Submitted', y: seriesData[0] },
      { x: 'Evaluated', y: seriesData[1] },
      { x: 'In Progress', y: seriesData[2] },
      { x: 'Verified', y: seriesData[3] },
      { x: 'R&R', y: seriesData[4] }
    ];
  
    const chartOptions = {
      chart: {
        type: 'pie',
        height: 400
      },
      labels: ['Submitted', 'Evaluated', 'In Progress', 'Verified', 'R&R'],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(0) + ' ideas'
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val.toFixed(0)
          }
        }
      },
      colors: ['#008FFB', '#00E396', '#CED4DC', '#FEB019', '#FF4560']
    };
  
    return { series: seriesData, data: chartData, chartOptions: chartOptions };
  }
  
  setupDonutChartEarnings(type) {
    const expectedEarningsPerSite = type == 'expected'?this.setupEarningsBySiteData(type):this.setupActualEarningsBySiteData()
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]
    const expectedEarningsSite3 = expectedEarningsPerSite[2]
    const series = [expectedEarningSite1, expectedEarningsSite2, expectedEarningsSite3];
    const total = series.reduce((a, b) => a + b, 0);

    const options = {
    chart: {
      type: 'donut',
    },
    labels: ['Site 1', 'Site 2', 'Site 3'],
    colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return  ''
      },
      total: {
        show: true,
        label: 'Total',
        formatter: function () {
          return total;
        }
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: undefined,
              offsetY: -10
            },
            value: {
              show: true,
              fontSize: '72px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val
              }
            },
            total: {
              show: true,
              showAlways: false,
              label: '',
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              }
            }
          }
        }
      }
    }
  };
  return [series, options]
  }
  

  render() {
    if (process.env.REACT_APP_IS_ENTERPRISE !== "1" && process.env.NODE_ENV === 'production')  return <></>;
    const { t } = this.props;
    const { sites, selectedSites, dataSites } = this.state;
    const officialNums = this.getOfficialNumsNew()
    const ideasByType = this.state.ideasByTypeForPie;
    const ideasByCategoryPie = this.state.ideasByCategoryForPie;
    const ideasPerCategory = this.setupNumberOfIdeasByCategoryDataNew()
    const ideasByTypeBar = this.setupNumberOfIdeasByTypeDataNew()
  

    const officialNumSubmitted = officialNums.numSubmitted
    const officialNumCompleted = officialNums.numCompleted
    const officialNumInProgress = officialNums.numInProgress
    const officialNumPendingEval = officialNums.numPendingEval
    const ideasByStatusData = this.getIdeasByStatusDataNew()
    const ideasPerEmployeeData = this.getPercentageIdeasEmployeesBySiteNew()
    const ideasPerEmployeeLast = this.getIdeasEmployeesBySiteNew()
  

    var smallStats = [
      {
        label: t('SUBMITTED_IDEAS'),
        value: officialNumSubmitted,
        percentage: "12.4%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.primary.toRGBA(0.1),
            borderColor: colors.primary.toRGBA(),
            data: [9, 3, 3, 9, 9]
          }
        ]
      },
      {
        label: t('COMPLETED_IDEAS'),
        value: officialNumCompleted,
        percentage: "7.21%",
        increase: false,
        chartLabels: [null, null, null, null, null],
        decrease: true,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.success.toRGBA(0.1),
            borderColor: colors.success.toRGBA(),
            data: [3.9, 4, 4, 9, 4]
          }
        ]
      },
      {
        label: t('PENDING_FOR_EVALUATION'),
        value: officialNumPendingEval,
        percentage: "3.71%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [6, 6, 9, 3, 3]
          }
        ]
      },
      {
        label: t('IDEAS_ON_PROGRESS'),
        value: officialNumInProgress,
        percentage: "3.71%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [6, 6, 9, 3, 3]
          }
        ]
      },
    ];

    const Option = (props) => {
      return (
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="ml-2">{props.label}</label>
        </components.Option>
      );
    };
    const isLoading = dataSites.length !== sites.length
    const currentUser = Parse.User.current();

    return (
      <Container fluid className="main-content-container px-4">
        <Row className="mt-4">
          {(currentUser.get('role') === 'murmuratto' || currentUser.get('allowDownloadReportEnterprise')) ? (<>
          <Col lg="3" sm="3">
           <Select
            options={sites}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            onChange={this.handleSiteChange}
            value={selectedSites}
          />
         
          </Col>
          <Col lg="2" sm="2">
            <Button onClick={this.handleDownload} disabled={(isLoading || ((selectedSites && !selectedSites.length) || !selectedSites) )}>{(isLoading ? 'Loading...' : 'Download Report')}</Button>
          </Col>

          <Col lg="3" sm="3"></Col>
          </>)
          : <Col lg="8" sm="8"></Col>}
    
          <Col xs="2" sm="2" >
            <FormSelect value={this.state.selectedOption} onChange={(selectedOption) => this.handleOptionChange(selectedOption)}>
              <option value="All Sites">All Sites</option>
              <option value="Site 1">SFO</option>
              <option value="Site 2">Amgen</option>
              <option value="Site 3">BOS</option>
              <option value="Site 4">Janssen</option>
            </FormSelect>
          </Col>
          <Col xs="2" sm="">

            <FormSelect value={this.state.selectedDateValue} onChange={(selectedDate) => this.handleDateChange(selectedDate)}>
              <option value="All Time">All Time</option>
              <option value="last_month">Last Month</option>
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
              <option value="365">1 Year</option>
            </FormSelect>
          </Col>
        </Row>

        <Row noGutters className="page-header py-4">
          <PageTitle title={t('Enterprise')} subtitle={t('REPORTS')} className="text-sm-left mb-3" />
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          {smallStats.map((stats, idx) => (
            <Col key={idx} md="6" lg="3" className="mb-4">
              <div style={{ display: 'flex', height: '100%' }}>
                <Card style={{ flex: 1 }}>
                  <CardBody style={{ padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ textAlign: 'center', marginTop: 32, marginBottom: 0, fontSize: 20, fontWeight: 500, paddingLeft: 30, paddingRight: 30 }}>
                      {stats.label}
                    </p>
                    <div style={{ textAlign: 'center', marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                      {this.state.dataIsLoading ? (
                        <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40} />
                      ) : (
                        <p style={{ fontSize: 50, fontWeight: 700, color: '#3f8cf4' }}>
                          {stats.value === 0 ? '0' : stats.value}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          ))}

          <Col lg="12" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                  <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                    {t('OPPORTUNITY_STATUS')}
                  </p>
                </div>
                  <BarChart data={ideasByStatusData}></BarChart>
              </CardBody>
            </Card>

          </Col>

          <Col lg="6" sm="12"  className="mb-4">
            <Card style={{minHeight: '550px'}}>
              <CardBody>
              <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                    <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                        {t('OPPORTUNITIES_BY_CATEGORY_PERCENTAGE')}
                    </p>
                    <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                        {t('PERCENTAGE')}
                    </p>
                </div>
                  <PieChart data={ideasByCategoryPie} id="enterprisePie1"></PieChart>
              </CardBody>
            </Card>
          </Col>

       {/* Earnings */}
       <Col lg="6" sm="12" className="mb-4">
        <Card style={{minHeight: '550px'}}>
            <CardBody>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                    <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                    {t('OPPORTUNITIES_BY_CATEGORY_PERCENTAGE')}
                    </p>
                    <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                        {t('QUANTITY')}
                    </p>
                </div>
              <BarChart data={ideasPerCategory}></BarChart>
            </CardBody>
            <CardFooter>
            
              </CardFooter>
          </Card>
        </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card style={{minHeight: '550px'}}>
              <CardBody>
              <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                    <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                      {t('OPPORTUNITIES_BY_TYPE_PERCENTAGE')}
                    </p>
                    <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                        { t('PERCENTAGE')}
                    </p>
                </div>
                  <PieChart data={ideasByType} id="enterprisePie2"></PieChart>
                

              </CardBody>
              <CardFooter>
                {/* <Button>{t('DOWNLOAD_DATA')} &rarr;</Button> */}
              </CardFooter>
            </Card>
            {/* <Chart data={data} axes={axes} /> */}
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card style={{minHeight: '550px'}}>
                <CardBody>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                    <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                      {t('OPPORTUNITIES_BY_TYPE_PERCENTAGE')}
                    </p>
                    <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                    { t('QUANTITY')}
                    </p>
                </div>
                <BarChart data={ideasByTypeBar}></BarChart>
                </CardBody>
                <CardFooter>
                
                </CardFooter>
            </Card>
            </Col>
       
        
      <Col lg="6" sm="12" className="mb-4">
       <Card style={{minHeight: '500px'}}>
      <CardBody>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
          <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
            {t('PARTICIPATION_BY_SITE')}
          </p>
          <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
            { t('PERCENTAGE')}
          </p>
        </div>
        
        {<BarChart data={ideasPerEmployeeData}></BarChart>}
      </CardBody>
      <CardFooter>
       
        </CardFooter>
    </Card>
        
      </Col>
      <Col lg="6" sm="12" className="mb-4">
       <Card style={{minHeight: '500px'}}>
      <CardBody>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
          <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
            {t('CONTRIBUTIONS_PER_EMPLOYEE')}
          </p>
          <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
            { t('QUANTITY')}
          </p>
        </div>
        {<BarChart data={ideasPerEmployeeLast}></BarChart>}
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
        
      </Col>

        </Row>

      </Container>
    )
  }
}

EnterpriseR1.propTypes = {
  smallStats: PropTypes.array
};

EnterpriseR1.defaultProps = {
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(EnterpriseR1);