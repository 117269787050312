import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  FormSelect
} from "shards-react";
import axios from 'axios';
import i18n from 'i18next';

import "./LocalStyles/CategorySelectStyle.css"

// Translation
import { withTranslation } from 'react-i18next';

import { fetchTypes } from "../../services/tableService";

// Defult icons
import { ReactComponent as ExtraImage } from '../../images/innovation.svg';
import { ReactComponent as HandImage } from "../../images/hand.svg"
import { ReactComponent as ChartImage } from "../../images/line-chart.svg"
import { ReactComponent as ShieldImage } from "../../images/shield.svg"
import { ReactComponent as TimeImage } from "../../images/time.svg"
import { ReactComponent as TeamImage } from "../../images/team.svg"
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg"

// Selected icons
import clockImageSelected from "../../images/stopwatch_blue.svg"
import handImageSelected from "../../images/hand_blue.svg"
import chartImageSelected from "../../images/line-chart_blue.svg"
import shieldImageSelected from "../../images/shield_blue.svg"

const selectedColor = '#FD902c';
const blueColor = '#3A7BBB';
const orangeColor = '#FD902c';
const greenColor = '#7FA86F';
const goldColor = '#DDB153';
const purpleColor = '#62466B';
const whiteSelected = 'white';

class CategorySelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: this.props.selectionValue ? this.props.selectionValue : '',
            allCats: [],
        }
        this.fetchCategoryData = this.fetchCategoryData.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    async fetchCategoryData() {
        try {
          const categories = await fetchTypes(11);
          this.setState({ allCats: categories });
        } catch (error) {
          console.error('Error setting category data:', error);
        }
      }

    changeSelectedValue(selectionValue, category) {
        this.setState({ selectionValue });
        this.props.setCategory(category.id);
        this.props.setSelectedValue(selectionValue);
    }

    getIcon(name, fillColor) {
        switch(name) {
            case 'Dollar Sign':
                return <DollarImage className="mx-auto d-block" style={{minWidth: 80, maxWidth: 80, fill: fillColor}} />;
            case 'Urgent':
                return <TimeImage className="mx-auto d-block" style={{minWidth: 80, maxWidth: 80, fill: fillColor}} />;
            case 'Productivity':
                return <ChartImage className="mx-auto d-block" style={{minWidth: 80, maxWidth: 80, fill: fillColor}} />;
            case 'Checkmark':
                return <ShieldImage className="mx-auto d-block" style={{minWidth: 80, maxWidth: 80, fill: fillColor}} />;
            default:
                return <ExtraImage className="mx-auto d-block" style={{minWidth: 80, maxWidth: 80, fill: fillColor}} />;
        }
    }

    getBgColor(index) {
        return this.state.selectionValue === index ? selectedColor : '';
    }

    render() {
        const { allCats } = this.state;
        const { t } = this.props;
        const storageLanguage = localStorage.getItem('language') != null ? localStorage.getItem('language') : 'en';

        return(
            <div className="container">
                <Row>
                    {allCats.map((category, index) => {
                        const description = t(category.transcription[storageLanguage] || category.title);
                        return (
                            <Col sm="12" lg="6" md="6" style={{backgroundColor: this.getBgColor(index), borderRadius: '20px'}} key={category.id}>
                                <div className="square" onClick={() => this.changeSelectedValue(index, category)}>
                                    {this.getIcon(category.icon, selectedColor)}
                                    <span><h4 className="text-center" style={{color: 'black'}}>{description}</h4></span>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }
}

export default withTranslation()(CategorySelect);
