import React from "react";
import { Col, Container, Row } from "shards-react";
import MoreInformationModal from "../components/administration/MoreInformationModal";
import PageTitle from "../components/common/PageTitle";


import { withTranslation } from "react-i18next";
import IdeaImplementFilterButton from "../components/common/IdeaImplementFilterButton";
import IdeasUnderImplementation from "../components/ecommerce/IdeasUnderImplementation";
import UpcomingDeadlinesEvents from "../components/ecommerce/UpcomingDeadlinesEvents";
import { getOpportunityById } from "../services/implementService";
import CompletedIdeaFormNew from "./CompletedIdeaFormNew";
import ImplementIdea from "./ImplementIdea";

class SearchIdeasToImplement extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      currIdea: '',
      canGoNext: false,
      canSubmit: false,
      canEdit: false,
      rfiOpen: false,
      idea: '',
      showA3: false,
      userRole: 'Implementation Owner',
      selectedFilter: 'View All'
    }

    this.openEvalForm = this.openEvalForm.bind(this);
  }

  componentDidMount() {
    
  }

  async openEvalForm(item) {
    const opportunity = await getOpportunityById(item.id)

    if (opportunity.status == "Response Needed") {
    //   alert(this.state.rfiOpen)
      this.setState({rfiOpen: true, idea: opportunity})
    } else {
      this.setState({
        currIdea: opportunity,
        open: true,
      });
    }
    
  }

  async getAndSendIdea(item) {
    const opportunityId = item.opportunity.id
    const opportunity = await getOpportunityById(opportunityId)

    this.setState({
      currIdea: opportunity,
      open: true,
      showA3: false
    })
    
    // var ItemClass = Parse.Object.extend("Idea");
    // var query = new Parse.Query(ItemClass);
    // query.equalTo("num", ideaNum);
    

    // query.find()
    // .then((results) => {
    //   const myIdea = results[0]
    //   this.setState({
    //     currIdea: myIdea,
    //     open: true,
    //     showA3: false
    //   });
    // }, (error) => {
    //     this.setState({
    //         data: []
    //     });
    // });
  }

  

  changeBtnStatus(status) {
    this.setState({
      canGoNext: status
    })
  }

  closeModal = () => {
    this.setState({rfiOpen: false})
  }

  updateCurrIdea = () => {
    const { currIdea } = this.state

    const currIdeaCopy = currIdea

    currIdeaCopy.set("progress", [75, 25])

    this.setState({currIdea: currIdeaCopy})
  }



  render() {
    const {open} = this.state;
    const {t} = this.props;
    return(
      !open?(
        <div>
      <Container fluid className="main-content-container px-4" style={{backgroundColor: 'white'}}>

        <MoreInformationModal isOpen={this.state.rfiOpen} closeModal={() => this.closeModal() } idea={this.state.idea} type="submitInfo"></MoreInformationModal>
        <Row noGutters className="page-header pt-4 pb-2">
          <PageTitle title={t("Your Ideas at Work")} subtitle={t("Workflow")} className="text-sm-left mb-3" />
        </Row>
        <Row noGutters className="page-header pt-2 mb-4">
          {t("Never lose track of the opportunities under your umbrella.")}
        </Row>
  

        <Row className="mb-4">
            {/* Page Header :: Title */}
            <Col md="12" lg="12" className="mt-4 mx-auto">
                <Row>
                    <Col md="12" lg="12">
                        <h4 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{t("You are viewing ideas as a")}:</h4>
                    </Col>
                </Row>
            </Col>
            <Col md="12" lg="12" className="mt-4 mx-auto">
                <Row>
                    <Col md="12" lg="12">
                        <IdeaImplementFilterButton changeFilter={(filter) => this.setState({selectedFilter: filter})}></IdeaImplementFilterButton>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row className="mb-4">
            {/* Page Header :: Title */}
            <Col md="12" lg="12" className="mt-4 mx-auto">
                <Row>
                    <Col md="12" lg="12">
                        <h4 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{t("Upcoming deadlines and events")}</h4>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row>
          {/* Latest Orders */}
          <Col lg="12" className="mt-2 mb-4 mx-auto">
            <UpcomingDeadlinesEvents onEvalBtnPress={(item) => this.getAndSendIdea(item)}/>
          </Col>
        </Row>

        <Row className="mb-4">
            {/* Page Header :: Title */}
            <Col md="12" lg="12" className="mt-4 mx-auto">
                <Row>
                    <Col md="12" lg="12">
                        <h4 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{t("Ideas under Implementation")}</h4>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row>
          <Col lg="12" className="mt-2 mb-4 mx-auto">
            <IdeasUnderImplementation filter={this.state.selectedFilter} onEvalBtnPress={(item) => this.openEvalForm(item)}/>
          </Col>
        </Row>

    
      </Container>
      </div>
    )
    :
    ( this.state.showA3? 
    <CompletedIdeaFormNew currIdea={this.state.currIdea} updateCurrIdea={() => this.updateCurrIdea()} closeModal={() => this.setState({open: false, showA3: false})} closeA3={() => this.setState({showA3: false})} stage="completed" ></CompletedIdeaFormNew>
    :
    <ImplementIdea currIdea={this.state.currIdea} updateCurrIdea={() => this.updateCurrIdea()} closeModal={() => this.setState({open: false, showA3: false})} showA3={(canShow) => this.setState({showA3: canShow})}></ImplementIdea>
    )
    )
  }

}

export default withTranslation()(SearchIdeasToImplement);
