import FuzzySearch from "fuzzy-search";
import moment from "moment";
import Parse from 'parse';
import React from "react";
import ReactTable from "react-table";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";

import { withTranslation } from "react-i18next";
import IdeaRecognitionView from "../components/common/IdeaRecognitionView";
import PageTitle from "../components/common/PageTitle";
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";

class RecognitionHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      tableData: [],
      showPreviousIcon: false,
    };

    this.searcher = null;

    this.getStatusClass = this.getStatusClass.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleItemConfirm = this.handleItemConfirm.bind(this);
    this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      showView: false,
    });
    this.fetchIdeas();

  }

  initSearcher() {
    this.searcher = new FuzzySearch(this.state.tableData, ["desc", "cat", "prop"], {
      caseSensitive: false
    });
  }

  // LOAD DATA
  fetchIdeas() {
    const className = "Recognition";
    const Idea = Parse.Object.extend(className);
    // const evalCriteria = await this.getEvalCriteria();
    //  
    var query = new Parse.Query(Idea);

    // query.equalTo("status", "SOMETIDA");
    query.equalTo("needsRecognition", false);
    query.descending("updatedAt");

    query.limit(1000000).find()
    .then((results) => {
      var myData = results.map(function (idea) {
        var newData = {};
        newData['id'] = idea.get("ideaNum");
        newData['type'] = idea.get("recognitionType");
        newData['employeeNum'] = idea.get("employeeNum");
        newData['employeeName'] = idea.get("employeeName"); 
        newData['points'] = idea.get("points"); 
        newData['recognitionDate'] = moment(idea.get('createdAt')).format('MM/DD/YYYY');; 
        newData['status'] = idea.get("status"); 
        newData['ideaNum'] = idea.get("ideaNum"); 
         
        return newData
      })
      
      //  
      // results.map((elem) => 
        
        
        // {"id": 245,
        // "desc": "Esta liqueando la substeacion 24...",
        // "cat": "Accion Inmediata",
        // "prop": "Bryan Short",
        // "status": "Complete",
        // "total": "$6541.92"
        // }
      // );
        this.setState({
            tableData: myData,
        }, this.initSearcher);
    }, (error) => {
      alert('Hubo un error en la busqueda. Favor de tratar luego.');
      // The object was not retrieved successfully.
      // error is a Parse.Error with an error code and message.
    });
  }

  /**
   * Returns the appropriate status class for the `Status` column.
   */
  getStatusClass(status) {
    const statusMap = {
      Cancelled: "danger",
      Complete: "success",
      Pending: "warning"
    };

    return `text-${statusMap[status]}`;
  }

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    this.setState({
      ...this.state,
      tableData: this.searcher.search(e.target.value)
    });
  }

  /**
   * Mock method for editing transactions.
   */
  handleItemEdit(row) {
    alert(`Editing transaction "${row.original.id}"!`);
  }

  /**
   * Mock method for deleting transactions.
   */
  handleItemDelete(row) {
    alert(`Deleting transaction "${row.original.id}"!`);
  }

  /**
   * Mock method for confirming transactions.
   */
  handleItemConfirm(row) {
    alert(`Confirming transaction "${row.original.id}"!`);
  }

  showIdea(item) {
    
    const ideaNum = this.state.tableData[item].ideaNum; // Get ideaNum from data
    
    const IdeaClass = Parse.Object.extend("Idea");
    const ideaQuery = new Parse.Query(IdeaClass);

    ideaQuery.equalTo("num", ideaNum); // Assuming the field name is "num"
    ideaQuery.limit(1);

    ideaQuery.first().then((idea) => {
        if (idea) {
            this.setState(
                { ideaItem: idea },
                () => this.dismissModal()
            );
        } else {
            
        }
    }).catch((error) => {
        console.error("Error while fetching idea:", error);
    });
}


  dismissModal() {
  const {showView} = this.state;
   this.setState({showView: !showView, showPreviousIcon: !this.state.showPreviousIcon})
  }

  /**
   * Mock method for confirming transactions.
   */
  handleItemViewDetails(row) {
    alert(`Viewing details for "${row.original.id}"!`);
  }

  handlePreviousClick = () => {
    // Implement logic for previous button click
    // Example: navigate to the previous step or close modal
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: false, // Close the modal
      showView: false, // Update view state
      showPreviousIcon: false,
    });
  };

  getHistoryView() {
    const selectedIdea = this.state.ideaItem;

    return(
      <Container fluid className="main-content-container px-4 pb-4" style={{backgroundColor: 'white'}}>
         <Row>
            <Col md="12" className="col d-flex align-items-center ">
                <IdeaRecognitionView dismissModal={this.dismissModal.bind(this)} ideaItem={selectedIdea} onViewIdeaPress={this.dismissModal.bind(this)}/>
            </Col>
          </Row>
      </Container>
    )
  }

  getNormalView() {
    const { tableData, pageSize, pageSizeOptions } = this.state;
    const { t } = this.props;
    
    const tableColumns = [
      {
        Header:t("IDEA_UPDATE_NUM"),
        accessor: "id",
        maxWidth: 150,
        className: "text-center"
      },
      {
        Header: t("TRANSACTION_EMPLOYEE_NAME"),
        accessor: "employeeName",
        className: "text-center",
        style: { 'white-space': 'unset' },
        minWidth: 150,
      },
      {
        Header: t("ECONOMIC_REWARD"),
        accessor: "type",
        maxWidth: 150,
        style: { 'white-space': 'unset' },
        className: "text-center"
      },
      {
        Header: t("DATE_VERIFIED"),
        accessor: "recognitionDate",
        maxWidth: 150,
        style: { 'white-space': 'unset' },
        className: "text-center"
      },
      {
        Header: t("OPEN"),
        accessor: "actions",
        maxWidth: 140,
        minWidth: 140,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button theme="white" onClick={() => this.showIdea(row.index)}>
              <i className="material-icons">visibility</i>
            </Button>
          </ButtonGroup>
        )
      }
    ];

    return(
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={t("TRANSACTION_VIEWRECOGNITION")} subtitle={t("Recognition Workflow")} className="text-sm-left mb-3" />
        </Row>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>{t('SHOW')}</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size + ' ' + t('ROWS')} 
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
              />
            </div>
          </CardBody>
        </Card>
        <Row>
            <Col lg="12" className="mb-4 mr-auto">
              {this.state.showPreviousIcon && (
                <PreviousIcon
                  className="functionalButton mr-auto d-block"
                  style={{ minWidth: 140, maxWidth: 140 }}
                  onClick={this.handlePreviousClick}
                ></PreviousIcon>
              )}
            </Col>
      </Row>
      </Container>
    )

  }


  render() {
    const { showView } = this.state;
    return !showView ? this.getNormalView() : this.getHistoryView();
  }
}

function getBadgeType(itemStatus) {
  const statusMap = {
    Complete: "success",
    Saved: "warning",
    Canceled: "danger"
  };

  return statusMap[itemStatus];
}

export default withTranslation()(RecognitionHistory);
