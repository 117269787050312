import React from "react";
import { withTranslation } from "react-i18next";
import ReactLoading from 'react-loading';
import ReactTable from "react-table";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import userDashboardService from '../../services/userDashboardService';
import SingleProgressChart from "../analytics/GoalsOverview/SingleProgressChart";

class LatestOrders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ideas: [],
      isLoading: false,
      filteredData: [],
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      search: ''
    };

    this.fetchIdeas = this.fetchIdeas.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentDidMount() {
    this.fetchIdeas();
  }

  async fetchIdeas() {
    const {isLoading} = this.state;
    if (isLoading) return;
    this.setState({ isLoading: true });

    try {
      const ideas = await userDashboardService.fetchDashboardIdeas();
      this.setState({ ideas, filteredData: ideas });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  showModal(item) {
    this.props.onEvalBtnPress(item, this.props.source);
  }

  handlePageSizeChange(e) {
    this.setState({ pageSize: parseInt(e.target.value) });
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      const filteredData = this.state.ideas.filter(idea =>
        idea.title.toLowerCase().includes(searchTerm) ||
        idea.category.toLowerCase().includes(searchTerm) ||
        idea.status.toLowerCase().includes(searchTerm)
      );
      this.setState({ filteredData });
    });
  }

  render() {
    const { isLoading, filteredData, pageSize, pageSizeOptions, search } = this.state;
    const { t } = this.props;

    const columns = [
      {
        Header: t("IDEA_MANAGE_NUM"),
        accessor: "num",
        
        className: "text-center",
        maxWidth: 120,
      },
      {
        Header: t("IDEA_MANAGE_TITLE"),
        accessor: "title",
        className: "text-center",
        Cell: row => (
          <>
            {row.value}
            {row.original.teamMembers && (
              <Badge pill style={{ marginLeft: '10px', backgroundColor: '#643fda', color: 'white' }}>
                Team
              </Badge>
            )}
          </>
        )
      },
      {
        Header: t("IDEA_MANAGE_CAT"),
        accessor: "category",
        maxWidth: 200,
        className: "text-center",
        style: {color: '#17c671'},
      },
      {
        Header: t("IDEA_MANAGE_PROG"),
        maxWidth: 150,
        accessor: "progress",
        Cell: row => <SingleProgressChart key={row.index} idea={row.original.actions} />
      },
      {
        Header: t("IDEA_MANAGE_STATUS"),
        accessor: "status",
        maxWidth: 150,
        className: "text-center",
        Cell: row => (
          <div className="d-table mx-auto">
            <Badge pill theme={getBadgeType(row.value)} onClick={() => this.showModal(row.original.actions)}>
              {row.value === 'SOMETIDA' ? 'Submitted' : row.value}
            </Badge>
          </div>
        )
      },
      {
        Header: t("Action"),
        accessor: "actions",
        sortable: false,
        className: "text-right",
        maxWidth: 80,
        Cell: row => (
          <Badge pill style={{cursor:'pointer'}} outline onClick={() => this.showModal(row.original.actions)}>
            View
          </Badge>
        )
      }
    ];

    const data = filteredData.map(idea => ({
      num: idea.num,
      title: idea.title,
      category: idea.category,
      progress: idea.progress,
      status: idea.status,
      teamMembers: idea.isTeam,
      actions: idea
    }));

    return (
      <Card>
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex" md="6">
                <span>{t('SHOW')}</span>
                <FormSelect
                  size="sm"
                  value={pageSize}
                  onChange={this.handlePageSizeChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size + ' ' + t('ROWS')}
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col className="file-manager__filters__search d-flex" md="6">
                <InputGroup seamless size="sm" className="ml-auto">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput value={search} onChange={this.handleFilterSearch} />
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <Container fluid className="px-0">
            {isLoading ? (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
              </div>
            ) : (
            <ReactTable
              columns={columns}
              data={data}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              showPageSizeOptions={false}
              resizable={false}
            />)}
          </Container>
        </CardBody>
      </Card>
    );
  }
}

function getBadgeType(itemStatus) {
  const statusMap = {
    "Complete": "success",
    "Saved": "warning",
    "Response Needed": "danger"
  };
  return statusMap[itemStatus];
}

export default withTranslation()(LatestOrders);
