import axios from 'axios';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "shards-react";

import NewUserItem from '../components/administration/NewUserItem';
import colors from "../utils/colors";
import { ReactComponent as AddUserIcon } from "../images/add_user.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import { useTranslation } from "react-i18next";
import ImportDataModal from '../components/administration/ImportDataModal';
import SettingsActionButtons from '../components/common/SettingsActionButtons';
import UserListNew from '../components/ecommerce/UserListNew';

import UserTemplate from '../assets/Murmuratto_Template_User.csv';
import administrationService from '../services/administrationService';

function AdministrationUsersNew(smallStats) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [title, setTitle] = useState('');
  const [ideaStage, setIdeaStage] = useState(1);
  const [category, setCategory] = useState('');
  const [canGoNext, setCanGoNext] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [finishedSaving, setFinishedSaving] = useState(false);
  const [categoryIsOpen, setCategoryIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [reset, setReset] = useState(false);
  const [shouldUpdateUsers, setShouldUpdateUsers] = useState(false);
  const [user, setUser] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);
  const [users, setUsers] = useState([]); // State to hold fetched users
  
  const setInitialTitle = async (user) => {
    setTitle('Administration > Users');
  };

  useEffect(() => {
    setInitialTitle();
    // getCategories();
    fetchUsers(); // Fetch users on component load
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await administrationService.getUsers(); // Call your service to fetch users
      setUsers(response); // Set the users data to state
      setShouldUpdateUsers(!shouldUpdateUsers); // Update the state to trigger a re-render
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getCategories = async() => {
    // const Category = Parse.Object.extend("IdeaCategory");
    // const query = new Parse.Query(Category);
    // const results = await query.find();
    
    // setCategories(results);
  };

  const updateCategories = () => {
    setShouldUpdateUsers(!shouldUpdateUsers);
    setShowUser(false);
    setUser('');
  };

  const createUser = () => {
    setUser('');
    setShowUser(true);
  };

  const editItem = (item) => {
    setUser(item);
    setShowUser(true);
  };

  const uploadData = () => {
    setOpenModal(true);
  };

  const onFinish = () => {
    alert("Your data was imported successfully!");
    setOpenModal(false);
    setShouldUpdateUsers(!shouldUpdateUsers);
    setShowUser(false);
    setUser('');
  };

  const exportData = async () => {
    try {
      const response = await administrationService.exportUsers();
      // Download the file here
      console.log(response)
       // Create a download link
      // const url = window.URL.createObjectURL(response);
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'exported_users.csv'); // Set the desired file name
      // document.body.appendChild(link);
      // link.click(); // Trigger the download
      // document.body.removeChild(link); // Clean up the DOM

    } catch (error) {
      console.error('Error exporting users:', error);
    }
  }

  return (
    <Container fluid className="main-content-container px-4" style={{backgroundColor: 'white'}}>
      <Row>
        <Col md="12" lg="12" className="mt-4 mx-auto">
          <Row>
            <Col md="8" lg="8">
              <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
            </Col>
            
            <Col xs="12" md="2" lg="2" className="col d-flex align-items-center ml-auto">
              <SettingsActionButtons uploadData={() => uploadData()} templateFile={UserTemplate} dataCsv={dataCsv} exportData={() => exportData()}></SettingsActionButtons>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivisorBarIcon></DivisorBarIcon>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row className="mt-4">
        <Col lg="12" className="m-auto">
          <AddUserIcon className="functionalButton mr-4 d-block mb-4" style={{minWidth: 180, maxWidth:180}} onClick={() => createUser()}></AddUserIcon>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md="12" lg="12">
          {showUser && <NewUserItem key={Math.random()} id={Math.random()} open={showUser} shouldReset={reset} updateUsers={updateCategories} user={user}></NewUserItem>}
        </Col>
      </Row>

      <Row className="mt-2">
        <Col lg="12" className="m-auto">
          <div style={{backgroundColor: '#F6F6F6', maxHeight: 500, overflow: 'auto', borderStyle: 'solid', borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 100}}>
            <UserListNew shouldUpdate={shouldUpdateUsers} users={users} editItem={(user) => editItem(user)} setDataCsv={(data) => setDataCsv(data)}/>
          </div>
        </Col>
      </Row>
      
      <ImportDataModal open={openModal} onFinish={() => onFinish()} dataType="users"></ImportDataModal>
    </Container>
  );
}

AdministrationUsersNew.propTypes = {
  smallStats: PropTypes.array
};

AdministrationUsersNew.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationUsersNew;
