import axios from 'axios';
import { authUser } from './userService';

  const API_URL = 'https://api.murmuratto.com';

const fetchOpportunityIdeas = async (opportunityId, viewFrom) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/opportunity/${opportunityId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
      params: {
        viewFrom: viewFrom
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const getOpportunityTasks = async (opportunityId) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/opportunity/${opportunityId}/tasks`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const fetchOpportunityHistory = async (opportunityId) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/opportunity/${opportunityId}/history`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default {
  fetchOpportunityIdeas,
  getOpportunityTasks,
  fetchOpportunityHistory
};
