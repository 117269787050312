import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

const fetchVerifyInboxIdeas = async (context) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/workflow/verify?context=${context}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }

};

const submitVerification = async (opportunityId, verificationData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('action', verificationData.action);
  formData.append('goalsMet', verificationData.goalsMet);
  formData.append('processImprovement', verificationData.processImprovement);
  formData.append('financialImpact', verificationData.financialImpact);
  formData.append('replicationPotential', verificationData.replicationPotential);
  formData.append('additionalComments', verificationData.additionalComments);
  formData.append('fileId', verificationData.fileId);
  try {
    const response = await axios.post(`${API_URL}/workflow/verify/${opportunityId}`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting verification:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const getVerificationById = async (opportunityId) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('action', 'requestInformation');

  try {
    const response = await axios.post(`${API_URL}/workflow/verify/${opportunityId}`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching verification by ID:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Request information
const requestInformation = async (opportunityId, requestData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('action', 'requestInformation');
  formData.append('subject', requestData.subject);
  formData.append('body', requestData.body);

  try {
    const response = await axios.post(`${API_URL}/workflow/verify/${opportunityId}`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting verification:', error.response ? error.response.data : error.message);
    throw error;
  }
}

export default {
  fetchVerifyInboxIdeas,
  submitVerification,
  getVerificationById,
  requestInformation
};