import axios from 'axios';
import { authUser } from './userService';

const API_URL = 'https://api.murmuratto.com';

export const getUserInfo = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/me`, {
      headers: {
        ...authHeader,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user info:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const postSurveyResponse = async (rating, feel) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    // Create a FormData object and append the rating and feel
    const formData = new FormData();
    formData.append('rating', rating);
    formData.append('feel', feel);
  
    try {
      const response = await axios.post(`${API_URL}/me/survey`, formData, {
        headers: {
          ...authHeader,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error submitting survey response:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  