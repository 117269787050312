import moment from 'moment';
import Parse from 'parse';
import React from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Tooltip
} from "shards-react";
import Swal from 'sweetalert2';

import { object } from "prop-types";
import ReactLoading from 'react-loading';
import { withRouter } from "react-router-dom";
import MergeIdeasModal from "../administration/MergeIdeasModal";


// Defult
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as GreenIcon } from "../../images/green_icon.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as RedIcon } from "../../images/red_icon.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";
// fill="#157ffb"

// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
import { ReactComponent as DefaultIcon } from '../../images/default_icon.svg';
// import selectIdeaImage from '../../images/select_idea_category_new.png';

// New Selected
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";

// Updated Icons
import { ReactComponent as DenyImage } from '../../images/Icons_Idle_08_Deny.svg';
import { ReactComponent as SaveImage } from '../../images/Icons_Idle_09_Save.svg';
import { ReactComponent as TransferImage } from '../../images/Icons_Idle_10_Transfer.svg';
import { ReactComponent as ProjectImage } from '../../images/Icons_Idle_11_Project.svg';
import { ReactComponent as QuestionImage } from '../../images/Icons_Idle_12_Question.svg';

// Updated Icons Selected
import { withTranslation } from 'react-i18next';
import { ReactComponent as DenyImageSelected } from '../../images/Icons_Selected_08_Deny.svg';
import { ReactComponent as SaveImageSelected } from '../../images/Icons_Selected_09_Save.svg';
import { ReactComponent as TransferImageSelected } from '../../images/Icons_Selected_10_Transfer.svg';
import { ReactComponent as ProjectImageSelected } from '../../images/Icons_Selected_11_Project.svg';
import { ReactComponent as QuestionImageSelected } from '../../images/Icons_Selected_12_Question.svg';

class IdeaViewCardNewAi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data:[],
      ideaQuestions: [],
      currentEvaluationHistory: [],
      similarEvaluationHistory: [],
      answers:[],
      category:null,
      department:null,
      date: '',
      categoryQuestions: [],
      filterQuestions: [],
      selectedFilterQ: [],
      filterQAnswers: [],
      categoryQAnswers: [],
      ideaDescription: null,
      file:null, 
      buttonNext:true,
      descriptionValid:'',
      remainingCharacters: 250,
      visible: true,
      filterVisible: false,
      filterQuestionsVisible: false, 
      ideaQuestionsVisible: false,
      hideNextButton: false,
      userName: 'User Name',
      sectionTitle:'',
      formButtonTitle: 'Continuar',
      ideaNumber: '#00008',
      status: '',
      executionResObj: object,
      selectedBenefit:'',
      selectedResult: '',
      money: '',
      selectedImprovement: '',
      selectedImpact: '',
      selectedCoachBackup: '',
      isRecognized: '',
      page: 2,
      responseInfo: false,
      responseInfo2: false,
      selectedStatus: ''.length,
      expectedReturn: '',
      timeUnit: '',
      timeUnitVal: '',
      executionRes: 0,
      coachRes: '',
      recurringImpact: false,
      comment: '',
      needsEconomicImpact: false,
      attachmentModalOpen: false,
      hasTeam: false,
      rfiOpen: false,
      transferCommitteeOpen: false,
      pmoOpen: false,
      mergeIdeasOpen: this.props.openMergeModal,
      showEvalFields: true,
      rfiInfo: '',
      showRfiField: false,
      showRfiAnswerField: false,
      tcInfo: '',
      showTcField: false,
      showTcAnswerField: false, 
      pmoInfo: '',
      showPmoField: false,
      showPmoAnswerField: false,
      pmoSelection: false,
      showLoading: false,
      showEvalCriteria: false,
      userEmail: ''
    }

    this.change = this.change.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setIdeaDescription = this.setIdeaDescription.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.setEvalStatus = this.setEvalStatus.bind(this);
    this.setExpectedReturn = this.setExpectedReturn.bind(this);
    this.setTimeUnit = this.setTimeUnit.bind(this);
  }

  componentDidMount() {
    let currUser = Parse.User.current();
    this.getUserName(currUser);
    this.fetchNewData();
    this.fetchQuestions();
    this.fetchFilterQuestions();
    this.getDate();
    this.fetchCategoryData()
    this.getEvaluationHistory()
  }

  getEvaluationHistory = async () => {
  const { ideaItem } = this.props; 
  const similarOpportunity = ideaItem.similarOpportunity;

  try {
    const EvaluationHistory = Parse.Object.extend('EvaluationHistory');
    const currentQuery = new Parse.Query(EvaluationHistory);
    currentQuery.equalTo('ideaReference', ideaItem.id);
    let currentEvalHistory = await currentQuery.find({ useMasterKey: true });

    let similarEvalHistory = [];
    if (similarOpportunity) {
      const similarQuery = new Parse.Query(EvaluationHistory);
      similarQuery.equalTo('ideaReference', similarOpportunity.id);
      similarEvalHistory = await similarQuery.find({ useMasterKey: true });
    }

    this.setState({ 
      currentEvaluationHistory: currentEvalHistory,
      similarEvaluationHistory: similarEvalHistory
    });
  } catch (error) {
    this.setState({ 
      currentEvaluationHistory: [],
      similarEvaluationHistory: []
    });
  }
}


  getDate() {
    this.setState({
      date: new Date()
    });
  }

  async getUserName(user) {
    var query = new Parse.Query(Parse.User);
    query.equalTo("objectId", user.id);
    const results = await query.find({useMasterKey: true});
    const firstName = results[0].get('firstName');
    const lastName = results[0].get('lastName');
    const fullName = firstName + ' ' + lastName;
  
    this.setState({
      userName: fullName,
      userEmail: results[0].get('email')
    });
  }

  changeRfiInfo = (info) => {
    this.setState({rfiInfo: info, rfiOpen: false, showRfiField: true, showTcField: false, showPmoField: false})
  }

  changeTcInfo = (info) => {
    this.setState({tcInfo: info, transferCommitteeOpen: false, showTcField: true, showRfiField: false, showPmoField: false})
  }

  changePmoInfo = (info) => {
    this.setState({pmoInfo: info, pmoOpen: false, showPmoField: true, showRfiField: false, showTcField: false})
  }

  changeSelection = (info) => {
    this.setState({pmoSelection: info})
  }

  editRfi = () => {
    this.setState({rfiOpen: true})
  }

  editTc = () => {
    this.setState({transferCommitteeOpen: true})
  }
  
  editPmo = () => {
    this.setState({pmoOpen: true})
  }

  showMergeIdeas = () => {
    this.setState({mergeIdeasOpen: true})
  }

  fetchNewData() {
    const className = "IdeaDepartment";
    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);

    query.find()
    .then((results) => {
      this.setState({
        data: results
      });
    }, (error) => {
      this.setState({
        data: []
      });
    });
  }

  fetchCategoryData() {
    const ideaItem = this.props.ideaItem;
    const ideaCategory = ideaItem.get("category")
    const className = "IdeaCategory";
    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);

    query.equalTo("itemName", ideaCategory);
    query.find()
    .then((results) => {
      this.setState({
        ideaCategory: results[0]
      });
    }, (error) => {
      this.setState({
        data: []
      });
    });
  }

  fetchQuestions() {
    const className = "IdeaQuestion";
    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);

    query.find()
    .then((results) => {
      this.setState({
        ideaQuestions: results
      });
    }, (error) => {
      this.setState({
        data: []
      });
    });
  }

  fetchFilterQuestions() {
    const className = "FilterQuestion";

    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);
    query.find()
    .then((results) => {
      this.setState({
        filterQuestions: results
      });
    }, (error) => {
      this.setState({
        filterQuestions: []
      });
    });
  }

  handleCategoryChange(selectedCategory) {
    const newCategory = selectedCategory;
    const { ideaQuestions } = this.state;

    var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
          
    this.setState({
      categoryQuestions: filteredData
    }, this.addAnswer(filteredData));
  }

  onSubmit = e => {
    e.preventDefault();
    alert('Form submitted');
  };


  resetForm() {
    this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
  }

  change(event) {
    this.setState({department: event.target.value});
  }

  setCategory(categoryName) {
    this.setState({
      category: categoryName
    })

    this.handleCategoryChange(categoryName)
  }

  setFilter(filterName) {
    this.handleFilterChange(filterName);
  }

  clickedPrint() {
      
  }

  setDate(ideaDate) {
    this.setState({
      date: ideaDate
    })
  }

  filterQuestionAnswerChange(event, idx) {
    this.state.filterQAnswers[idx].answer = event.target.value;
  }

  questionAnswerChange(event, idx) {
    this.state.answers[idx].answer = event.target.value;
  }

  setIdeaDescription(event) {
    const description = event.target.value;
    const maxCharacters = 250;
    const charCount = maxCharacters - description.length
    var isValid = null;

    if (charCount < 0 && description.length > 0) {
      isValid = false
    } else if (charCount > 0 && description.length > 0) {
      isValid = true
    } else {
      isValid = null
    }

    this.setState({
      descriptionValid: isValid,
      ideaDescription: description,
      remainingCharacters: charCount
    })
  }

  addAnswer(filteredQuestions) {
    filteredQuestions.map((item, idx) => (
      this.setState((prevState) => ({
        answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
      }))
    ))
  }

  addFilterAnswer(filteredData) {
    var newItems = [];
    filteredData.forEach((item, idx) => {
      newItems.push({question:item.get("question"), answer:''});
    })

    this.setState({ filterQAnswers: newItems}, () => {});
  }

  getSecondaryEmail = async(primaryEmail) => {
    const User = Parse.Object.extend("User");
    const query = new Parse.Query(User);
    query.doesNotExist("deletedAt").equalTo("email", primaryEmail);
    const results = await query.find();

    if (results.length >= 1) {
      return results[0].get("secondaryEmail")
    } else {
      return ''
    }
  }

  changeStatus(selection) {
    this.setState({status: selection});
  }

  async getHumanResourceUsers() {
    var query = new Parse.Query(Parse.User);
    query.equalTo("humanResources", true);
    const results = await query.find();
      
    return results
  }

  changeBenefit(response) {
    this.setState({
      selectedBenefit: response
    });
  }

  changeResults(response) {
    this.setState({
      selectedResult: response
    });
  }

  changeImprovement(response) {
    this.setState({
      selectedImprovement: response
    });
  }

  changeImpact(response) {
    this.setState({
      selectedImpact: response
    });
  }

  changeBackup(response) {
    this.setState({
      selectedCoachBackup: response
    });
  }

  changeRecognition(response) {
    this.setState({
      isRecognized: response
    });
  }

  getDate(date) {
    return moment(date).format('DD / MM / YYYY');
  }

  getIcon(name, fillColor) {
    const newIcons = [
      {normal: <UrgentImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
      selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <ProductivityImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
      selected: <ProductivityImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <CheckmarkImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
      selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <TrophyImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
      selected: <TrophyImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <Shield2Image className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
      selected: <Shield2ImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <DollarSignImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
      selected: <DollarSignImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <NumberOneImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
      selected: <NumberOneImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <UrgentImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
        selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <DenyImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
        selected: <DenyImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <TransferImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
        selected: <TransferImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <ProjectImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
        selected: <ProjectImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <QuestionImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
        selected: <QuestionImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <SaveImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
        selected: <SaveImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
      {normal: <CheckmarkImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
        selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
      },
    ]

    switch(name) {
      case 'HandImage':
        return <HandImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
      case 'ShieldImage':
          return <ShieldImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
      case 'ChartImage':
          return <ChartImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
      case 'TeamImage':
          return <TeamImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
      case 'DollarImage':
          return <DollarImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
      case 'ClockImage':
          return <TimeImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
      //New Icons
      case 'Urgent':
          return newIcons[0].selected;
      case 'Productivity':
          return newIcons[1].selected;
      case 'Checkmark':
          return newIcons[2].selected;
      case 'Trophy':
          return newIcons[3].selected;
      case 'Shield Image':
          return newIcons[4].selected;
      case 'Dollar':
          return newIcons[5].selected;
      case 'Dollar Sign':
        return newIcons[5].selected;
      case 'Number One':
          return newIcons[6].selected;
      case 'Approve':
          return newIcons[13].selected;
      case 'Do not Pursue':
          return newIcons[8].selected;
      case 'Save for Later':
          return newIcons[12].selected;
      case 'Request information':
          return newIcons[11].selected;
      case 'Request\ninformation':
        return newIcons[11].selected;
      case 'Project Idea':
          return newIcons[10].selected;
      case 'Transfer Committee':
          return newIcons[9].selected;
      case 'Transfer\nCommittee':
        return newIcons[9].selected;
      default:
        return <DefaultIcon className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
    }
  }

  getIconDescription(name) {
    const {t} = this.props;
  
    switch(name) {
        case 'Approve':
            return t("APPROVE_MSG");
        case 'Do not Pursue':
            return t("DO_NOT_PURSUE_MSG");
        case 'Save for Later':
            return t("SAVE_FOR_LATER_MSG");
        case 'Request information':
            return t("REQUEST_INFORMATION_MSG");
        case 'Request\ninformation':
            return t("REQUEST_INFORMATION_MSG");
        case 'Project Idea':
            return t("PROJECT_IDEA_MSG");
        case 'Transfer Committee':
            return t("TRANSFER_COMMITTEE_MSG");
        case 'Transfer\nCommittee':
          return t("TRANSFER_COMMITTEE_MSG");
        default:
          return "No description available at the moment."
      }
  }

  toggle() {
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  toggleTwo() {
    this.setState({
      responseInfo2: !this.state.responseInfo2
    });
  }

  setEvalStatus(status, index) {
      
    var shouldOpenRfiModal = false
    var showEvalFields = true

    var shouldOpenTcModal = false
    var shouldOpenPmoModal = false
    
    if (status == "Request\ninformation") {
      shouldOpenRfiModal = true
      showEvalFields = false
    }

    if (status == "Transfer\nCommittee") {
      shouldOpenTcModal = true
      showEvalFields = false
    }

    if (status == "Project Idea") {
      shouldOpenPmoModal = true
      showEvalFields = false
    }

    if (status == "Approve" || status == "Do not Pursue" || status == "Save for Later") {
      this.setState({
        showRfiField: false,
        showTcField: false,
        showPmoField: false
      })
    }

    if (status != "Approve") {
      this.props.setNoMoreSteps(true)
    } else {
      this.props.setNoMoreSteps(false)
    }

    this.props.changeStatus(true)

    this.setState({
      showEvalFields: showEvalFields,
      rfiOpen: shouldOpenRfiModal,
      pmoOpen: shouldOpenPmoModal,
      transferCommitteeOpen: shouldOpenTcModal,
      selectedStatus: status,
      selectedStatusVal: status
    })
  }

    setExpectedReturn(event) {
      const amount = event.target.value;
      const correctAmount =  amount.replace(/\D/g, "");
  
      //  
      this.setState({
        expectedReturn: correctAmount,
      })

      if (amount && this.state.timeUnit) {
        this.props.changeStatus(true)
      } else {
        this.props.changeStatus(false)
      }
    }

    setTimeUnit(unit) {
       
      this.setState({timeUnit: unit.label, timeUnitVal: unit})
      if (this.state.expectedReturn && unit.label) {
        this.props.changeStatus(true)
      }
    }

    changeResponsible(res, idx) {
      this.setState({
        executionRes: res,
      });
      
      if (res) {
        this.props.changeStatus(true)
      }
       
    }

    changeCoach(res, idx) {
      this.setState({
        coachRes: res,
      });
       
    }

    commentChangeField(res) {
      const comment = res.target.value
      this.setState({
        comment: comment
      })
    }

    showEvalCriteriaModal = () => {
      this.setState({showEvalCriteria: true})
    }

    changeIdeaStageBack = () => {
      window.location.reload()
    }

    changeIdeaStage = (similarOpportunity) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to mark this opportunity as not a duplicate?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCloseButton: true,
        icon: null
      }).then((result) => {
         if (result.isConfirmed) {
          const currentNotDuplicates = this.props.ideaItem.get('notDuplicates') || [];
          const updatedNotDuplicates = [...currentNotDuplicates, similarOpportunity];
          this.props.ideaItem.set('notDuplicates', updatedNotDuplicates);

          this.props.ideaItem.save()
            .then(() => {
              const toUrl = `/evaluate-${this.props.ideaItem.id}`;
              this.props.history.push(toUrl);
            })
            .catch((error) => {
              console.error('Error saving ideaItem:', error);
              Swal.fire({
                title: 'Error',
                text: 'There was an error saving the changes. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            });
        }
      });
    }

    getType = (type) => {
      let editedIdeaType = type
      switch(type) {
        case 'innovacion':
          editedIdeaType = "Innovation";
          break;
        case 'improvement':
        case 'mejoras':
          editedIdeaType = "Continuous Improvement";
          break;
        case 'problema':
        case 'Problem Solving':
          editedIdeaType = "Problem Solving";
          break;
        default:
          editedIdeaType = "Problem Solving";
          break;
        }
      return editedIdeaType
    }

    render() {
        const {ideaCategory, visible } = this.state
        const {t, ideaItem} = this.props;
        const similarOpportunity = ideaItem.similarOpportunity
        const formVisibilityState = visible? 'block' : 'none';
       
        const ideaDate = ideaItem.submittal.submittedAt
        const today = moment()
        const ideaMomentDate = moment(ideaDate)
        const timeDiff = today.diff(ideaMomentDate, 'days')
        const timingWording = ideaItem.submittal.responseStatus
         
        const parsedDate = this.getDate(ideaDate)

        const similarIdeaType = this.getType(similarOpportunity.type);
        

        const similarIdeaDate = similarOpportunity.submittal.submittedAt;
        const similarIdeaMomentDate = moment(similarIdeaDate);
        const similarTimeDiff = today.diff(similarIdeaMomentDate, 'days');
        const similarTimingWording = similarTimeDiff > 10 ? "Late" : "On-Time";
        const similarParsedDate = this.getDate(similarIdeaDate);


        const storageLanguage =  localStorage.getItem('language') != null?localStorage.getItem('language'):'en';
        const myIcon = ideaCategory && ideaCategory.get("icon")
        const similarMyIcon = ideaCategory && ideaCategory.get("icon")
    
        const categoryTitle = ideaCategory?ideaCategory.transciption[storageLanguage]:ideaItem.category
        const similarCategoryTitle = similarOpportunity.category
        const ideaFile = ideaItem.fileId
        const isPdf = ideaFile && ideaFile._name.includes(".pdf")
        const downloadFileString = t('VIEW_FILE')

        const editedIdeaType = this.getType(ideaItem.get("ideaType"))


        return(
          this.props.canSubmit?
            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30em'}}>
              <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80}/>
            </div>
            :
            <div className="edit-user-details" >
          <Form className="py-4" onSubmit={this.onSubmit} noValidate >
            <div style={{display: formVisibilityState}}>
            <Row form>
              <Col lg="5">
                <Row form>
                  <Col md="12" className="form-group">
                  <Row className="mt-4">
                      <Col>
                        <h4 style={{fontWeight: 500, color: 'red'}}>Current Opportunity</h4>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <label htmlFor="firstName" className="georgia">{t('Title')}</label>
                        <h6 style={{fontWeight: 500,  color: '#303030'}}>{ideaItem.get("title")}</h6>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md="6">
                        <label htmlFor="firstName" className="georgia">{t('CATEGORY')}</label>
                        <Row>
                          <Col>
                          {this.getIcon(myIcon, 'Black')}
                              <div className="mr-auto" style={{width: '100%', backgrounColor: 'black'}}>
                                <h6 style={{fontWeight: 500,  color: '#303030'}}>{categoryTitle}</h6>
                              </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row className="mt-2">
                          <Col>
                            <label htmlFor="firstName" className="georgia">Submit Date</label>
                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{parsedDate}</h6>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <label htmlFor="firstName" className="georgia">Employee Response</label>
                            <Row>
                              <Col md="7">
                                <h6 style={{fontWeight: 500,  color: '#303030'}}>{timingWording}</h6>
                              </Col>
                              <Col className="mb-auto" md="1">
                                {/* <div className="my-auto" style={{backgroundColor: '#1DE334', height: 16, width: 16, borderRadius: 8}}></div> */}
                                { timingWording == "On-Time"? 
                                <GreenIcon style={{height: 16, width: 16}}></GreenIcon>
                                :
                                <RedIcon style={{height: 16, width: 16}}></RedIcon>
                                }
                              </Col>
                              <Col md="1" className="mb-auto">
                                <a id={"TooltipResponseInfo"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                                    const myCopy = this.state.responseInfo
                                    myCopy = !myCopy
                                    this.setState({responseInfo: myCopy})
                                }}>
                                    
                                    <InfoIcon style={{height: 16, width: 16}}></InfoIcon>
                                    
                                </a>
                              </Col>
                              
                              <Tooltip
                                open={this.state.responseInfo}
                                target={"#TooltipResponseInfo"}
                                id={"TooltipResponseInfo1"}
                                toggle={() => {this.toggle()}}
                                >
                                  {t("RESPONSE_TIME_MSG")}
                              </Tooltip>
                          </Row>

                          </Col>
                        </Row>
                        
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col>
                        <label htmlFor="firstName" className="georgia">{t('Type')}</label>
                        <h6 style={{fontWeight: 500,  color: '#303030'}}>{editedIdeaType}</h6>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col>
                        <label htmlFor="firstName" className="georgia">{t("SUBMIT_IDEA_Department")}</label>
                        <h6 style={{fontWeight: 500,  color: '#303030'}}>{ideaItem.get("department")}</h6>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col>
                        <label htmlFor="firstName" className="georgia">{t("DESCRIPTION")}</label>
                        <h6 style={{fontWeight: 500,  color: '#303030'}}>{ideaItem.get("description")}</h6>
                      </Col>
                    </Row>
                    {ideaItem.get("file") && 
                    <Row className="mt-4">
                      <Col>
                        <label htmlFor="firstName" className="georgia">Attachments</label>
                        <Button onClick={() => isPdf?window.open(ideaFile._url):this.setState({attachmentModalOpen: true})} style={{display: 'flex'}} >{downloadFileString}</Button>
                      </Col>
                    </Row>
                    }
                  </Col>

                </Row>

                {this.state.currentEvaluationHistory.map((item, index) => {
                  if(item.get('status') === 'Project Opportunity')
                    return (<div>
                      <Row className="mt-4">
                        <Col md="12" className="form-group">
                          <div style={{backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid'}}>
                            <h6 className="ml-2 mt-2" style={{fontWeight: 500,  color: '#303030'}}>Project Opportunity Comments: </h6>
                              <p className="mx-2"><strong>Reason for transfer: </strong>{item.get("pmoInfo").reason}</p>
                            <p className="mx-2"><strong>Is this idea aligned with our business' needs, goals and/or KPI's? </strong>{item.get("pmoInfo").alignedWithCompany == true?"Yes":'No'}</p>
                            {item.get("evaluatedAt") && <p className="mx-2"><strong>Evaluated by: </strong>{item.get("userFullName")} on {moment(item.get("evaluatedAt")).format("DD MMMM YYYY, hh:mm A")} </p>}
                          </div>
                        </Col>
                      </Row> 
                    </div>)
                  else if(item.get('status') === 'Response Needed'){
                    return (<div>
                      <Row className="mt-4">
                      <Col md="12" className="form-group">
                        <div style={{backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid'}}>
                          <h6 className="ml-2 mt-2" style={{fontWeight: 500,  color: '#303030'}}>RFI Submitted: </h6>
                          <p className="mx-2">{item.get("rfiQuestion")}</p>
                          {item.get("evaluatedAt") && <p className="mx-2"><strong>Submitted by: </strong>{item.get("userFullName")} on {moment(item.get("evaluatedAt")).format("DD MMMM YYYY, hh:mm A")} </p>}

                          <hr/>
                          <h6 className="ml-2 mt-2" style={{fontWeight: 500,  color: '#303030'}}>Response: </h6>
                          <p className="mx-2">{item.get("rfiAnswer")}</p>
                        </div>
                      </Col>
                    </Row> 
                      </div>)
                  }
                  return <></>
                })
              } 


                <Row form>
                  {ideaItem.get("filterAnswer").map((question, index) => {
                    if(!question) return null;
                      const prefix = 'Qdd' + (index + 1)+ ': '
                      const myQuestion = prefix + question["question"]
                      return(
                      <Col md="12" className="form-group">
                        <Row className="mt-4">
                          <Col md="12">
                            <label className="georgia">{myQuestion}</label>
                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{question["answer"]}</h6>
                          </Col>
                        </Row>
                      </Col>)
                  })}
                </Row>
              </Col>
              <Col lg="1" className="mx-auto">
                <div className="mx-auto" style={{height: '100%', width: 1, backgroundColor: '#BABABA'}}></div>
              </Col>














              <Col lg="5">
              <Row form>
                {/* Proponent */}
                <Col md="12" className="form-group">
                  <Row className="mt-4">
                      <Col>
                        <h4 style={{fontWeight: 500,  color: '#303030', color: 'red'}}>Similar Opportunity</h4>
                      </Col>
                    </Row>
                  <Row className="mt-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">{t('Title')}</label>
                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{similarOpportunity.get("title")}</h6>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md="6">
                      <label htmlFor="firstName" className="georgia">{t('Category')}</label>
                      <Row>
                        <Col>
                        {this.getIcon(similarMyIcon, 'Black')}
                            <div className="mr-auto" style={{width: '100%', backgrounColor: 'black'}}>
                              <h6 style={{fontWeight: 500,  color: '#303030'}}>{similarCategoryTitle}</h6>
                            </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row className="mt-2">
                        <Col>
                          <label htmlFor="firstName" className="georgia">Submit Date</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{similarParsedDate}</h6>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <label htmlFor="firstName" className="georgia">Employee Response</label>
                          <Row>
                            <Col md="7">
                              <h6 style={{fontWeight: 500,  color: '#303030'}}>{similarTimingWording}</h6>
                            </Col>
                            <Col className="mb-auto" md="1">
                              {/* <div className="my-auto" style={{backgroundColor: '#1DE334', height: 16, width: 16, borderRadius: 8}}></div> */}
                              { similarTimingWording == "On-Time"? 
                              <GreenIcon style={{height: 16, width: 16}}></GreenIcon>
                              :
                              <RedIcon style={{height: 16, width: 16}}></RedIcon>
                              }
                            </Col>
                            <Col md="1" className="mb-auto">
                              <a id={"TooltipResponseInfo"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                                  const myCopy = this.state.responseInfo
                                  myCopy = !myCopy
                                  this.setState({responseInfo: myCopy})
                              }}>
                                  
                                  <InfoIcon style={{height: 16, width: 16}}></InfoIcon>
                                  
                              </a>
                            </Col>
                            
                            <Tooltip
                              open={this.state.responseInfo}
                              target={"#TooltipResponseInfo"}
                              id={"TooltipResponseInfo1"}
                              toggle={() => {this.toggle()}}
                              >
                                {t("RESPONSE_TIME_MSG")}
                            </Tooltip>
                        </Row>

                        </Col>
                      </Row>
                      
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">{t('Type')}</label>
                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{similarIdeaType}</h6>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">{t("SUBMIT_IDEA_Department")}</label>
                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{similarOpportunity.get("department")}</h6>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Idea Description</label>
                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{similarOpportunity.get("description")}</h6>
                    </Col>
                  </Row>
                  {ideaItem.get("file") && 
                  <Row className="mt-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Attachments</label>
                      <Button onClick={() => isPdf?window.open(ideaFile._url):this.setState({attachmentModalOpen: true})} style={{display: 'flex'}} >{downloadFileString}</Button>
                    </Col>
                  </Row>
                  }
                </Col>

              </Row>
              
              {this.state.similarEvaluationHistory.map((item, index) => {
                if(item.get('status') === 'Project Opportunity')
                  return (<div>
                    <Row className="mt-4">
                      <Col md="12" className="form-group">
                        <div style={{backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid'}}>
                          <h6 className="ml-2 mt-2" style={{fontWeight: 500,  color: '#303030'}}>Project Opportunity Comments: </h6>
                            <p className="mx-2"><strong>Reason for transfer: </strong>{item.get("pmoInfo").reason}</p>
                          <p className="mx-2"><strong>Is this idea aligned with our business' needs, goals and/or KPI's? </strong>{item.get("pmoInfo").alignedWithCompany == true?"Yes":'No'}</p>
                          {item.get("evaluatedAt") && <p className="mx-2"><strong>Evaluated by: </strong>{item.get("userFullName")} on {moment(item.get("evaluatedAt")).format("DD MMMM YYYY, hh:mm A")} </p>}
                        </div>
                      </Col>
                    </Row> 
                  </div>)
                else if(item.get('status') === 'Response Needed'){
                  return (<div>
                    <Row className="mt-4">
                    <Col md="12" className="form-group">
                      <div style={{backgroundColor: 'white', borderWidth: 1, borderStyle: 'solid'}}>
                        <h6 className="ml-2 mt-2" style={{fontWeight: 500,  color: '#303030'}}>RFI Submitted: </h6>
                        <p className="mx-2">{item.get("rfiQuestion")}</p>
                        {item.get("evaluatedAt") && <p className="mx-2"><strong>Submitted by: </strong>{item.get("userFullName")} on {moment(item.get("evaluatedAt")).format("DD MMMM YYYY, hh:mm A")} </p>}

                        <hr/>
                        <h6 className="ml-2 mt-2" style={{fontWeight: 500,  color: '#303030'}}>Response: </h6>
                        <p className="mx-2">{item.get("rfiAnswer")}</p>
                      </div>
                    </Col>
                  </Row> 
                    </div>)
                }
                return <></>
              })} 

            <Row form>
              {similarOpportunity.get("filterAnswer").map((question, index) => {
                  const prefix = 'Q' + (index + 1)+ ': '
                  const myQuestion = prefix + question["question"]
                  return(
                  <Col md="12" className="form-group">
                    <Row className="mt-4">
                      <Col md="12">
                        <label className="georgia">{myQuestion}</label>
                        <h6 style={{fontWeight: 500,  color: '#303030'}}>{question["answer"]}</h6>
                      </Col>
                    </Row>
                  </Col>)
              })}
              </Row>
            </Col>
            
            </Row>
            
              <Row className="mt-4 floating-buttons pb-4">
                  {/* Add a column that takes full width and includes a title */}
                  <Col xs={12}>
                      <div className="text-center mt-2 pb-2">
                      <h6 style={{fontWeight: 500,  color: '#303030'}}>Select an option</h6>
                      </div>
                  </Col>
                  <Col className="button-col">
                      <div className="text-center mb-2">
                          <Button pill style={{backgroundColor:"#6e4db8", borderColor: "#6e4db8"}} onClick={() => this.setState({mergeIdeasOpen: true})}>Merge</Button>
                      </div>
                  </Col>
                  <Col className="button-col">
                      <div className="text-center mb-2">
                          <Button pill style={{backgroundColor:"#6e4db8", borderColor: "#6e4db8"}} onClick={() => this.changeIdeaStageBack()}>Review Later</Button>
                      </div>
                  </Col>
                  <Col className="button-col">
                      <div className="text-center mb-2">
                          <Button pill style={{backgroundColor:"#6e4db8", borderColor: "#6e4db8"}} onClick={() => this.changeIdeaStage(similarOpportunity)}>Not Duplicate</Button>
                      </div>
                  </Col>
              </Row>
            </div>
            </Form>
              <MergeIdeasModal isOpen={this.state.mergeIdeasOpen} closeModal={() => this.setState({mergeIdeasOpen: false}) } changeText={(text) => this.changePmoInfo(text)} changeSelection={(selection) => this.changeSelection(selection)} text={this.state.pmoInfo} idea={ideaItem} similarOpportunity={similarOpportunity}></MergeIdeasModal>

        </div>

      );
    }
}




export default withTranslation()(withRouter(IdeaViewCardNewAi));