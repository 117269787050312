import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import { Col, Row } from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import colors from "../../utils/colors";
import EditCategoryForm from '../common/EditCategoryForm';
import SmallSwitch from '../common/SmallSwitch';



function CategoryItem({category, updateCategories, activeCategories}) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(1)
//   const [category, setCategory] = useState('')
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(false)

  const setInitialTitle = async (user) => {
    setTitle('Administration > Categories')
  }

  useEffect(() => {
    setInitialTitle()
  });


  const setFinished = () => {
    setIdeaStage(3)
  }

  const changeBtnStatus = (status) => {
 
   setCanGoNext(status)
  }

  const changeContinueBtnStatus = (status) => {
   setCanContinue(status)
  }

  const updateIdeaCategoryStatus = async (category, status) => {
    if (activeCategories >= 4 && status == false) {
      alert('Only 4 active categories are allowed. Please verify.')
    } else {
      if(category.get("endDate") && category.get("endDate") < new Date()) {
        alert(t('CATEGORY_ENDED'))
        return
      }

      category.set("show", !status);
      category.set("customUpdatedAt", new Date());
      category.save().then(() => {
        updateCategories()
      })
    }
    
  }

  const categoryNameEnglish = category.get("itemNameTrans").en
  const categoryStatus = category.get("show")
  

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (categoryNameEnglish == '') {
      setCategoryIsOpen(true)
    }
  }, []);

  return (
    <Row>
      {/* Latest Orders */}
      <Col lg="10" className="m-auto">
              <div className="my-auto" style={{width: '100%', borderStyle: 'solid',borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex'}}>

                {/* Change Status Color of Category */}
                {/* {categoryStatus && <GreenIcon className="ml-4 my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></GreenIcon>}
                {!categoryStatus && <RedIcon className="ml-4 my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></RedIcon>} */}
                <div className="ml-4" style={{ width: 40}}>
                  
                  <SmallSwitch 
                      // className="mt-auto"
                      style={{marginTop: 30}}
                      isOn={categoryStatus}
                      myKey={'turnOn' + Math.random()}
                      handleToggle={() => updateIdeaCategoryStatus(category, categoryStatus)}
                      onColor="#79de75"
                      title="On/Off"
                  />
                </div>

                <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
                    <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>{categoryNameEnglish?categoryNameEnglish:"New Category"}</h6>
                </div>

                { categoryIsOpen && <ArrowUpIcon onClick={() => setCategoryIsOpen(false)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon> }
                { !categoryIsOpen && <ArrowDownIcon onClick={() => setCategoryIsOpen(true)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowDownIcon>}
              
              </div>
              { categoryIsOpen &&
              <div style={{backgroundColor: '#F6F6F6',}}>
               <EditCategoryForm refreshIdea={updateCategories} categoryData={category} canSubmit={canSubmit} setFinishedSaving={() => setFinished()} ideaStage={ideaStage} changeStatus={(status) => changeBtnStatus(status)} changeContinueStatus={(status) => changeContinueBtnStatus(status)} changeIdeaStage={() => this.changeIdeaStage()}/>
              </div>
              }
      </Col>
    </Row>
  )}

CategoryItem.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

CategoryItem.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(CategoryItem);
