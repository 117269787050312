import Parse from 'parse';
import React from "react";
import "./LocalStyles/CategorySelectStyle.css";

import { withTranslation } from 'react-i18next';
import { fetchTeam } from '../../services/tableService';

import Select from 'react-select';

const selectedColor = '#157ffb';//'#ff9012';

class ExecutionSelectNew extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data:[],
            options:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            selectedPerson:this.props.selectedVal,
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
          this.handleSelectedPersonChangeMulti = this.handleSelectedPersonChangeMulti.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    async fetchCategoryData() {
        try {
          // Fetch team data using the provided fetchTeam function
          const results = await fetchTeam();
         
      
          // Assuming the API returns an array of users similar to the Parse query results
          const options = results.map(result => ({
            value: result, 
            label: result.fullName, 
          }));
      
          const coaches = results.filter(result => result.isCoach === true).map(result => ({
            value: result, 
            label: result.fullName, 
          }));
      
          // Update the component's state with the fetched data
          this.setState({
            data: results,
            coaches: coaches,
            options: options
          });
      
          // Optional: Set a responsible user if needed, uncomment and adapt if required
          // this.props.setResponsible(results[0], -1);
      
        } catch (error) {
          // Handle the error by setting an empty state and logging the error
          console.error('Error fetching category data:', error);
          this.setState({
            data: []
          });
        }
      }
      

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad', 
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            })
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2){
            this.setState({
                selectionValue: 'Calidad', 
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            })
             this.props.setFilter('solucion');
        } 
    }

    handleSelectedPersonChange(event) {
        this.props.setResponsible(event, 0);
    }

    handleSelectedPersonChangeMulti(event) {
        if (event && event.length <= 3) {
            this.props.setResponsible(event, 0);
        } else if (!event) {
            this.props.setResponsible('', 0);
        }
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    render() {
        const {  options, coaches } = this.state;
        const { selectedVal } = this.props;
        const { t } = this.props;
        
        var filtered = ''
        var myValue = ''
        if (selectedVal) {
            filtered = options.filter(row => row.email === selectedVal.label);
            if (filtered.length > 0) {
                myValue = filtered[0]
            }
        }
       
        return(
            this.props.evalType=="execution1" ?
                    <div  style={{width: '100%'}}>
                        <Select
                            value={myValue}
                            onChange={this.handleSelectedPersonChange}
                            options={options}
                            placeholder={'Choose employee(s)'}
                            clearable={false}
                            className="insideFont"
                        />
                    </div>
            :
            (this.props.evalType=="execution" ?
                    <div  style={{width: '100%'}}>
                        <Select
                            value={myValue.value}
                            onChange={this.handleSelectedPersonChange}
                            options={options}
                            placeholder={'Choose employee(s)'}
                            clearable={false}
                            className="insideFont"
                        />
                    </div>
            :
                <div  style={{minWidth: 250}}>
                    <Select
                        value={selectedVal}
                        onChange={this.handleSelectedPersonChangeMulti}
                        options={coaches}
                        placeholder={t('Choose up to 3 coach(s)')}
                        className="insideFont"
                        isMulti
                    />
                </div>
            ))
    }
}

export default withTranslation()(ExecutionSelectNew);

const myStyles = {
    container: {
        marginRight: 10,
        marginLeft: 10
    }
  }