import Parse from 'parse';
import React from "react";

import "./LocalStyles/CategorySelectStyle.css";


import { withTranslation } from 'react-i18next';

import Papa from 'papaparse';


import XLSX from 'xlsx';

const selectedColor = '#157ffb';//'#ff9012';

class SettingsActionButtons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            selectedPerson:this.props.selectedVal,
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    fetchCategoryData() {
        const className = "IdeaDepartment";
  
        var ItemClass = Parse.Object.extend(className);
        var query = new Parse.Query(ItemClass);
        query.limit(1000)
        query.ascending("firstName").find()
        .then((results) => {
            const options = []
            var coaches = []


            // if (this.props.type == 'Committee') {
            //     options.push({"value":'All Departments', "label":'All Departments'})
            // }
            
            results.map((result, idx) => options.push({"value":result, "label":result.get("itemNameTrans").en}))
               
            
            
            this.setState({
                data: results, 
                options: options
            });
            // this.props.setResponsible(results[0], -1);
        }, (error) => {
            this.setState({
                data: []
            });
          // The object was not retrieved successfully.
          // error is a Parse.Error with an error code and message.
        });
      }

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad', 
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            })
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2){
            this.setState({
                selectionValue: 'Calidad', 
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            })
             this.props.setFilter('solucion');
        } 
    }

    handleSelectedPersonChange(event, action) {
        const {data} = this.state;
        // const idx = event.target.value;
         
        
        var filterValue = event
         
        if (action.action == 'select-option') {
            
            filterValue = event.filter((v, i) =>  !v.label.includes('All '))
        }

        if (action.action == 'remove-value') {
            
            filterValue = this.props.selectedVal.filter((v, i) => v.label !== action.removedValue.label);
        }
        // this.setState({filterValue: filterValue})
        this.props.setResponsible(filterValue, 0);
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    downloadCsvData() {
        const jsonData = this.props.dataCsv
        const csv = Papa.unparse(jsonData);
        
        // Transform CSV to Blob and Download it
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "data.csv";
        link.click();

        
    }


    downloadTemplate() {
        const fileUrl = this.props.templateFile;
      
        fetch(fileUrl)
          .then(response => response.text())
          .then(csvText => {
            const csvData = Papa.parse(csvText, { header: true }).data;

            const data = csvData.map(row => {
                row['Last Name'] = { v: row['Last Name'] };
                row['First Name'] = { v: row['First Name'], s: { fill: {
                    type: 'pattern',
                    pattern:'solid',
                    patternType: "solid", // none / solid
                    fgColor: {rgb: "FF000000"},
                    bgColor: {rgb: "FFFFFFFF"}
                    }, } };
                
              return row;
            });
            
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const xlsxFile = XLSX.write(workbook, { type: 'binary' });
            const blob = new Blob([this.s2ab(xlsxFile)], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'murmuratto_data_template.xlsx';
            a.click();
          })
          .catch(error => {
            console.error(error);
          });
      }
      
      s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      }
      

    toggleUploadTooltip() {
        this.setState({showUploadTooltip: !this.state.showUploadTooltip})
    }

    toggleDownloadTooltip() {
        this.setState({showDownloadTooltip: !this.state.showDownloadTooltip})
    }

    toggleDownloadTemplateTooltip() {
        this.setState({showDownloadTemplateTooltip: !this.state.showDownloadTemplateTooltip})
    }

    render() {
        const { selectedPerson, data, options, coaches } = this.state;
        const { selectedVal, t, size } = this.props;
        const finalSize = size?size: 30
        return(
            // <Container className="ml-auto">
            //     <Row className="ml-auto">
            //         <Col md="12" className="mx-1">
            //         <DownloadData
            //             id="DownloadButton"
            //             onClick={() => this.props.onPress()}
            //             className="functionalButton ml-auto d-block"
            //             style={{
            //                 minWidth: finalSize,
            //                 maxWidth: finalSize,
            //                 minHeight: finalSize,
            //                 maxHeight: finalSize,
            //             }}
            //             fill={this.props.color ? this.props.color : ""}
            //             />

            //             <Tooltip
            //             open={this.state.showDownloadTooltip}
            //             target={"#DownloadButton"}
            //             id={"DownloadButton"}
            //             toggle={() => {this.toggleDownloadTooltip()}}
            //             >
            //                 Download data. 
            //             </Tooltip>
            //         </Col>
                    
            //     </Row>
            // </Container>
            <></>
        )
    }
}

export default withTranslation()(SettingsActionButtons);

const myStyles = {
    container: {
        marginRight: 10,
        marginLeft: 10
    }
  }