import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// i18n
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';


import * as serviceWorker from './serviceWorker';

const allowedLanguages = ['en', 'es'];
const storageLanguage = localStorage.getItem('language')

if (!storageLanguage) {
  localStorage.setItem('language', 'en');
}

const defaultLng = 'en';
let lng = defaultLng;

if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
  lng = storageLanguage;
}



i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  react: {
      useSuspense: false,
  },
  resources: {
    en: {

      translation: {
  
        "IDEA": "IDEA",

        "BACK": "Back",
  
        "SUBMIT_IDEA": "Submit",
  
        "SUBMIT_IDEA_Sub": "Submit IDEA information and details",

        "IDEA_INFO": "IDEA Info",
        "VERIFICATION_SUBMIT":"Your evaluation has been submitted. Thank you!",
  
        "SUBMIT_IDEA_Proponent": "Proponent",
  
        "SUBMIT_IDEA_Department": "Department to benefit",

        "SUBMIT_IDEA_Department_Benefitted": "Impacted Department",
  
        "SUBMIT_IDEA_DepartmentSelect": "Choose a department",
  
        "SUBMIT_IDEA_SELECT": "Select a department",
  
        "SUBMIT_IDEA_Date": "Date",
  
        "SUBMIT_IDEA_Team": "Team",

        "CHOOSE_FILE":"Choose a file",

        "RESET_PASSWORD":"Reset password",

        "SubmittalDetails": "Submittal",
        "ImplementationDetails": "Implementation",
        "VerificationDetails": "Verification",

        "LOGOUT":"Logout",

        "IDEA_COMPLETADA": "IMPLEMENTED IDEA", 

        "IDEA_COMPLETADA_MESSAGE": "Add your IDEA implementation results.",

        "TITLE":"Title",

        "DESCRIPTION":"Description",

        "FILE":"File",

        "Verify Workflow":"Workflow",
        "Recognition Workflow":"Workflow",
        "Verify History Workflow Instruction":"Click on an opportunity to view all the details used to verify its impact and review your analysis.",
        "Verify Inbox Workflow Instruction":"Your team did the heavy lifting, now is time for you to quantify their success.",

        "VIEW_FILE":"View File",

        "EVALUATION":"Evaluation",

        "COMMENTS":"Comments",

        "RESPONSABILITY":"Responsability",

        "WRITE_COMMENTS":"Write comments",

        "ASSIGN_RESPONSIBLE_EXECUTION":"Assign responsible for execution",

        "ASSIGN_COACH":"Assign a coach",

        "IdeaDetails": "Opportunity Details",

        "PHOTOS_BEFORE_AFTER":"Photos (Before and After)",

        "SUBMIT_BEFORE_AFTER": "Submit before and after picture.",

        "MEJORAS_IMPLANTADAS": "Implementations",

        "RESULTS": "Results",

        'AVG_CONTRIBUTION_EMPLOYEE': 'Average Contributions / Employee',

        "BEFORE": "Before",

        "AFTER": "After",
        'PARTICIPATION_BY_SITE': "Employee Participation",

        "NO_FILE_FOUND": "No file found.",

        "RESPONSIBLE_NAME":"Responsible Name",

        "CATEGORY_INFORMATION":"Category Information",
        "SOLUTION_INFORMATION":"Idea Information",

        "BASIC_INFORMATION":"Basic Information",

        "IDEA_INNOVATION":"Is this an innovation idea?",
  
        "IDEA_PROBLEM":"Does this idea solve a problem?",
  
        "SUBMIT_IDEA_TeamPlaceholder": "Team Name",
  
        "SUBMIT_IDEA_Category": "Category",
  
        "SUBMIT_IDEA_IdeaTitle": "Opportunity Title",
        "SUBMIT_IDEA_IdeaSubTitle": "Type a short title",

  
        "SUBMIT_IDEA_TitlePlaceholder": "Briefly describe the goal of your opportunity",
  
        "SAFETY_CATEGORY":"Safety",
  
        "QUALITY_CATEGORY":"Quality",
  
        "IMMEDIATEACTION_CATEGORY":"Immediate Action",
  
        "PRODUCTIVITY_CATEGORY":"Productivity",
  
        "SUBMIT_IDEA_IdeaDescription": "Description",
  
        "SUBMIT_IDEA_DescriptionPlaceholder": "Help us understand your idea and its benefits by describing it here!",
  
        "SUBMIT_IDEA_UploadArchive": "Upload Archive",
  
        "SUBMIT_IDEA_RemainingCharacters": "remaining characters.",
  
        "SUBMIT_IDEA_REQUIRED_QUESTION": "*This question is required",
  
        "TEAM":"Team",
  
        "ANSWER":"Type answer here",
  
        "SUBMIT":"Submit",
  
        "SEARCH":"Search",
  
        "CONTINUE":"Continue",
  
       "ENTRIES":"Entries",
  
        "HISTORY":"History",
  
        // Administration
  
        "ADMINISTRATION_TITLE": "ADMINISTRATION",
  
        "ADMINISTRATION_SUBTITLE": "CONFIGURATION AND",

        // EVALUATION
        "EVAL_IDEA_TITLE":"Evaluate",

        "EVAL_IDEA_TITLE_PROJECT":"Evaluate PROJECT",

        "EVAL_IDEA_VERIFICATION":"Evaluation Questions",

        "EVAL_IDEA_BENEFITS":"Were the goals of the opportunity met?",

        "EVAL_IDEA_IMPROVEMENT":"Did the opportunity improve the process or business?",

        "EVAL_IDEA_FINRES":"What was the financial impact?",

        "EVAL_IDEA_IMPACT":"Can this opportunity be replicated in another are of the business?",

        "EVAL_IDEA_RECOG":"Does this Opportunity get recognition?",

        "EVAL_IDEA_YES":"Yes",

        "EVAL_IDEA_NO":"No",

        "EVAL_IDEA_BUTTON":"Submit",

        "REWARDRECOG_IDEA_BUTTON":"Submit",

         // RECOGNITION
         "RECOG_IDEA_TITLE":"Recognition Inbox",

         "RECOG_IDEA_RECOGNITION":"Evaluation Questions",

         "RECOG_IDEA_EMPLOYEES":"Select Employees to Recognize",

         "RECOG_IDEA_DATE":"Target Recognition Date",

         "RECOG_IDEA_TYPE":"Type of Recognition",

         "RECOG_IDEA_POINTS":"Economic Reward (If Applicable)",

         "RECOG_IDEA_LOW":"Low",
         "RECOG_IDEA_MODERATE":"Moderate",
         "RECOG_IDEA_HIGH":"High",
  
        // Evaluation committee
  
        "EVAL_COMMITTEE_NEW": "New Evaluation Committee",
  
        "EVAL_COMMITTEE": "Evaluation Committee",
  
        "EVAL_COMMITTEE_PLACEHOLDER": "Evaluation committee name",
  
        "EVAL_COMMITTEE_MEMBER": "Members",
  
        "EVAL_COMMITTEE_EMAIL_PLACEHOLDER": "Email",
  
        "EVAL_COMMITTEE_NAME_PLACEHOLDER": "Full name",
  
        "EVAL_COMMITTEE_MEMBER_BTN": "Add members",
  
        "EVAL_COMMITTEE_TYPE_TITLE": "This committee will evaluate by: ",
  
        "EVAL_COMMITTEE_AREA": "Area of Responsibility",
  
        "EVAL_COMMITTEE_CLASSIFICATION": "Idea Classification",
  
        "EVAL_COMMITTEE_CREATE_BTN": "Create Committee",
  
        "EVAL_COMMITTEE_DEPARTMENT": "Department",
  
        "EVAL_COMMITTEE_CATEGORY": "Category",
  
        "EVAL_COMMITTEES": "Evaluation Committees",
  
        "CATEGORIES": "CATEGORIES",
        "CHART_CATEGORIES": "Categories",
  
        "DEPARTMENTS": "DEPARTMENTS",
  
        "NEW_QUESTION": "New Question",
  
        "QUESTION_ENGLISH": "Question (English)",
  
        "QUESTION_SPANISH": "Question (Spanish)",

        "QUESTION_OPEN": "Open Ended Question (Field)",
        "OPEN":"Open",
  
        "QUESTION_PLACEHOLDER": "Write your question here...",

        "QUESTION_PLACEHOLDER_SPANISH": "Write your question in spanish here...",
  
        "QUESTION_CATEGORY": "Category",
  
        "QUESTION_REQUIRED": "Required Question",
  
        "QUESTION_BTN": "Submit Question",
  
        "QUESTION_LIST": "Question List",
  
        "QUESTION": "Question",
  
        "CATEGORY": "Category",
        "TYPE": "Type",
  
        "STATUS": "Status",
  
        "ACTIONS": "Actions",
  
        "SELECT_MEMBERS":"Select Members",
        "SELECT_EMPLOYEES":"Select Employees",
        "SHOW":"SHOW",
        "ROWS":"ROWS",
  
        //Idea Filter Select
  
        "IDEA_FILTER_INNOVATION":"Is this an innovation idea?",
  
        "IDEA_FILTER_QUESTION":"Does this idea solve a problem?",
  
        //SEARCH IDEA
  
        "IDEA_UPDATE_TITLE":"Update Idea",
  
        "IDEA_UPDATE_SUBTITLE":"IDEA",
  
        "IDEA_UPDATE_NUM":"Opportunity No.",
  
        "IDEA_UPDATE_TIT":"Title",
  
        "IDEA_UPDATE_CAT":"Category",
  
        "IDEA_UPDATE_PROG":"Progress",
  
        "IDEA_UPDATE_STATUS":"Status",
  
        "IDEA_UPDATE_VIEW":"View / Edit",
  
        // MANAGE IDEA
  
        "IDEA_MANAGE_TITLE":"Manage Idea",
  
        "IDEA_MANAGE_SUBTITLE":"IDEA",
  
        "IDEA_MANAGE_OVERVIEW":"Idea Overview",
  
        "IDEA_MANAGE_PROPONENT":"Proponent",
  
        "IDEA_MANAGE_SUBMITTED":"Submitted",
  
        "IDEA_MANAGE_DEPARTMENT":"Department",
  
        "IDEA_MANAGE_CATEGORY":"Category",
  
        "IDEA_MANAGE_ASSIGNED":"Assigned to",

        "IDEA_MANAGE_COACH":"Coach",
  
        "IDEA_MANAGE_STATUS":"Current Status",
  
        "IDEA_MANAGE_PROGRESS":"Current Progress",
  
       "IDEA_MANAGE_ADD_PROGRESS":"Update Progress",

       "IDEA_EVALUATED_ASSIGNED":"Idea was evaluated and assigned",

       "IDEA_EXECUTION_STARTED":"Execution started",

       "IDEA_IMPLEMENTED":"Implemented",

       "IDEA_IMPLEMENTED_BENEFITS":"Implemented with benefits",

       "WRITE_NEW_COMMENT":"Write a New Comment",

       "WRITE_NEW_COMMENT_DESCRIPTION":"Write a new comment about the IDEA",

        "IDEA_MANAGE_BTN_HOLD":"Pause Idea",
  
        "IDEA_MANAGE_BTN_UPDATE":"Update Idea",
  
        // MENU
        "IDEA_VERIFICATION":"Verify Inbox",

  
        "MENU_IDEA":"Idea",
  
        "MENU_SUBMIT_IDEA":"Submit",
  
        "MENU_SEARCH_IDEA":"Search Idea",
  
        "MENU_MANAGE_IDEA":"Manage Ideas",
  
        "MENU_IDEA_VERIFICATION":"Idea Verification",
  
        "MENU_RECOGNITION":"Rewards & Recognition",
  
        "MENU_REPORTS":"Reports",

        "MENU_INTELLIGENCE":"Intelligence",
  
        "MENU_ADMINISTRATION":"Administration",
  
        "Verified":"Verified",

        "MENU_INCOMING_IDEAS":"Inbox",
  
        "MENU_INCOMING_RECOGNITION":"Incoming Recognition",
  
        "MENU_HISTORY":"History",

        "MENU_CATS_DEPTS": "Categories/Departments",

        "MENU_USERS": "Users",

        "MENU_COMITES": "Committees",

        "MENU_QUESTIONS": "Questions",

        "IMPORT_CATS_DEPTS": "Import Categories and Departments",

        "IMPORT_QUESTIONS": "Import Questions",
        "RESET_IDEAS":"Reset Ideas",
  
        // REPORTS
  
        "REPORTS": "Reports",
        "REPORTS_DASHBOARD": "Reports Dashboard",
  
        "VIEW_REPORTS": "View Reports",
  
        "SUBMITTED_IDEAS": "Opportunities Submitted",
  
        "COMPLETED_IDEAS": "Opportunities Completed",
  
        "IDEAS_ON_PROGRESS": "Opportunities in Progress",
  
        "PENDING_FOR_EVALUATION": "Opportunities Pending Evaluation",
  
        "IDEAS_BY_DEPARTMENT": "Ideas by Department",
  
        "PROGRESS_BY_CATEGORY": "Progress by Category",
        
        "EXPECTED_EARNINGS_BY_CATEGORY": "Expected Earnings by Category",

        "ACTUAL_EARNINGS_BY_CATEGORY": "Actual Earnings by Category",
  
        // CONFIGURATION AND ADMINISTRATION
  
        "CONFIGURATION_AND": "CONFIGURATION AND",
  
        "ADMINISTRATION": "ADMINISTRATION",
  
        "NEW_EVAL_COMMITTEE": "New Evaluation Committee",
  
        "EVAL_COMMITTEE_NAME": "Evaluation committee name",
  
        "EVAL_COMMITTEE_MEMBERS": "Members",
  
        "EVAL_COMMITTEE_EMAIL": "Email",
  
        "EVAL_COMMITTEE_NAME": "Full name",
  
        "EVAL_COMMITTEE_ADD_MEMBERS": "Add members",
        "EVAL_COMMITTEE_ADD":"Add new members",
  
        "EVAL_COMMITTEE_EVALUATE_BY": "This committee will evaluate by:",
  
        "EVAL_COMMITTEE_CREATE_COMMITTEE": "Create Committee",
  
        "EVAL_COMMITTEE_AREA_RESPONSIBILITY": "Area of Responsibility",
  
        "EVAL_COMMITTEE_IDEA_CLASSIFICATION": "Area of Responsibility",
  
        // MANAGE IDEA ENTRANTE
  
        "IDEA_MANAGE_FILTERS":"Filters",
  
        "IDEA_MANAGE_SUBTITLE":"IDEA",
  
        "IDEA_MANAGE_OPEN":"View Opportunity",
  
        "IDEA_MANAGE_NUM":"Opportunity No.",
  
        "IDEA_MANAGE_TITLE":"Title",
  
        "IDEA_MANAGE_CAT":"Category",
  
        "IDEA_MANAGE_CATS":"Categories",
        "IDEA_MANAGE_COM":"Committee",

        "IDEA_MANAGE_DEPTS":"Departments",

  
        "IDEA_MANAGE_TYPES":"Opportunity Type",
        "APPROVAL_PERCENTAGE":'Approval Percentage',
        "CHART_EMPLOYEE_PARTICIPATION":'Employee Participation',
  
        "IDEA_MANAGE_PROG":"Progress",
  
        "IDEA_MANAGE_PROP":"Proponent",
  
        "IDEA_MANAGE_STATUS":"Status",
  
        "IDEA_MANAGE_EVALUATE":"Evaluate",
  
        // HISTORY
  
        "TRANSACTION_HISTORY": "Transaction History",
  
        "TRANSACTION_DESCRIPTION": "Description",
  
        "TRANSACTION_CATEGORY": "Category",
  
        "TRANSACTION_PROPONENT":"Proponent",
  
        "TRANSACTION_STATUS": "Status",
  
        "TRANSACTION_ACTIONS":"Actions",
  
        "TRANSACTION_EVALUATED": "Evaluation History",
  
        "TRANSACTION_MANAGE":"Evaluate",
  
        "TRANSACTION_VERIFIED":"Verification History",

        "RecognitionDetails":"Recognition",
  
        "TRANSACTION_RECOGNITION":"RECOGNITIONS",
  
        "TRANSACTION_VIEWRECOGNITION":"Recognition History",
  
        "TRANSACTION_EMPLOYEE_NAME":"Employee(s) Name",

        "ECONOMIC_REWARD":"Economic Reward",

        "DATE_VERIFIED":"Date Verified",
  
        "TRANSACTION_EMPLOYEE_NUM":"Employee Number",
  
        "TRANSACTION_RECOGNITION_TYPE":"Type",
  
        "TRANSACTION_EMPLOYEE_POINTS":"Points",
  
        'NUMBER_MEMBERS':"Number of members:",
  
        'EVALUATING_BY':"Evaluating by: ",
        'CONTRIBUTIONS_PER_EMPLOYEE':'Contribution Activity',
  
        'ENGLISH':"English",
  
        'SPANISH':"Spanish",
  
        // EVALUATE
  
        "EVALUATE_IDEA":"Evaluate",
  
        "REVIEW_IDEA":"IDEA Review",
  
        "EVALUATION_IDEA":"Evaluation",
  
        "NEEDS_INFO_IDEA":"IDEA Needs More Information",

        "NEEDS_INFO_PROJECT":"Project Needs More Information",
  
        "HOLD_IDEA":"IDEA on Hold",

        "HOLD_PROJECT":"Project on Hold",
  
        "NOT_PURSUED_IDEA":"Not Pursued",

        "NOT_PURSUED_PROJECT":"Not Pursued",
        "Select Departments":"Seleccione Department(s)",

        "ENTER_LAST_NAME":"Last Name",
  
        "JUST_DO_IDEA":"Just Do It",

        "JUST_DO_PROJECT":"Just Do It Project",
  
        "PROYECT_IDEA":"IDEA Project",
  
        "OTHER_COMMITTEE_IDEA":"Other Committee",
  
        "COMMENTS_IDEA":"Comments",
  
        "WRITE_COMMENTS_IDEA":"Write comments",
  
        "ASSIGN_RESPONSIBLE_IDEA":"Assign responsible for execution",
  
        "RESPONSIBLE_NAME_IDEA":"Responsible Name",
  
        "ASSIGN_COACH_IDEA":"Assign IDEA Coach",

        "ADDITIONAL_COMMENTS":"Additional Comments",

        "REPLICATED_AREA_BUSINESS":"Can this be replicated in another area of the business?:",
  
        "RESET": "Reset",

        "You are viewing ideas as a":"You are viewing opportunities as a",
        "Upcoming deadlines and events":"Upcoming deadlines and events",
        "Ideas under Implementation":"Opportunities under Implementation",
  
        "RESET_MSG": "This will delete all ideas. Do you want to proceed?",

        "USER_LIST": "User List",
        "Idea Implementation Status":"Opportunity Status",

        "ENTER_NAME":"Name",
        "title":"Title",
        "type":"Type",

        "FIRST_NAME_PLACEHOLDER":"First Name",
  
        "LAST_NAME_PLACEHOLDER":"Last Name",

        "EMAIL":"Email",

        "USERID":"Userid",
        "USERID_PLACEHOLDER":"Enter your Userid",

        "EMAIL_PLACEHOLDER":"jose.feli@lasalle-group.com",

        "USER_TYPE":"Role",

        "ADD_USER_BTN": "Add User",

        "NEW_USER": "New User",

        // Reports
        "IDEAS_BY_PROGRESS":"Ideas by Status",
        "DOWNLOAD_DATA": "Download Data",

        // Filters
        "FILTER_INNOVATION": "Innovation",
        "FILTER_PROBLEM_F": "Problem Solving",
        "FILTER_IMPROVEMENT": "Continuous Improvement",
        "FILTER_ALL": "All",
        "FILTER_SUBMITTED": "Submitted",
        "FILTER_DONE": "Done",

        "EVAL_COMMITTEE_MANAGE": "Management",
        // Return
        "SUBMIT_IDEA_ReturnTitle":"Expected Savings (Monthly)",
        "SUBMIT_IDEA_ReturnPlaceholder":"E.g. 1000",
        "Thisopportunitywassubmittedby" : "This opportunity was submitted by:",

        "DELETE_FILE": "Delete File",
        "HELP":"Help",
        'cretor':'Creator',
        "SUBMIT_IDEA_Money":"Only numbers are accepted. Please do not use commas or other invalid characters.",
        "COULD_BE_REPLICATED":"Could it be replicated in other areas or sites?",
        "SAVINGS_IN_LABOR":"Savings in labor (hrs)",
        "NUMBER_HOURS_SAVED":"Number of hours saved...",
        "COST":"Cost (USD)",
        "COST_SAVED":"How much money it saved...",
        "INVENTORY":"Inventory (USD)",
        "INVENTORY_DESC":"Inventory...",
        "OEE_INCREASE":"OEE (Increase %)",
        "OEE_INCREASE_MUCH":"By how much did OEE increase in %...",
        "LEAD_TIME":"Lead Time (HRS)",
        "LEAD_TIME_HOURS":"Lead Time in hours...",
        "RECOGNITION_ATTACHMENT":"Recognition Attachment",

        // New Translation
        "Opportunity": "Opportunity",
        "OPPORTUNITY_STATUS": "Employee Opportunities - Workflow Stages",
        "WELCOME_BACK":"Welcome back, ",
        "IDEA_SELECT_IDEA_TYPE":"Opportunity > Select Opportunity Type",
        "Opportunity >":"Opportunity >",
        "OPPORTUNITY_TYPE":"Opportunity Type",
        "Choose how to contribute!":"Choose how to contribute.",
        "Selected Category":"Selected Category:",
        "Show transferred opportunities":"Show transferred opportunities",
        "Idea Title*":"Opportunity Title*",
        "Idea Description*":"Opportunity Description*",
        "Add team members and attachments":"Add team members and attachments",
        "MAXIMUM_FILE_SIZE":"Maximum file size",
        "Add an attachment":"Attachment",
        'Choose up to 3 coach(s)': 'Choose up to 3 coach(es)',
        "Add a team":"Add team members",
        "Click to share your idea":"Click to share your idea",
        "Help us understand your idea and its benefits by describint it here!":"Help us understand your idea and its benefits by describint it here!",
        "Type [First Name] [Last Name]":"Choose a user or type their name",
        "APPROVE_MSG":"Choose this option to start implementing this idea right away.",
        "DO_NOT_PURSUE_MSG":"Choose this option for ideas that are not aligned with business needs or idea approval criteria.",
        "SAVE_FOR_LATER_MSG":"Choose this option for good ideas that should be implemented in the near future.",
        "REQUEST_INFORMATION_MSG":"Choose this option if you need additional information to evaluate this idea. Type your questions in the comment section below. We’ll deliver it to the right person!",
        "PROJECT_IDEA_MSG":"Choose this option for ideas that need additional budget and approvals. We’ll send this to the PMO team in your organization!",
        "TRANSFER_COMMITTEE_MSG":"Not the right person to evaluate this? Let us help you by choosing this option and typing the right department in the comment section below.",
        "RESPONSE_TIME_MSG":"People love speedy responses. Respond within 14 days to keep your team engaged.",
        "Which department is this idea for?":"Which department is this opportunity for?",
        "Idea Details":" > Opportunity Details",
        "YOUR_IDEA_HISTORY":"Opportunity History",
        "Your Ideas at Work": "Implement",
        "User Dashboard": "User Dashboard",
        "Your Submitted Contributions":"Your Submitted \nOpportunities",
        'Evaluation Desicion': 'Evaluation Desicion',
        "Your Approved Contributions":"Your Approved \nOpportunities",
        "Your Team's Contributions":"Your Department's \nOpportunities",
        "Ideas Being Implemented":"Opportunities Being \nImplemented",
        "Ideas by Department":"Opportunities by Department",
        "Ideas by Type":"Opportunities by Type",
        "KEEP_YOUR_TEAM_UPDATED": "Opportunity Overview",
        "IMPLEMENTATION STARTED": "Implementation Started",
        "Estimate economic/output impact":"Forecast economic impact",
        'Review Evaluation Criteria':'Review Evaluation Criteria',
        "IMPLEMENTED": "Implemented",
        "Choose_how_to_proceed":"Choose how to proceed: ",
        "Never lose track of the opportunities under your umbrella.":"Never lose track of the opportunities under your umbrella.",
        "OPPORTUNITY_EVALUATED": "Opportunity Evaluated",
        "UPDATE_OPPORTUNITY_STATUS": "Update Status",
        "WHAT_CHANGED": "What Changed?",
        "ENTER_CHANGES_HERE": "Enter changes here...",
        "OPPORTUNITY_IMPLEMENTATION_SUMMARY": "Opportunity Implementation Summary",
        "IN_ORDER_TO_GET_YOUR_OPPORTUNITY": "In order to get your opportunity to the next stage, we need additional information.",
        "CANCEL": "Cancel",
        "Select Categories":"Select Category(ies)",
        "Select Type":"Select Type(s)",
        "Select Departments":"Select Department(s)",
        "FINISH_OPPORTUNITY": "Finish Opportunity",
        "ANALYSIS_&_CONCLUSION": "Analysis & Conclusion",
        "OPPORTUNITY_CREATOR": "Opportunity Creator",
        "START_DATE": "Start Date",
        "TARGET_COMPLETION_DATE": "Target Completion Date",
        "SELECT_A_DATE": "Select a Date",
        "Thisopportunitywassubmittedby":"This opportunity was submitted by:",
        "Subject-MatterExpertComments":"Subject-Matter Expert Comments:",
        // Add below here
        "OPPORTUNITY_OWNER": "Opportunity Owner",
        "OPPORTUNITY_COACH": "Opporutnity Coach",
        'creator': 'Creator',
        "REASSIGN_OPPORUTNITY": "Reassign Opportunity",
        "REASSIGN":"Reassign",
        "EVALUATED": 'Evaluated',
        "VIEW_OPPORTUNITY": "View Opportunity",
        "SAVE_NEW_OWNER": "Save New Owner",
        "TEAM_MEMBERS": "Team Members",
        "EVALUATION_COMMENTS": "Evaluation Comments",
        "EVALUATION_ATTACHMENT": "Evaluation Attachment",
        "VIEW_ATTACHMENT": "View Attachment",
        'Keep your team updated!':'Opportunity Overview',
        // Needs been added to Pages
        "OPPORTUNITIES_SUBMITTED":"Opportunities Submitted",
        "EVALUATED_OPPORTUNITIES": "Opportunities Evaluated ",
        "COMPLETED_OPPORTUNITIES": "Opportunities Completed",
        "CLOSE_OPPORTUNITIES": "Opportunities Closed",
        "OPPORTUNITIES_PENDING_EVALUATION": "Opportunities Pending Evaluation",
        "OPPORTUNITIES_UNDER_IMPLEMENTATION": "Opportunities in Progress",
        "EMPLOYEE_OPPORTUNITY_WORKFLOW_STAGE": "Employee Opportunities - Workflow Stages",
        'IMPLEMENTATION_LEADER':'Implementation Leader',
        "TRENDING_TOPICS": "Trending Topics",
        "QUANTITY_OF_OPPORTUNITIES": "Quantity of Opportunties",
        "OPPORTUNITIES_BY_CATEGORY_PERCENTAGE": "Opportunities by Category",
        "OPPORTUNITIES_BY_CATEGORY_QUANTITY": "Opportunities by Category",
        "OPPORTUNITIES_BY_TYPE_PERCENTAGE": "Opportunities by Type",
        "OPPORTUNITIES_BY_TYPE_QUANTITY": "Opportunities by Type",
        "BUSINESS_IMPACT_FORECAST": "Business Impact",
        "BUSINESS_IMPACT_VERIFIED": "Business Impact",
        "BUSINESS_IMPACT": "Business Impact",
        "EMPLOYEES_WITH_MOST_OPPORTUNITIES": "Employees with most Opportunities",
        "DEPARTMENTS_RECEIVING_MOST_OPPORTUNITIES": "Departments receiving most Opportunities",
        "ACTUAL": "(Verified)",
        "ESTIMADO": "(Forecasted)",
        "QUANTITY": "(Quantity)",
        "PERCENTAGE": "(Percentage)",
        "category":"Category",
        // Sheet 3
        "WORKFLOW": "Workflow",
        // Sheet 4
        "CATEGORY_ENDED": "Please review the Category Duration options in this opportunity and try again."

      },
    },
  
    es: {
  
      translation: {
        "CATEGORY_ENDED": "Por favor revisar la fecha de culminación de la categoría y volver a intentar.",
        'Keep your team updated!':'Resumen de Oportunidad',
        "Your Submitted Contributions":"Tus \nOportunidades Sometidas",
        "Your Approved Contributions":"Tus Oportunidades \nAprobadas",
        "Your Team's Contributions":"Oportunidades de \ntu Departamento",
        "Ideas Being Implemented":"Ideas en \nProceso",
        "THANK_YOU_FOR_YOUR_CONTRIBUTION": "¡Gracias por <em>tu</em> participación!",
        "Ideas by Department":"Ideas por Departmento (%)",
        "Ideas by Type":"Ideas por Tipo (%)",
        "Your Ideas at Work": "Implementación",
        "User Dashboard": "Panel de usuario",

        "YOUR_IDEA_HISTORY":"Tu historial de ideas",
 
        "SUBMIT_IDEA": "Someter Idea",

        "BACK": "Regresar",
 
        "SUBMIT_IDEA_Sub": "Somete información y detalles de la IDEA.",

        "IDEA_INFO": "Información de IDEA",
 
        "SUBMIT_IDEA_Proponent": "Proponente",
 
        "SUBMIT_IDEA_Department": "Departmento",
        "SUBMIT_IDEA_Department_Benefitted": "Departamento Beneficiado",
 
        "SUBMIT_IDEA_Team": "Equipo",
 
        "SUBMIT_IDEA_TeamPlaceholder": "Nombre del Equipo",
 
        "SUBMIT_IDEA_DepartmentSelect": "Escoja un departamento",
 
        "SUBMIT_IDEA_Date": "Fecha de Sumisión",
 
        "RESET_PASSWORD":"Cambiar contraseña",

        "HELP":"Ayuda",

        "TITLE":"Título",

        "DESCRIPTION":"Descripción",

        "FILE":"Archivo",

        "VIEW_FILE":"Ver Archivo",

        "CATEGORY_INFORMATION":"Información de Categoría",
        "SOLUTION_INFORMATION":"Información de Sobre la Idea",

        "BASIC_INFORMATION":"Información Básica",

        "LOGOUT":"Salir",

        "IDEA_COMPLETADA":  "IDEA COMPLETADA",

        "IDEA_COMPLETADA_MESSAGE": "Somete los resultados reales de la IDEA.",

        "EVALUATION":"Evaluación",

        "COMMENTS":"Comentarios",

        "RESPONSABILITY":"Responsabilidad",

        'CONTRIBUTIONS_PER_EMPLOYEE': 'Contribución Promedio de Empleado',

        "IMPORT_QUESTIONS": "Importar Preguntas",

        "RESET_IDEAS":"Reiniciar Ideas",

        "WRITE_COMMENTS":"Escribir Comentarios",

        "ASSIGN_RESPONSIBLE_EXECUTION":"Asignar responsable para ejecución",

        "ASSIGN_COACH":"Asignar Coach",

        "RESPONSIBLE_NAME":"Nombre del Responsable",
        "VERIFICATION_SUBMIT" : "Su verificacion ha sido sometida. ¡Gracias!",

        "PHOTOS_BEFORE_AFTER":"Fotos (Antes y Después)",

        "SUBMIT_BEFORE_AFTER": "Sube fotos de antes y después.",

        "MEJORAS_IMPLANTADAS": "Mejoras Implementadas",

        "RESULTS": "Resultados",

        "BEFORE": "Antes",

        "AFTER": "Después",

        "SUBMIT_IDEA_Category": "Categoría",
 
        "SUBMIT_IDEA_IdeaTitle": "Título de Oportunidad",
        "SUBMIT_IDEA_IdeaSubTitle": "Escoja un título",

        "IMPORT_CATS_DEPTS": "Importar CATEGORÍAS and Departmentos",
 
        "SUBMIT_IDEA_TitlePlaceholder": "Describa brevemente la meta de esta oportunidad",
 
        "SUBMIT_IDEA_IdeaDescription": "Descripción",
 
        "SUBMIT_IDEA_DescriptionPlaceholder": "Descripción...",
 
        "SUBMIT_IDEA_UploadArchive": "Subir Archivo",
 
        "SUBMIT_IDEA_RemainingCharacters": " caracteres restantes",

        "Recognition Workflow":"Workflow",
 
        "ADMINISTRATION_TITLE": "ADMINISTRACIÓN",
 
        "Administration_SUBTITLE": "CONFIGURACIÓN Y",
 
        "TEAM":"Equipo",
 
        // Administration
 
        "ADMINISTRATION_TITLE": "ADMINISTRACIÓN",
        "Opportunity >":"Oportunidad >",
        "MAXIMUM_FILE_SIZE":"Tamaño máximo de archivo",
 
        "ADMINISTRATION_SUBTITLE": "CONFIGURACIÓN Y",

        "SubmittalDetails": "Entrada",
        "ImplementationDetails": "Implementación",
        "VerificationDetails": "Verificación",
 
        // Evaluation committee
 
        "IDEA_VERIFICATION":"Verificar Idea",

        "IDEA_UPDATE_NUM":"No. de Oportunidad",
 
        "EVAL_COMMITTEE_NEW": "Nuevo Comité Evaluador",
 
        "EVAL_COMMITTEE": "Comité Evaluador",
 
        "EVAL_COMMITTEE_PLACEHOLDER": "Nombre del Comité Evaludor",
 
        "EVAL_COMMITTEE_MEMBER": "Miembros",
 
        "EVAL_COMMITTEE_EMAIL_PLACEHOLDER": "Email",
 
        "EVAL_COMMITTEE_NAME_PLACEHOLDER": "Nombre Completo",

        "CHOOSE_FILE":"Seleccione archivo",
        'Review Evaluation Criteria':'Criterios de Evaluación',

        "IDEA_INNOVATION":"¿Es una idea de innovación?",
 
        "IDEA_PROBLEM":"¿Soluciona un problema?",

        "EVAL_COMMITTEE_MEMBER_BTN": "Añadir miembros",
        "Thisopportunitywassubmittedby":"Esta oportunidad fue sometida por:",
 
        "EVAL_COMMITTEE_TYPE_TITLE": "Este comité evaluará por: ",
 
        "EVAL_COMMITTEE_AREA": "Area de Responsabilidad",
 
        "EVAL_COMMITTEE_CLASSIFICATION": "Clasificación de la Idea",

 
        "EVAL_COMMITTEE_CREATE_BTN": "Crear Comité",
 
        "EVAL_COMMITTEE_DEPARTMENT": "Departmento",

        "You are viewing ideas as a": "Estás viendo oportunidades como un",
        "Upcoming deadlines and events": "Próximas fechas límite y eventos",
        "Ideas under Implementation": "Oportunidades en Implementación",
 
        "EVAL_COMMITTEE_CATEGORY": "Categoría",
        "Thisopportunitywassubmittedby":"Esta oportunidad fue sometida por:",
        "Subject-MatterExpertComments":"Comentarios:",
 
        "EVAL_COMMITTEES": "Comités de Evaluación",
        "REASSIGN":"Reasignar",
        'Choose up to 3 coach(s)':'Escoja hasta 3 coaches',
 
        "SAFETY_CATEGORY":"Seguridad",
 
        "QUALITY_CATEGORY":"Calidad",
 
        "IMMEDIATEACTION_CATEGORY":"Acción Inmediata",
 
        "PRODUCTIVITY_CATEGORY":"Productividad",
 
        "SELECT_MEMBERS":"Seleccionar Miembros",
 
        "SUBMIT_IDEA_REQUIRED_QUESTION": "*Esta pregunta es requerida",
 
        "ANSWER":"Escriba comentarios aquí",
 
        "CONTINUE":"Continuar",
 
        "SUBMIT":"Someter",
 
        "SEARCH":"Buscar",
        "Estimate economic/output impact":"Estime Impacto Económico",
 
        "IDEA_UPDATE_TITLE":"Actualizar Idea",
 
        "ENTRIES":"Entradas",
 
        "HISTORY":"Historial",
 
        "DEPARTMENTS": "DEPARTMENTOS",
        'creator': 'Creador',
        'IMPLEMENTATION_LEADER':'Lider de Implementación',

        "Idea Implementation Status":"Estado de Oportunidad",

        "EVALUATED": 'Evaluated',
        "Select Departments":"Seleccione Departmento(s)",
        // Evaluation committee – Comité Evaluador
 
        "EVAL_COMMITTEE_NEW": "Nuevo Comité Evaluador",
 
         // MENU
 
         "MENU_IDEA":"Idea",
 
         "MENU_SUBMIT_IDEA":"Someter Idea",
 
         "MENU_SEARCH_IDEA":"Buscar Idea",
 
         "MENU_MANAGE_IDEA":"Manejar Ideas",
 
         "MENU_IDEA_VERIFICATION":"Verificar ideas",

         "Verify Workflow":"Workflow",
 
         "MENU_RECOGNITION":"Reconocimiento y recompensas",
 
         "MENU_REPORTS":"Reportes",

         "MENU_INTELLIGENCE":"Inteligencia",
 
         "MENU_ADMINISTRATION":"Administración",
 
         "MENU_INCOMING_IDEAS":"Inbox",
 
         "MENU_INCOMING_RECOGNITION":"Reconocimientos Entrantes",
 
         "MENU_HISTORY":"Historial",

         "MENU_CATS_DEPTS": "CATEGORÍAS/Departmentos",

        "MENU_USERS": "Usuarios",

        "MENU_COMITES": "Comites",

        "MENU_QUESTIONS": "Preguntas",
 
         // REPORTS
 
        "REPORTS": "Reportes",
        "REPORTS_DASHBOARD": "Panel de Reportes",
 
        "VIEW_REPORTS": "Ver Reportes",
 
        "SUBMITTED_IDEAS": "Oportunidades Sometidas",
 
        "COMPLETED_IDEAS": "Oportunidades Completadas",

        "REPLICATED_AREA_BUSINESS":"¿Puede replicarse en otra area del negocio?:",
 
        "IDEAS_ON_PROGRESS": "Oportunidades en Progreso",
 
        "PENDING_FOR_EVALUATION": "Evaluación Pendiente",
 
        "IDEAS_BY_DEPARTMENT": "Oportunidades por Departmento",
 
        "PROGRESS_BY_CATEGORY": "Progreso por Categoría",
 
        // CONFIGURATION AND ADMINISTRATION
 
        "CONFIGURATION_AND": "CONFIGURACIÓN Y",
        'PARTICIPATION_BY_SITE': "Participación de Empleados",
        'AVG_CONTRIBUTION_EMPLOYEE':'Contribución Promedio de Empleados',
 
        "ADMINISTRATION": "ADMINISTRACIÓN",
 
        "NEW_EVAL_COMMITTEE": "Nuevo Comité Evaluador",

        "SELECT_EMPLOYEES":"Seleccione Empleados",
 
        "EVAL_COMMITTEE_NAME": "Nombre Comité Evaluador",
 
        "EVAL_COMMITTEE_MEMBERS": "Miembros",
 
        "EVAL_COMMITTEE_EMAIL": "Email",

        "Verify History Workflow Instruction":"Oprima una oportunidad para ver su análisis de verificación y todos los detalles enlazados a la misma.",
        "Verify Inbox Workflow Instruction":"Tu equipo ha completado una oportunidad. Ahora nos toca cuantificar su impacto al negocio.",
 
        "EVAL_COMMITTEE_NAME": "Nombre Completo",
 
        "EVAL_COMMITTEE_ADD_MEMBERS": "Añadir miembros",
        "EVAL_COMMITTEE_ADD":"Añadir miembros nuevos",
 
        "EVAL_COMMITTEE_EVALUATE_BY": "Este comité evaluará por: ",
 
        "EVAL_COMMITTEE_CREATE_COMMITTEE": "Crear Comité",
 
        "EVAL_COMMITTEE_AREA_RESPONSIBILITY": "Area de Responsabilidad",
 
        "EVAL_COMMITTEE_IDEA_CLASSIFICATION": "Clasificación de la Idea",

        "EVAL_COMMITTEE_MANAGE": "Manejo",
 
        "CATEGORIES": "CATEGORÍAS",
        "CHART_CATEGORIES": "Categorías",
        "type":"Tipo",
        "category":"Categoría",
 
        //SEARCH IDEA
 
        "IDEA_MANAGE_FILTERS":"Actualizar Idea",
 
        "IDEA_MANAGE_SUBTITLE":"IDEA",
 
        "IDEA_MANAGE_OPEN":"Ver Oportunidad",
 
        "IDEA_MANAGE_NUM":"No. de Oportunidad",
 
        "IDEA_MANAGE_TITLE":"Título",
 
        "IDEA_MANAGE_CAT":"Categoría",
 
        "IDEA_MANAGE_CATS":"Categorías",
        "Select Categories":"Seleccione Categoría(s)",
        "Select Departments":"Seleccione Departamento(s)",
        "IDEA_MANAGE_COM":"Comité",

        "IDEA_MANAGE_DEPTS":"Departamentos",
 
        "IDEA_MANAGE_FILTERS":"Filtros",
 
        "IDEA_MANAGE_TYPES":"Tipos de Oportunidad",
 
        "IDEA_MANAGE_PROG":"Progreso",
 
        "IDEA_MANAGE_PROP":"Proponente",
        // Verify -> Status a Estado
        "IDEA_MANAGE_STATUS":"Estado",
 
        "IDEA_MANAGE_EVALUATE":"Evaluar",

        "IDEA_UPDATE_VIEW":"Ver / Editar",
        "SHOW":"VER",
  

        // EVALUATION
        "IDEA_VERIFICATION":"Buzón para Verificación",

        "EVAL_IDEA_TITLE":"Evaluar",

        "EVAL_IDEA_TITLE_PROJECT":"Evaluar PROYECTO",

        "EVAL_IDEA_VERIFICATION":"Preguntas para Evaluación",
        "ROWS":"FILAS",

        "EVAL_IDEA_BENEFITS":"¿Se cumplieron los objetivos de la oportunidad?",



        "EVAL_IDEA_IMPROVEMENT":"¿Se mejoró el proceso o etapa del proceso?",

        "DATE_VERIFIED":"Fecha de Evaluación",

        "EVAL_IDEA_FINRES":"¿Cuánto fue el impacto económico?",

        "EVAL_IDEA_IMPACT":"¿Esta oportunidad puede ser replicada en otra área del negocio?",

        "EVAL_IDEA_RECOG":"Esta IDEA lleva reconocimiento",

        "EVAL_IDEA_YES":"Si",

        "EVAL_IDEA_NO":"No",

        "EVAL_IDEA_BUTTON":"Someter",
        // Verify - Submit a Someter
        "REWARDRECOG_IDEA_BUTTON":"Someter",

        // EVALUATION
        "RECOG_IDEA_TITLE":"Buzon para Reconocimiento",

        "RECOG_IDEA_RECOGNITION":"Preguntas para Evaluación",

        "RECOG_IDEA_EMPLOYEES":"Escoja Empleado(s)",

        "RECOG_IDEA_DATE":"Fecha de Reconocimiento",

        "RECOG_IDEA_TYPE":"Tipo de Reconocimiento",

        "RECOG_IDEA_POINTS":"Recompensa Económica (Si Aplica)",

        "RECOG_IDEA_LOW":"Bajo",
        "RECOG_IDEA_MODERATE":"Mediano",
        "RECOG_IDEA_HIGH":"Alto",
        "title":"Título",
        "Show transferred opportunities":"Mostrar oportunidades transferidas",

        "IDEA_MANAGE_NUM":"No. de Oportunidad",
        "Select Type":"Seleccione Tipo(s)",
 
        // MANAGE IDEA ENTRANTE
 
        // "IDEA_MANAGE_TITLE":"Manejar Idea",
 
        "IDEA_MANAGE_SUBTITLE":"IDEA",
 
        "IDEA_MANAGE_OVERVIEW":"Información de Idea",
 
        "IDEA_MANAGE_PROPONENT":"Proponente",
 
        "IDEA_MANAGE_SUBMITTED":"Sometida",

        "IdeaDetails": "Detalles sobre Oportunidad",
 
        "IDEA_MANAGE_DEPARTMENT":"Departmento",
 
        "IDEA_MANAGE_CATEGORY":"Categoría",
 
        "IDEA_MANAGE_ASSIGNED":"Asignada a",
        // Verify - Estatus -> Estado
        "IDEA_MANAGE_STATUS":"Estado",
 
        "IDEA_MANAGE_PROGRESS":"Progreso Actual",

       "IDEA_EVALUATED_ASSIGNED":"Idea evaluada y asignada",

       "IDEA_EXECUTION_STARTED":"Comenzó ejecución",

       "IDEA_IMPLEMENTED":"Implementado",
       "QUANTITY_OF_OPPORTUNITIES": "Cantidad de Oportunidades",
       "IDEA_IMPLEMENTED_BENEFITS":"Implantada con beneficios",

       "WRITE_NEW_COMMENT":"Escribe un comentario",

       "WRITE_NEW_COMMENT_DESCRIPTION":"Escribe comentario pertinente sobre la idea",
 
        "IDEA_MANAGE_ADD_PROGRESS":"Actualizar Progreso",
 
        "IDEA_MANAGE_BTN_HOLD":"Pausar Idea",
 
        "IDEA_MANAGE_BTN_UPDATE":"Actualizar Idea",
 
        // HISTORY
 
        "TRANSACTION_HISTORY": "Historial de Transacciones",
 
        "TRANSACTION_DESCRIPTION": "Descripción",
 
        "TRANSACTION_CATEGORY": "Categoría",
        "Never lose track of the opportunities under your umbrella.":"Nunca pierda de vista las oportunidades bajo su responsabilidad.",
 
        "TRANSACTION_PROPONENT":"Proponente",
        // Verify - Status -> Estado
        "TRANSACTION_STATUS": "Estado",
 
        "TRANSACTION_ACTIONS":"Acciones",
 
        "TRANSACTION_EVALUATED": "Historial de Evaluación",
 
        "TRANSACTION_MANAGE":"Evaluación",
 
        "TRANSACTION_VERIFIED":"Historial de Evaluaciones",
 
        "TRANSACTION_RECOGNITION":"RECONOCIMIENTOS Y RECOMPENSAS",
 
        "TRANSACTION_VIEWRECOGNITION":"Historial de Reconocimiento",
 
        "TRANSACTION_EMPLOYEE_NAME":"Nombre de Empleado(s)",

        "ECONOMIC_REWARD":"Puntos",
 
        "TRANSACTION_EMPLOYEE_NUM":"Número de Empleado",
 
        "TRANSACTION_RECOGNITION_TYPE":"Tipo",

        "RecognitionDetails":"Reconocimiento",
 
        "TRANSACTION_EMPLOYEE_POINTS":"Puntos",
 
        'NUMBER_MEMBERS':"Número de Miembros: ",
        'APPROVAL_PERCENTAGE': 'Porcentaje de Aprobación',
 
        'EVALUATING_BY':"Evaluando por: ",
 
        'ENGLISH':"Inglés",
 
        'SPANISH':"Español",
 
        // New Question
 
        "NEW_QUESTION": "Nueva Pregunta",
 
        "QUESTION_ENGLISH": "Pregunta (Inglés)",
 
        "QUESTION_SPANISH": "Pregunta (Español)",

        "QUESTION_OPEN": "Pregunta Abierta (Campo)",
 
        "QUESTION_PLACEHOLDER": "Escriba la pregunta en inglés aquí..",

        "QUESTION_PLACEHOLDER_SPANISH": "Escriba la pregunta en español aquí..",
 
        "QUESTION_CATEGORY": "Categoría",
 
        "QUESTION_REQUIRED": "Pregunta Requerida",
 
        "QUESTION_BTN": "Someter Pregunta",
 
        // Question list
 
        "QUESTION_LIST": "Lista de Preguntas",
 
        "QUESTION": "Pregunta",
 
        "CATEGORY": "Categoría",
        "TYPE": "Tipo",

        // Verify - Status -> Estado
        "STATUS": "Estatus",
 
        "ACTIONS": "Acciones",
 
         
        
 
         "EVALUATE_IDEA":"Evaluar",
 
         "REVIEW_IDEA":"Repasar IDEA",
 
         "EVALUATION_IDEA":"Evaluación",
 
         "NEEDS_INFO_IDEA":"IDEA necesita más información",

         "NEEDS_INFO_PROJECT":"Proyecto necesita más información",
 
         "HOLD_IDEA":"IDEA en Pausa",

         "HOLD_PROJECT":"Proyecto en Pausa",
 
         "NOT_PURSUED_IDEA":"No Perseguida",

         "NOT_PURSUED_PROJECT":"No Perseguida",
          // Verify - Just Do It -> Solo Hazlo con David?
         "JUST_DO_IDEA":"Just Do It",

         "JUST_DO_PROJECT":"Just Do It Proyecto",
 
         "PROYECT_IDEA":"IDEA Proyecto",
 
         "OTHER_COMMITTEE_IDEA":"Otro Comité",
 
         "COMMENTS_IDEA":"Comentarios",
 
         "WRITE_COMMENTS_IDEA":"Escribir comentarios",
 
         "ASSIGN_RESPONSIBLE_IDEA":"Asignar responsable",
 
         "RESPONSIBLE_NAME_IDEA":"Nombre del Responsable",
 
         "ASSIGN_COACH_IDEA":"Asignar Coach",
 
         "RESET": "Reiniciar",
 
         "RESET_MSG": "Se borrarán todas las ideas. ¿Desea proceder?",

         "USER_LIST": "Lista de Usuarios",

        "ENTER_NAME":"Nombre",

        "FIRST_NAME_PLACEHOLDER":"Primer Nombre",
 
        "LAST_NAME_PLACEHOLDER":"Apellido",

        "EMAIL":"Email",

        "USERID":"Userid",
        "USERID_PLACEHOLDER":"Entre su Userid",

        "EMAIL_PLACEHOLDER":"jose.feli@lasalle-group.com",

        "USER_TYPE":"Rol",
        "OPPORTUNITY_STATUS": "Status de Oportunidades",

        "ADD_USER_BTN": "Añadir Usuario",

        "ENTER_LAST_NAME":"Apellido",
       
        "NEW_USER": "Nuevo Usuario",

        // Reports
        // Verify - Estatus - Estado
        "IDEAS_BY_PROGRESS":"Ideas por Estados",

        "EXPECTED_EARNINGS_BY_CATEGORY": "Ganancias Proyectadas por Categoría",

        "ACTUAL_EARNINGS_BY_CATEGORY": "Ganancias Reales por Categoría",



        "DOWNLOAD_DATA": "Descargar Datos",

        // Filters
        "FILTER_INNOVATION": "Innovación",
        "FILTER_PROBLEM_F": "Problema + Solución",
        "FILTER_IMPROVEMENT": "Mejoramiento Continuo",
        "FILTER_ALL": "Todas",
        "FILTER_SUBMITTED": "Sometidas",
        "FILTER_DONE": "Completadas",
        
        "OPPORUTNITY_DETAILS":" > Detalles de Oportunidad",
        // Return
        "SUBMIT_IDEA_ReturnTitle":"Ahorros Esperados (Mensual)",
        "SUBMIT_IDEA_ReturnPlaceholder":"E.j. 1000",
        "IDEA_SELECT_IDEA_TYPE":"Oportunidad > Tipo de Oportunidad",
        "WELCOME_BACK":"Hola, ",
        "Choose_how_to_proceed": 'Elija como proceder: ',
        'Evaluation Desicion': 'Resultado de Evaluación',
        "Choose how to contribute!":"Elija como contribuir.",
        "0PEN":"Abrir",
        "Add team members and attachments":"Añadir equipo y agregar archivos",
        "Selected Category":"Categoría seleccionada:",
        "DELETE_FILE": "Borrar Archivo",
        "SUBMIT_IDEA_Money":"Solo se acceptan numeros. Evite comas u otros caracteres invalidos.",
        "COULD_BE_REPLICATED":"Puede replicarse en otras areas o plantas?",
        "ADDITIONAL_COMMENTS":"Comentarios",
        "Which department is this idea for?":"¿Qué departamento se beneficiará de esta oportunidad?",
        "SAVINGS_IN_LABOR":"Ahorros en labor (hrs)",
        "NUMBER_HOURS_SAVED":"Numero de horas ahorradas...",
        "COST":"Costo (USD)",
        "COST_SAVED":"Cuanto dinero se ahorro...",
        "INVENTORY":"Inventario (USD)",
        "INVENTORY_DESC":"Inventario...",
        "OEE_INCREASE":"OEE (Aumento %)",
        "OEE_INCREASE_MUCH":"Por cuanto aumento el OEE en %...",
        "LEAD_TIME":"Lead Time (HRS)",
        "LEAD_TIME_HOURS":"Lead Time en horas...",

        // New Translation
        "Idea Title*":"Título de Oportunidad*",
        "Idea Description*":"Descripción de Oportunidad*",
        "Add an attachment":"Archivo",
        "Add a team":"Agregar colaboradores",
        "Click to share your idea":"Click to share your idea",
        "Help us understand your idea and its benefits by describint it here!":"Help us understand your idea and its benefits by describint it here!",
        "Type [First Name] [Last Name]":"Seleccione un usuario o escriba su nombre",
        "APPROVE_MSG":"Choose this option to start implementing this idea right away.",
        "DO_NOT_PURSUE_MSG":"Choose this option for ideas that are not aligned with business needs or idea approval criteria.",
        "SAVE_FOR_LATER_MSG":"Choose this option for good ideas that should be implemented in the near future.",
        "REQUEST_INFORMATION_MSG":"Choose this option if you need additional information to evaluate this idea. Type your questions in the comment section below. We’ll deliver it to the right person!",
        "PROJECT_IDEA_MSG":"Choose this option for ideas that need additional budget and approvals. We’ll send this to the PMO team in your organization!",
        "TRANSFER_COMMITTEE_MSG":"Not the right person to evaluate this? Let us help you by choosing this option and typing the right department in the comment section below.",
        "RESPONSE_TIME_MSG":"People love speedy responses. Respond within 14 days to keep your team engaged.",
        "Idea Details":" > Detalles de Oportunidad",
        "OPEN":"Abrir",
        "YOUR_IDEA_HISTORY":"Historial de Oportunidades",
        "Your Ideas at Work": "Implementación",
        "Verified":"Verificado",
        "Your Submitted Contributions":"Tus Oportunidades \nSometidas",
        "Your Approved Contributions":"Tus Oportunidades \nAprobadas",
        "Your Team's Contributions":"Oportunidades de \n tu Departamento",
        "THANK_YOU_FOR_YOUR_CONTRIBUTION": `Gracias por tu <em>your</em> contribución!`,
        "Ideas Being Implemented":"Oportunidades \nen Implementación",
        "Ideas by Department":"Oportunidades por Departamento",
        "Ideas by Type":"Oportunidades por Tipo",
        "KEEP_YOUR_TEAM_UPDATED": "Resumen de Oportunidad",
        
        "IMPLEMENTATION STARTED": "Implementación Comenzada",
        "IMPLEMENTED": "Implementado",
        "OPPORTUNITY_EVALUATED": "Oportunidad evaluada",
        "UPDATE_OPPORTUNITY_STATUS": "Actualiza estatus",
        "WHAT_CHANGED": "¿Qué cambió?",
        "ENTER_CHANGES_HERE": "Ponga los cambios aquí...",
        "IDEA_IMPLEMENTATION_SUMMARY": "Resumén sobre implementación de opportunidad",
        "IN_ORDER_TO_GET_YOUR_OPPORTUNITY": "Para llevar su oportunidad a la siguiente etapa, necesitamos un poco más de información.",
        "CANCEL": "Cancelar",
        "COMPLETED_OPPORTUNITIES": "Opportunidades Completadas",
        "CLOSE_OPPORTUNITIES": "Opportunidades Cerradas",
        "FINISH_OPPORTUNITY": "Terminar oportunidad",
        "ANALYSIS_&_CONCLUSION": "Análisis & Conclusión",
        "OPPORTUNITY_CREATOR": "Proponente de Oportunidad",
        "START_DATE": "Fecha de Comienzo",
        "TARGET_COMPLETION_DATE": "Fecha Meta para Culminación",
        "SELECT_A_DATE": "Escoge una fecha",
        // down
        "OPPORTUNITY_OWNER": "Lider de Oportunidad",
        "OPPORTUNITY_COACH": "Mentor de Oportunidad",
        "REASSIGN_OPPORUTNITY": "Reasignar Oportunidad",
        "VIEW_OPPORTUNITY": "Ver Oportunidad",
        "SAVE_NEW_OWNER": "Añadir nuevo Lider",
        "TEAM_MEMBERS": "Miembros del Equipo",
        "EVALUATION_COMMENTS": "Comentarios de Evaluación",
        "EVALUATION_ATTACHMENT": "Archivo de Evaluación",
        "VIEW_ATTACHMENT": "Ver Archivo",
        "RECOGNITION_ATTACHMENT":"Archivo de Reconocimiento",
        // Needs been added to Pages
        "OPPORTUNITIES_SUBMITTED":"Oportunidades Recibidas",
        "EVALUATED_OPPORTUNITIES": "Oportunidades Evaluadas",
        "OPPORTUNITIES_PENDING_EVALUATION": "Oportunidades No Evaluadas",
        "OPPORTUNITIES_UNDER_IMPLEMENTATION": "Oportunidades en Progreso",
        "EMPLOYEE_OPPORTUNITY_WORKFLOW_STAGE": "Estatus de Contribuciones de Empleados",
        "TRENDING_TOPICS": "Temas recurrentes/populares",
        "QUANTITY_OF_OPPORTUNITIES": "Cantidad de Oportunidades",
        "OPPORTUNITIES_BY_CATEGORY_PERCENTAGE": "Oportunidades por Categoría",
        "OPPORTUNITIES_BY_CATEGORY_QUANTITY": "Oportunidades por Categoría",
        "OPPORTUNITIES_BY_TYPE_PERCENTAGE": "Oportunidades por Tipo",
        "OPPORTUNITIES_BY_TYPE_QUANTITY": "Oportunidades por Tipo",
        "BUSINESS_IMPACT_FORECAST": "Impacto Económico",
        "BUSINESS_IMPACT_VERIFIED": "Impacto Económico",
        "BUSINESS_IMPACT": "Impacto Económico",
        "EMPLOYEES_WITH_MOST_OPPORTUNITIES": "Empleados con más Contribuciones",
        "DEPARTMENTS_RECEIVING_MOST_OPPORTUNITIES": "Departamentos recibiendo más Oportunidades",
        "ACTUAL": "(Actual)",
        "ESTIMADO": "(Estimado)",
        "QUANTITY": "(Cantidad)",
        "PERCENTAGE": "(Porcentaje)",
        "OPPORTUNITY_TYPE":"Tipo de Oportunidad",
        "CHART_EMPLOYEE_PARTICIPATION":'Participación de Empleados',
      }
      }
  },
  lng: lng,
  fallbackLng: "en",
});



ReactDOM.render(
<I18nextProvider i18n={i18next}>
    <App />
</I18nextProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();