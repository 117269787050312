import Parse from 'parse';

export const getCategories = async () => {
  const Category = Parse.Object.extend("IdeaCategory");
  const query = new Parse.Query(Category);
  const results = await query.doesNotExist("deleted_at").limit(100000).find();

  const processedCategories = results.map(category => {
    const currentDate = new Date();
    const startDate = category.get("startDate");
    const endDate = category.get("endDate");

    if (startDate && endDate) {
      if (currentDate >= startDate) {
        category.set("show", true);
      }

      if (currentDate > endDate) {
        category.set("show", false);
      }
    }

    return category;
  });

  return processedCategories;
};
