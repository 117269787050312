import Parse from 'parse';
import React from "react";
import { withTranslation } from 'react-i18next';
import ReactTable from "react-table";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";

class RecognitionTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ideas: [],
      evalCriteria: [],
      filteredIdeas: [],
      title: '',
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      search: ''
    };

    this.fetchIdeas = this.fetchIdeas.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentDidMount() {
    this.getEvalCriteria();
    this.fetchIdeas();
  }

  componentWillReceiveProps(newProps) {
    this.filterIdeas(newProps.category, newProps.status);
  }

  async getEvalCriteria() {
    const currentUser = Parse.User.current();
    const evalCriteria = currentUser.get("evaluationCriteria");
    this.setState({ evalCriteria });
  }

  fetchIdeas() {
    const className = "Idea";
    var recognitionQuery = new Parse.Query("Recognition");
    recognitionQuery.select("idea");

    recognitionQuery.find().then((recognitionResults) => {
      const recognizedIdeaIds = recognitionResults.map(recognition => recognition.get("idea").id);

      var query = new Parse.Query(className);
      query.equalTo("verified", true);
      query.notContainedIn("objectId", recognizedIdeaIds);
      query.descending("createdAt");

      return query.find();
    }).then((results) => {
      this.setState({
        ideas: results,
        filteredIdeas: results,
      });
    }, (error) => {
      alert('Hubo un error en la búsqueda. Favor de intentar nuevamente más tarde.');
    });
  }

  filterIdeas(categoryName, status) {
    const newCategory = categoryName;
    const newStatus = status;

    const { ideas } = this.state;
    
    let filteredData = ideas;
    if (newCategory && newCategory !== 'Todas') {
      filteredData = filteredData.filter(item => item.get("category") === newCategory);
    }
    if (newStatus && newStatus !== 'Todos') {
      filteredData = filteredData.filter(item => item.get("status") === newStatus);
    }

    this.setState({ filteredIdeas: filteredData });
  }

  handlePageSizeChange(e) {
    this.setState({ pageSize: e.target.value });
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      const filteredData = this.state.ideas.filter(idea =>
        idea.get("title").toLowerCase().includes(searchTerm) ||
        idea.get("category").toLowerCase().includes(searchTerm) ||
        idea.get("status").toLowerCase().includes(searchTerm)
      );
      this.setState({ filteredIdeas: filteredData });
    });
  }

  render() {
    const { filteredIdeas, pageSize, pageSizeOptions, search } = this.state;
    const { t } = this.props;

    const columns = [
      {
        Header: t("IDEA_MANAGE_OPEN"),
        accessor: "open",
        className: "text-center",
        width: 120,
        Cell: row => (
            <Button pill outline size="sm" theme="danger" onClick={() => this.props.onViewIdeaPress(row.original.evaluate)}>
              <i className="material-icons">visibility</i>
            </Button>
        )
      },
      {
        Header: t("IDEA_MANAGE_EVALUATE"),
        className: "text-center",
        accessor: "evaluate",
        width: 100,
        Cell: row => (
            <Button pill outline size="sm" theme="danger" onClick={() => this.props.onEditActionPress(row.original.evaluate)}>
              <i className="material-icons">create</i>
            </Button>
        )
      },
      {
        Header: t("IDEA_MANAGE_NUM"),
        width: 120,
        accessor: "num",
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_TITLE"),
        accessor: "title",
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_CATEGORY"),
        accessor: "category",
        width: 200,
        style: {color: '#17c671'},
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_PROP"),
        width: 200,
        accessor: "proponentName",
        className: "text-center"
      },
      {
        Header: t("IDEA_MANAGE_STATUS"),
        accessor: "status",
        width: 150,
        className: "text-center",
        Cell: row => (
          <div className="d-table mx-auto">
            <Badge pill theme={getBadgeType(row.value)}>
              {row.value}
            </Badge>
          </div>
        )
      }
    ];

    const data = filteredIdeas.map(idea => ({
      open: idea,
      evaluate: idea,
      num: idea.get("num"),
      title: idea.get("title"),
      category: idea.get("category"),
      proponentName: idea.get("proponentName"),
      status: idea.get("status"),
      verified: idea.get("verified")
    }));

    return (
      <Card>
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex" md="6">
                <span>{t('SHOW')}</span>
                <FormSelect
                  size="sm"
                  value={pageSize}
                  onChange={this.handlePageSizeChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size + ' ' + t('ROWS')}
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col className="file-manager__filters__search d-flex" md="6">
                <InputGroup seamless size="sm" className="ml-auto">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput value={search} onChange={this.handleFilterSearch} />
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <Container fluid className="px-0">
            <ReactTable
              columns={columns}
              data={data}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              showPageSizeOptions={false}
              resizable={false}
              // className="-striped -highlight"
            />
          </Container>
        </CardBody>
      </Card>
    );
  }
}

function getBadgeType(itemStatus) {
  const statusMap = {
    "Complete": "success",
    "Saved": "warning",
    "Canceled": "danger"
  };
  return statusMap[itemStatus];
}

export default withTranslation()(RecognitionTable);
