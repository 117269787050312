import Parse from 'parse';
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  DatePicker,
  Form,
  FormInput,
  FormRadio,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "shards-react";

import { object } from "prop-types";
import Select from 'react-select';
import { sendEmail } from '../../services/emailService';
import FormSectionTitle from "../edit-user-profile/FormSectionTitle";

import { withTranslation } from 'react-i18next';
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as LineChartImage } from "../../images/line-chart.svg";
import { ReactComponent as UserImage } from "../../images/man-user.svg";
import { ReactComponent as MedalImage } from "../../images/medal.svg";
import { ReactComponent as RibbonImage } from "../../images/ribbon.svg";
import CustomFileWithProgressUpload from "../components-overview/CustomFileWithProgressUpload";

class RecognitionForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          data:[],
          ideaQuestions: [],
          answers:[],
          category:null,
          department:null,
          date: '',
          categoryQuestions: [],
          filterQuestions: [],
          selectedFilterQ: [],
          filterQAnswers: [],
          categoryQAnswers: [],
          ideaDescription: null,
          file:null, 
          buttonNext:true,
          descriptionValid:'',
          remainingCharacters: 250,
          visible: true,
          filterVisible: false,
          filterQuestionsVisible: false, 
          ideaQuestionsVisible: false,
          hideNextButton: false,
          userName: 'User Name',
          sectionTitle:'',
          formButtonTitle: 'Continuar',
          ideaNumber: '#00008',
          status: '',
          executionRes: 0,
          executionResObj: object,
          selectedBenefit:'',
          selectedResult: '',
          money: '',
          selectedImprovement: '',
          selectedImpact: '',
          selectedCoachBackup: '',
          selectedEmployees: '',
          points:'',
          options: [],
          uploadProgress: 100,
        }

        this.change = this.change.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setIdeaDescription = this.setIdeaDescription.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.showNext = this.showNext.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.showNext = this.showNext.bind(this);
        this.selectEmployees = this.selectEmployees.bind(this);
        this.changePoints = this.changePoints.bind(this);
        this.changeComments = this.changeComments.bind(this);
    }

    componentDidMount() {
      let currUser = Parse.User.current();
      this.getResponsibleUsers();
      this.getUserName(currUser);
      this.fetchNewData();
      this.fetchQuestions();
      this.fetchFilterQuestions();
      this.getDate();
    }

   async getResponsibleUsers() {
    const ideaItem = this.props.ideaItem;
    const creatorId = ideaItem.get("responsible");
    const proponent = ideaItem.get("proponentObj");
    const coaches = ideaItem.get("coaches");
    const teamMembers = ideaItem.get("teamMembers");

   
    const userIds = [];
    if(creatorId){
      userIds.push(creatorId);
    }

    if(proponent){
      userIds.push(proponent.id);
    }

    if(teamMembers){
      teamMembers.forEach(member => {
        userIds.push(member.value.id);
      });
    }
    
    if(coaches){
      coaches.forEach(coach => {
        userIds.push(coach.id);
      });
    }

    const options = [];
    const query = new Parse.Query(Parse.User);
    query.equalTo('deletedAt', null);
    query.ascending('firstName');

    try {
      const results = await query.find();
      if (results.length > 0) {
        results.forEach((user) => {
          const firstName = user.get('firstName');
          const lastName = user.get('lastName');
          const fullName = firstName + ' ' + lastName;
          options.push({ "value": user.id, "label": fullName });
        });
      }

      const filteredUsers = results.filter(user => userIds.includes(user.id));

      const filteredOptions = filteredUsers.map(user => ({
        "value": user.id,
        "label": user.get('firstName') + ' ' + user.get('lastName')
      }));

      this.setState({ options: filteredOptions });
    } catch (error) {
      console.error('Error while fetching users:', error);
    }
  }

    getDate() {
      this.setState({
        date: new Date()
      });
    }

    async getUserName(user) {
      var query = new Parse.Query(Parse.User);
      query.equalTo("objectId", user.id);
      const results = await query.find();
      const firstName = results[0].get('firstName');
      const lastName = results[0].get('lastName');
      const fullName = firstName + ' ' + lastName;
      this.setState({
        userName: fullName
      });
    }

    async getUserFullName(user) {
      const query = new Parse.Query(Parse.User);
      query.equalTo("objectId", user.id);
      const results = await query.find();
      const firstName = results[0].get('firstName');
      const lastName = results[0].get('lastName');
      const fullName = firstName + ' ' + lastName;
      this.setState({
        userName: fullName
      });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continuar") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        //  
        //  
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          //  
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        //  
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions.");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
      //  
      //  
      //  
      //  
      //  
    }

    resetIdeaForm() {
      window.location.reload();
    }

    fetchNewData() {
      const className = "IdeaDepartment";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);

      query.find()
      .then((results) => {
          this.setState({
              data: results
          });
          //  
      }, (error) => {
          this.setState({
              data: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    fetchQuestions() {
      const className = "IdeaQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);

      query.find()
      .then((results) => {
        //  
          this.setState({
              ideaQuestions: results
          });
      }, (error) => {
          this.setState({
              data: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    fetchFilterQuestions() {
      const className = "FilterQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);
      query.find()
      .then((results) => {
        //  
          this.setState({
              filterQuestions: results
          });
      }, (error) => {
          this.setState({
              filterQuestions: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions, selectedFilterQ } = this.state;
      //  
      //  
      
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      //  
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };


    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
      // this.setState({category: event.target.value});
      this.setState({department: event.target.value});
      //  
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
      //  
    }

    setFilter(filterName) {
      //  
      // this.setState({sectionTitle: (filterName === "innovacion")?"INNOVACION":"RESOLUCION DE PROBLEMAS"});
      this.handleFilterChange(filterName);
    }

    clickedPrint() {
       
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
      //  
    }

    filterQuestionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.filterQAnswers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    questionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.answers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

      //  
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      //  
    }

    selectFile(file) {
      //  
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        //  
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
      //  
    }

    addFilterAnswer(filteredData) {
      //  
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          //  
        });
    }

    submitEvaluation() {
      const {selectedImpact, date, selectedEmployees, points, file, comments, uploadProgress} = this.state;

      if (!selectedImpact || !date || !selectedEmployees ||  uploadProgress != 100) {
        if (uploadProgress != 100) {
          alert('Please wait for the file to finish uploading.');
          return;
        }
        
        alert('Please fill out all required fields.');
        return;
      }

      const ideaItem = this.props.ideaItem;
      const RecognitionObject = Parse.Object.extend("Recognition");


      const pointsNum = parseFloat(points);
      let recognitions = [];
      for (let i in selectedEmployees) {
        const currEmployee = selectedEmployees[i];
        const employeePointer = new Parse.User();
        employeePointer.id = currEmployee ? currEmployee.value : null;

        
        const recognition= new RecognitionObject();
        recognition.set("employeeName", currEmployee ? currEmployee.label : null);
        recognition.set("employee", currEmployee ? employeePointer : null);
        recognition.set("employeeNum", 1);
        recognition.set("idea", ideaItem);
        recognition.set("ideaNum",ideaItem.get("num"));
        recognition.set("recognitionType", selectedImpact);
        recognition.set("recognitionDate", date);
        recognition.set("needsRecognition", false);
        recognition.set("points", pointsNum);
        recognition.set("status", "Completado");
        recognition.set("customUpdatedAt", new Date());

        if (comments) {
          recognition.set("comments", comments);
        }

        if (file) {
          recognition.set("file", file);
        }
        
        recognitions.push(recognition);
      }

      Parse.Object.saveAll(recognitions, {useMasterKey: true}).then(async () => {
        ideaItem.set("needsRecognition", false);
        ideaItem.set("customUpdatedAt", new Date());
        const currentUser = Parse.User.current();

        await sendEmail({
            typeEmail: 'M_NE_04', 
            client: process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo',
            subject: 'We have an update...',
            userId: currentUser.id,
            ideaId: ideaItem.id,
            action:'recognition submit'
        })
        
        ideaItem.save().then(() => {
          alert('Submission successfull.', this.props.dismissModal());
        });
      })
    }

    changeStatus(selection) {
      this.setState({status: selection});
       
    }

    changeResponsible(res, idx) {
       
      this.setState({
        executionResObj: res,
        executionRes: idx
      });
       
    }

    async setNotifications() {
      const responsibleMsg = "'¡Congrats! We have assigned an idea for execution.'";

      var ideaItem = this.props.ideaItem;
       
       

      var proponentUser = ideaItem.get("proponentObj");
      var responsibleUser = ideaItem.get("responsible");

      var proponentNotifications = proponentUser.get("notificationMessages");
      

      proponentUser.increment("notificationCount");
      proponentUser.set("notificationMessages", proponentNotifications);

      var responsibleNotifications = responsibleUser.get("notificationMessages");
      responsibleNotifications.unshift(responsibleMsg);

      responsibleUser.increment("notificationCount");
      responsibleUser.set("notificationMessages", responsibleNotifications);

      var users = new Parse.Query("Users");
      users.set("customUpdatedAt", new Date());

      await Parse.Object.saveAll(users, {useMasterKey: true}).catch(error => {
         
      });
      // await responsibleUser.saveAll().catch(error => {
      //    
      // });

      //  
      // var notified = false;
      // for (var u in allUsers) {
      //   const evalCriteria = allUsers[u].get("evaluationCriteria");
      //    
      //   for (var i in evalCriteria) {
      //     if ((evalCriteria[i].type == "department" && evalCriteria[i].name == department) || (evalCriteria[i].type == "category" && evalCriteria[i].name == category)) {
      //        
      //       // Department Queries
      //       // var deptQuery = new Parse.Query(ItemClass);
      //       // myQueries.push(deptQuery.equalTo("department", evalCriteria[i].name))
      //       if (notified == false) {
      //         allUsers[u].increment("notificationCount");
      //         allUsers[u].set("notificationMessages", ["You have a new IDEA waiting for evaluation in your inbox."]);
      //         await allUsers[u].save().catch(error => {
      //           // This will error, since the Parse.User is not authenticated
      //         });
      //       }

      //       notified = true;
      //     } 
        // }
      // }
    }

    changeBenefit(response) {
        this.setState({
          selectedBenefit: response
        });
      }

    changeResults(response) {
    this.setState({
        selectedResult: response
    });
    }

    changeImprovement(response) {
    this.setState({
        selectedImprovement: response
    });
    }

    changeMoney(response) {
    this.setState({
        money: response
    });
    }

    changeBackup(response) {
    this.setState({
        selectedCoachBackup: response
    });
    }

    changeImpact(response) {
      this.setState({
          selectedImpact: response
      });
      }

    changePoints(response) {
      const myPoints = parseFloat(response.target.value);
      this.setState({
        points: myPoints
      });
    }

    changeComments(response) {
      const myComment = response.target.value;
      this.setState({
        comments: myComment
      });
    }

    selectEmployees = selectedEmployees => {
      this.setState({ selectedEmployees });
    };

    render() {
        const { visible, hideNextButton } = this.state
        const formVisibilityState = visible? 'block' : 'none';
        const nextButtonVisibilityState = !hideNextButton? 'inline' : 'none';
        const ideaItem = this.props.ideaItem;
        const {t} = this.props;
         
        return(
                  <Card small className="edit-user-details mb-4">
                    {/* <ProfileBackgroundPhoto /> */}
    
                    <CardBody className="p-0">
    
                      {/* Form Section Title :: General */}
                      <Form className="py-4"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                        <FormSectionTitle
                          title={ideaItem.get("title")}
                          description=""
                        />
                        {/* VISIBILITY */}
                        <div style={{display: formVisibilityState}}>
                        <Row form className="mx-4">
                          <Col lg="12">
                            <Row form>
                              {/* Proponent */}
                              <Col md="3" className="form-group">
                                <label htmlFor="firstName">{t("SUBMIT_IDEA_Proponent")}</label>
                                <FormInput
                                  id="firstName"
                                  value={ideaItem.get("proponentName")}
                                  onChange={() => {}}
                                  required
                                  disabled
                                />
                              </Col>
    
                              {/* Date */}
                              <Col md="3" className="form-group">
                              <label htmlFor="lastName">{t("SUBMIT_IDEA_Date")}</label>
                              <InputGroup>
                                  <InputGroupAddon type="append">
                                    <InputGroupText>
                                      <i className="material-icons">&#xE916;</i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <DatePicker
                                    placeholderText={ideaItem.get("date")}
                                    dropdownMode="select"
                                    className="text-center"
                                    readOnly = "true"
                                    onChange={this.setDate} 
                                    selected={ideaItem.get("date")}
                                    required
                                  />
                                </InputGroup>
                              </Col>
                              {/* Department */}
                              <Col md="3" className="form-group">
                                <label htmlFor="userLocation">{t("SUBMIT_IDEA_Department")}</label>
                                <FormInput
                                  id="firstName"
                                  value={ideaItem.get("department")}
                                  onChange={() => {}}
                                  required
                                  disabled
                                />
                              </Col>
                              {/* Department */}
                              <Col md="3" className="form-group">
                                <label htmlFor="userLocation">{t("SUBMIT_IDEA_Category")}</label>
                                <FormInput
                                  id="firstName"
                                  value={ideaItem.get("category")}
                                  onChange={() => {}}
                                  required
                                  disabled
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
    
                        <br/>

                        <FormSectionTitle
                          title={t("RECOG_IDEA_RECOGNITION")}
                          description=""
                        />
                        <Row form className="mx-4">
                        {/* Categoria */}
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><UserImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("RECOG_IDEA_EMPLOYEES")}: *</p>
                                <Select
                                  value={this.state.selectedEmployees}
                                  onChange={this.selectEmployees}
                                  options={this.state.options}
                                  isMulti
                                  placeholder={t('SELECT_EMPLOYEES')}
                                />
                                </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><LineChartImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("RECOG_IDEA_DATE")}: *</p>
                                <DatePicker
                                    placeholderText={this.state.date}
                                    dropdownMode="select"
                                    className="text-center"
                                    onChange={this.setDate} 
                                    selected={this.state.date}
                                  />
                                </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><MedalImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("RECOG_IDEA_TYPE")}: *</p>
                                <FormRadio
                                    inline
                                    name="impacto"
                                    checked={this.state.selectedImpact === "Level 1"}
                                    onChange={() => {
                                    this.changeImpact("Level 1");
                                    }}
                                >
                                    {t("Level 1")}
                                </FormRadio>
                                <FormRadio
                                    inline
                                    name="impacto"
                                    checked={this.state.selectedImpact=== "Level 2"}
                                    onChange={() => {
                                    this.changeImpact("Level 2");
                                    }}
                                >
                                    {t("Level 2")}
                                </FormRadio>
                                <FormRadio
                                    inline
                                    name="impacto"
                                    checked={this.state.selectedImpact=== "Level 3"}
                                    onChange={() => {
                                    this.changeImpact("Level 3");
                                    }}
                                >
                                    {t("Level 3")}
                                </FormRadio>
                                </Col>
                                </Row>
                            </Col>
                            
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form>
                                    <Col md="3"><DollarImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                    <Col md="9">
                                        <p className="mb-2">{t("RECOG_IDEA_POINTS")}:</p>
                                        <FormInput onChange= {this.changePoints} placeholder={t("RECOG_IDEA_POINTS")} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><RibbonImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                {/* <p className="mb-2">{t("EVAL_IDEA_RECOG")}:</p> */}
                                <p className="mb-2">{t("ADDITIONAL_COMMENTS")}:</p>
                                
                                  <FormInput placeholder="Write comments..." onChange= {this.changeComments}/>
                                </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                {/* <Col md="3"><DocumentImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col> */}
                                <Col md="9" className="ml-4">
                                {/* <p className="mb-2">{t("EVAL_IDEA_IMPACT")}:</p> */}
                                
                                <p className="mb-2">{t("Add an attachment")}:</p>
                                <span><CustomFileWithProgressUpload maximumSizeFile={10}
                                onProgressUpdate={(progress) => {
                                  this.setState({uploadProgress: progress})
                                }}
                                onFileUpload={(file) => {
                                  this.setState({ file })
                                }}
                                /></span> 
                                </Col>
                                </Row>
                            </Col>
                
                        </Row>
                        </div>
                        </Form>
                    </CardBody>
                    <CardFooter className="border-top">
                      <Button 
                            pill 
                            // disabled={!selectedImpact || !date || !selectedEmployees || !points} 
                            type="button" 
                            className="d-table ml-auto" 
                            theme="accent" 
                            onClick={() => this.submitEvaluation()} 
                            style={{display: nextButtonVisibilityState}}
                        >
                            {t("REWARDRECOG_IDEA_BUTTON")}
                        </Button>

                        
                      {/* </ButtonGroup> */}
                    </CardFooter>
                  </Card>
          );
    }
}




export default withTranslation()(RecognitionForm);
